import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { Link as ScrollLink } from "react-scroll";
import useDeviceType, { device } from "../HelperComponents/useDeviceType";
import { Pagetitle } from "../HelperComponents/Pagetitle";

import ProductArea from "./ProduktArea/";

import "./motif.css";
import CondolenceCardArea from "./CondolenceCardArea";

const ERROR_MESSAGE_500 =
  "Es gab Probleme bei der Kommunikation mit unseren Servern. Versuchen Sie es bitte erneut.";
const ERROR_MESSAGE_404 =
  "Bitte versuchen Sie es erneut oder wenden sich an support@jungetrauer.de";

const productNames = {
  invitation: "Trauerkarten",
  newspaper: "Zeitungsanzeigen",
  booklet: "Sterbebilder",
  thankyou: "Danksagungskarten",
  condolence: "Beileidskarten",
  b2b_condolence: "personalisierte Beileidskarten",
};

const offset_desktop = -70;
const offset_touch = -300;

MotifPage.propTypes = {};

function MotifPage(props) {
  const deviceType = useDeviceType();
  let { id } = useParams();
  const alert = useAlert();

  const [motifImage, setMotifImageURL] = useState("");
  const [motifName, setMotifName] = useState("");
  const [motifDescription, setMotifDescription] = useState([]);
  const [productInvitation, setProductInvitation] = useState(null);
  const [productNewspaper, setProductNewspaper] = useState(null);
  const [productBooklet, setProductBooklet] = useState(null);
  const [productThankyou, setProductThankyou] = useState(null);
  const [productCondolence, setProductCondolence] = useState(null);
  const [productB2BCondolence, setProductB2BCondolence] = useState(null);

  useEffect(() => {
    fetch("/api/motifs/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            let counter = 0;
            setMotifImageURL(data.motifImageURL);
            setMotifName(data.name);
            setMotifDescription(data.description.split("\n"));

            if (data.invitation) {
              setProductInvitation({ ...data.invitation, item: counter });
              counter++;
            }
            if (data.newspaper) {
              setProductNewspaper({ ...data.newspaper, item: counter });
              counter++;
            }
            if (data.booklet) {
              setProductBooklet({ ...data.booklet, item: counter });
              counter++;
            }
            if (data.thankyou) {
              setProductThankyou({ ...data.thankyou, item: counter });
              counter++;
            }
            if (data.condolence) {
              setProductCondolence({ ...data.condolence, item: counter });
              counter++;
            }
            if (data.b2b_condolence) {
              setProductB2BCondolence({
                ...data.b2b_condolence,
                item: counter,
              });
            }
          });
        } else {
          if (res.status === 404) {
            alert.show(ERROR_MESSAGE_404, {
              title: "Keine Daten gefunden",
            });
          } else {
            alert.show(ERROR_MESSAGE_500, {
              title: "Fehler",
            });
          }
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        alert.show(ERROR_MESSAGE_500, {
          title: "Fehler",
        });
      });
  }, [alert, id]);

  const imageStyle = {
    backgroundImage: motifImage ? `url('${motifImage}')` : "none",
  };

  function checkDesignerAvailable(product) {
    if (!product) return -1;
    if (product.designId == null) return false;
    if (product.designId === "") return false;
    return true;
  }

  function getSupportedProducts(designerAvailable) {
    let products = [];
    if (checkDesignerAvailable(productInvitation) === designerAvailable)
      products.push("invitation");
    if (checkDesignerAvailable(productNewspaper) === designerAvailable)
      products.push("newspaper");
    if (checkDesignerAvailable(productBooklet) === designerAvailable)
      products.push("booklet");
    if (checkDesignerAvailable(productThankyou) === designerAvailable)
      products.push("thankyou");
    if (checkDesignerAvailable(productB2BCondolence) === designerAvailable)
      products.push("b2b_condolence");

    if (products.length === 0)
      return (
        <li className="product-list-item">
          {designerAvailable
            ? "Derzeit nicht im Designer verfügbar"
            : "Derzeit keine Sonderanfragen für dieses Motiv möglich"}
        </li>
      );

    return products.map((product) => {
      return (
        <li key={product} className="product-list-item pli-link">
          <ScrollLink
            to={product}
            spy={true}
            smooth={true}
            duration={500}
            offset={
              deviceType < device.normalDesktop ? offset_touch : offset_desktop
            }
          >
            <Row>
              <Col xs="1" className="no-padding-right">
                {"//"}
              </Col>
              <Col xs="10">{productNames[product]}</Col>
            </Row>
          </ScrollLink>
        </li>
      );
    });
  }

  function getCondolenceCard() {
    return (
      <li key="condolence" className="product-list-item pli-link">
        <ScrollLink
          to="condolence"
          spy={true}
          smooth={true}
          duration={500}
          offset={
            deviceType < device.normalDesktop ? offset_touch : offset_desktop
          }
        >
          <Row>
            <Col xs="1" className="no-padding-right">
              {"//"}
            </Col>
            <Col xs="10">{productNames["condolence"]}</Col>
          </Row>
        </ScrollLink>
      </li>
    );
  }

  return (
    <Container fluid>
      <Pagetitle
        title={`Produkte ${motifName}`}
        description={`Hier finden Sie alle Produkte zu unserem Motiv ${motifName}, wie zum Beispiel Todesanzeigen, Traueranzeigen, Zeitungsanzeigen, Danksagungskarten oder Beileidskarten. Die Traueranzeigen und Zeitungsanzeigen können Sie in unserem Designer kostenlos selbst erstellen.`}
      />
      <Container
        fluid
        className="content-container section-with-light-colored-background"
      >
        <Container>
          <h2 className="page-heading">{motifName}</h2>
          <Row>
            <Col md="5">
              <div className="motif-image">
                <div className="motif-image-content" style={imageStyle} />
              </div>
            </Col>
            <Col md="7">
              <div className="intro-flex">
                <div className="flex-text">
                  <h3 className="section-heading">Das Motiv</h3>
                  {motifDescription.map((description, key) => (
                    <p key={key}>{description}</p>
                  ))}
                </div>

                <Row>
                  <Col
                    xl={productCondolence ? "4" : "6"}
                    lg={productCondolence ? "12" : "6"}
                  >
                    <p className="sub-sub-content-heading">
                      Online selbst gestalten:
                    </p>
                    <ul className="product-list">
                      {getSupportedProducts(true)}
                    </ul>
                  </Col>
                  {productCondolence ? (
                    <Col xl="4">
                      <p className="sub-sub-content-heading">
                        Direkt bestellen:
                      </p>
                      <ul className="product-list">{getCondolenceCard()}</ul>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col
                    xl={productCondolence ? "4" : "6"}
                    lg={productCondolence ? "12" : "6"}
                  >
                    <p className="sub-sub-content-heading">
                      Weitere Möglichkeiten:
                    </p>
                    <ul className="product-list">
                      {getSupportedProducts(false)}
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {productInvitation && (
        <ProductArea
          data={productInvitation}
          itemNo={productInvitation.item}
          product={"invitation"}
          motifName={motifName}
        />
      )}
      {productNewspaper && (
        <ProductArea
          data={productNewspaper}
          itemNo={productNewspaper.item}
          product={"newspaper"}
          motifName={motifName}
        />
      )}
      {productBooklet && (
        <ProductArea
          data={productBooklet}
          itemNo={productBooklet.item}
          product={"booklet"}
          motifName={motifName}
        />
      )}
      {productThankyou && (
        <ProductArea
          data={productThankyou}
          itemNo={productThankyou.item}
          product={"thankyou"}
          motifName={motifName}
        />
      )}
      {productCondolence && (
        <CondolenceCardArea
          data={productCondolence}
          itemNo={productCondolence.item}
          product={"condolence"}
          motifName={motifName}
        />
      )}
      {productB2BCondolence && (
        <ProductArea
          data={productB2BCondolence}
          itemNo={productB2BCondolence.item}
          product={"b2b_condolence"}
          motifName={motifName}
        />
      )}
    </Container>
  );
}

export default MotifPage;
