import React from "react";
import Helmet from "react-helmet";

const Pagetitle = ({ title, description }) => {
  var defaultTitle = "jungeTrauer";
  return (
    <Helmet>
      <title>{title ? defaultTitle + " | " + title : defaultTitle}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export { Pagetitle };
