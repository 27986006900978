import React from "react";

import "./MotifTile.css";
import { Link } from "react-router-dom";

MotifTile.propTypes = {};

function MotifTile({ tileColor = "#ffffff", motif }) {
  const imageStyle = {
    backgroundImage: motif ? `url('${motif.motifImageURL}')` : "none",
  };
  const backgroundStyle = {
    border: motif ? "1px solid black" : "none",
    backgroundColor: motif ? tileColor : "none",
  };
  return (
    <div className="tile" style={backgroundStyle}>
      <div className="tile-content" style={imageStyle}></div>
      {motif && <div className="overlay">{motif.name}</div>}
      {motif && <Link className="link" to={"/motifs/" + motif._id} />}
    </div>
  );
}

export default MotifTile;
