import React from "react";

import "../Partner.css";

function PartnerItem({ imageUrl, link, name }) {
  return (
    <a href={link} target="_blank" rel="noreferrer" >
      <img src={imageUrl} alt={`Logo von Partner ${name}`} className="partner-item" />
    </a>
  );
}

export default PartnerItem;
