import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// REDUCER

const designAdapter = createEntityAdapter();

const initialState = designAdapter.getInitialState();

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    updateDesignGenerator: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, generateDesign) {
        return { payload: { id: designId, changes: { generateDesign: generateDesign } } };
      },
    },
    updateMainPathSize: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, mainPathHeightPx, mainPathWidthPx) {
        return {
          payload: {
            id: designId,
            changes: {
              mainPathHeightPx: mainPathHeightPx,
              mainPathWidthPx: mainPathWidthPx,
            },
          },
        };
      },
    },
    updateFrame: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, frame) {
        return { payload: { id: designId, changes: { frame: frame } } };
      },
    },
    updateSpaceAlert: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, alertSet) {
        return { payload: { id: designId, changes: { spaceAlert: alertSet } } };
      },
    },
    updateBackgroundColor: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, color) {
        return { payload: { id: designId, changes: { color: color } } };
      },
    },
    updateDateIcon: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, dateIcon) {
        return { payload: { id: designId, changes: { dateIcon: dateIcon } } };
      },
    },
    updatePathColor: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, color) {
        return { payload: { id: designId, changes: { pathColor: color } } };
      },
    },
    updateTextColor: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, color) {
        return { payload: { id: designId, changes: { textColor: color } } };
      },
    },
    updateSelectedText: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, textId) {
        return {
          payload: { id: designId, changes: { selectedTextId: textId } },
        };
      },
    },
    updateSelectedTextType: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, textId) {
        return {
          payload: { id: designId, changes: { selectedTextType: textId } },
        };
      },
    },
    updateHeight: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, height) {
        return {
          payload: { id: designId, changes: { height: height } },
        };
      },
    },
    updateWidth: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, width) {
        return {
          payload: { id: designId, changes: { width: width } },
        };
      },
    },
    updateSizePx: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, height, width) {
        return {
          payload: {
            id: designId,
            changes: { pxHeight: height, pxWidth: width },
          },
        };
      },
    },
    deleteDesignById(state, action) {
      designAdapter.removeOne(state, action.payload);
    },
    addNewDesign: {
      reducer(state, action) {
        designAdapter.upsertOne(state, action.payload);
      },
      prepare(design, designId) {
        const designWithId = { ...design, id: designId };

        return {
          payload: designWithId,
        };
      },
    },
    updateStandardFontFamily: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontFamily) {
        return {
          payload: {
            id: designId,
            changes: { standardFontFamily: fontFamily },
          },
        };
      },
    },
    updateDateFontFamily: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontFamily) {
        return {
          payload: {
            id: designId,
            changes: { dateFontFamily: fontFamily },
          },
        };
      },
    },
    updateHighlightFontFamily: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontFamily) {
        return {
          payload: {
            id: designId,
            changes: { highlightFontFamily: fontFamily },
          },
        };
      },
    },
    updateNameFontFamily: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontFamily) {
        return {
          payload: {
            id: designId,
            changes: { nameFontFamily: fontFamily },
          },
        };
      },
    },
    updateStandardFontSize: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontSize) {
        return {
          payload: {
            id: designId,
            changes: { standardFontSize: fontSize },
          },
        };
      },
    },
    updateDateFontSize: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontSize) {
        return {
          payload: {
            id: designId,
            changes: { dateFontSize: fontSize },
          },
        };
      },
    },
    updateHighlightFontSize: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontSize) {
        return {
          payload: {
            id: designId,
            changes: { highlightFontSize: fontSize },
          },
        };
      },
    },
    updateNameFontSize: {
      reducer(state, action) {
        designAdapter.updateOne(state, action.payload);
      },
      prepare(designId, fontSize) {
        return {
          payload: {
            id: designId,
            changes: { nameFontSize: fontSize },
          },
        };
      },
    },
  },
});

export const {
  addNewDesign,
  deleteDesignById,
  updateSelectedText,
  updateDateFontFamily,
  updateHighlightFontFamily,
  updateNameFontFamily,
  updateStandardFontFamily,
  updateDateFontSize,
  updateHighlightFontSize,
  updateNameFontSize,
  updateStandardFontSize,
  updateSizePx,
  updateHeight,
  updateWidth,
  updateBackgroundColor,
  updateTextColor,
  updatePathColor,
  updateMainPathSize,
  updateSpaceAlert,
  updateFrame,
  updateSelectedTextType,
  updateDateIcon,
  updateDesignGenerator,
} = designSlice.actions;

export const designSelectors = () =>
  designAdapter.getSelectors((state) => state.design);

export const createSelectDesignById = () =>
  createSelector(
    (state, designId) => {
      return state.design.entities[designId];
    },
    (design) => {
      return design;
    }
  );

export const selectPxHeight = (state, designId) => {
  return state.design.entities[designId]?.pxHeight;
};

export const selectMainPathHeight = (state, designId) => {
  return state.design.entities[designId]?.mainPathHeightPx;
};

export const selectMainPathWidth = (state, designId) => {
  return state.design.entities[designId]?.mainPathWidthPx;
};

export const selectSelectedText = (state, designId) => {
  return state.design.entities[designId]?.selectedTextId;
}

export const selectSelectedTextType = (state, designId) => {
  return state.design.entities[designId]?.selectedTextType;
}

export const selectSpaceAlert = (state, designId) => {
  return state.design.entities[designId]?.spaceAlert;
}

export const selectDesignGenerator = (state, designId) => {
  return state.design.entities[designId]?.generateDesign ?? null;
}

export const checkDesignAvailable = (state, designId) => {
  return state.design.entities[designId] != null;
}

export default designSlice.reducer;
