import { configureStore } from "@reduxjs/toolkit";

import layoutSlice from "../components/DesignerV2/Layout/layoutSlice";
import designSlice from "../components/DesignerV2/Design/designSlice";
import pathSlice from "../components/DesignerV2/Pathdrawer/pathSlice";
import textSlice from "../components/DesignerV2/Textframe/textSlice";
import textAreaSlice from "../components/DesignerV2/TextArea/textAreaSlice";
import staticAreaSlice from "../components/DesignerV2/StaticArea/staticAreaSlice";
import printerSlice from "../components/DesignerV2/printerSlice";

const store = configureStore({
  reducer: {
    design: designSlice,
    layout: layoutSlice,
    path: pathSlice,
    text: textSlice,
    staticArea: staticAreaSlice,
    textArea: textAreaSlice,
    printer: printerSlice,
  },
});

export function createReduxStore() {
  const store = configureStore({
    reducer: {
      design: designSlice,
      layout: layoutSlice,
      path: pathSlice,
      text: textSlice,
      staticArea: staticAreaSlice,
      textArea: textAreaSlice,
      printer: printerSlice,
    },
  });
  return store;
}

export default store;
