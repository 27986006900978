import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../../App.css";
import "./productArea.css";
import ImageCarousel from "../../Page_Home/Motifs/ImageCarousel";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

ProductArea.propTypes = {
  data: PropTypes.object,
  itemNo: PropTypes.number,
  product: PropTypes.string,
  motifName: PropTypes.string,
};

const productTitles = {
  invitation: "Todesanzeige & Einladung zur Trauerfeier",
  newspaper: "Zeitungsanzeige",
  booklet: "Sterbebild",
  thankyou: "Danksagungskarte",
  condolence: "Beileidskarte",
  b2b_condolence: "Personalisierte Beileidskarte",
};

const productDescriptions = {
  invitation:
    "Die Karte wird verschickt, um den Todesfall bekannt zu geben. Optional können Sie hiermit zur Beisetzung einladen.",
  newspaper:
    "Neben der oben beschriebenen Todesanzeige wird oft auch eine Anzeige in der Zeitung abgedruckt. Sie soll die Öffentichkeit über den Tod informieren und kann Details zur Beisetzung enthalten.",
  booklet:
    "Sterbebilder sind kleine Klappkarten und enthalten meist ein Bild und die wichtigsten Daten der verstorbenen Person. Sie werden bei der Trauerfeier an die Anwesenden übergeben und dienen als Andenken. ",
  thankyou:
    "Mit Danksagungskarten wird den Menschen gedankt, die ihre Anteilnahme zum Ausdruck gebracht haben, bei der Beerdigung anwesend waren oder eine Beileidskarte geschickt haben.",
  condolence:
    "Eine Beileidskarte, Kondolenzkarte oder Trauerkarte nutzen Freunde und Bekannte, um den Angehörigen darin ihr Mitgefühl auszudrücken.",
  b2b_condolence:
    "Institutionen wie Hospize, Krankenhäuser und andere Einrichtungen verschicken jährlich viele Beileidskarten. Mit unseren Designs können sie individualisierte Karten und Briefumschläge verwenden, die zum Beispiel Ihr Unternehmens-Logo enthalten.",
};

const ourServiceDesigner = {
  invitation:
    "Erstellen Sie eine einseitige Anzeige in unserem online Designer. Per E-Mail erhalten Sie umgehend das Design im PDF-Format. Dies können Sie bei Ihrem Bestatter drucken lassen.",
  newspaper:
    "Erstellen Sie die Zeitungsanzeige in unserem online Designer. Per E-Mail erhalten Sie umgehend das Design im PDF-Format. Dies können Sie bei Ihrem Bestatter drucken lassen.",
  booklet:
    "Erstellen Sie das Sterbebild in unserem online Designer. Sie erhalten das Design als PDF-Datei umgehend per E-Mail, welche Sie bei Ihrem Bestatter in den Druck geben können.",
  thankyou:
    "Erstellen Sie die Danksagungskarte in unserem online Designer. Sie erhalten das Design als PDF-Datei umgehend per E-Mail, welche Sie bei Ihrem Bestatter in den Druck geben können.",
  condolence:
    "Erstellen Sie die Beileidskarte in unserem online Designer. Sie erhalten das Design als PDF-Datei umgehend per E-Mail, welche Sie bei Ihrem Bestatter in den Druck geben können.",
  b2b_condolence:
    "Erstellen Sie die personalisierte Beileidskarte in unserem online Designer. Sie erhalten das Design als PDF-Datei umgehend per E-Mail, welche Sie bei Ihrem Bestatter in den Druck geben können.",
};

const ourServiceCustom = {
  invitation:
    "Sie möchten Klappkarten oder haben spezielle Wünsche, die sich in unserem Designer nicht abbilden lassen? Nehmen Sie Kontakt zu uns auf.",
  newspaper:
    "Wir erstellen die Zeitungsanzeige nach Ihren individuellen Wünschen und senden sie Ihnen innerhalb von 24 Stunden als PDF-Datei zu. Diese können Sie dann an die Zeitung weiterleiten.",
  booklet:
    "Unsere Motive können ebenfalls auf Sterbebildchen angewendet werden. Wenn Sie Interesse hieran haben, versenden wir unsere Grafik-Dateien gerne direkt an Ihr Bestattungshaus.",
  thankyou:
    "Unsere Motive können ebenfalls auf Danksagungskarten angewendet werden. Wenn Sie Interesse hieran haben, versenden wir unsere Grafik-Dateien gerne direkt an Ihr Bestattungshaus.",
  condolence:
    "Wir schicken Ihnen die gewünschte Beileidskarte inklusive eines hochwertigen Briefumschlags zu. Bei einem 3er-Set können sie die Anzahl der einzelnen Motive frei wählen.",
  b2b_condolence:
    "Wir erstellen die doppelseitige Klappkarten nach Ihren Wünschen, lassen sie drucken und schicken sie Ihnen inklusive hochwertiger Briefumschläge zu.",
};

const ourPriceDesigner = {
  invitation:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
  newspaper:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
  booklet:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
  thankyou:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
  condolence:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
  b2b_condolence:
    "Wir sind noch in der Entwicklungsphase, daher bestimmen Sie den Preis und wir freuen uns über Ihre Unterstützung.",
};

const ourPriceCustom = {
  invitation: "Designanpassung ab 49,95 €",
  newspaper: "Designanpassung ab 49,95 €",
  booklet: "Designanpassung ab 34,95 €",
  thankyou:
    "Designanpassung ab 49,95 €\nStückpreis für Karte & Briefumschlag sind abhängig von der Druckauflage",
  condolence:
    "1 Karte inkl. Briefumschlag: 3,50 €\n3 Karten inkl. Briefumschläge: 9 € (14 % Rabatt)",
  b2b_condolence: "Auf Anfrage",
};

function ProductArea({ data, itemNo = 0, product = "", motifName = "" }) {
  const history = useHistory();
  const imageObjects = data.productImageURLs.map((url) => {
    return { url, caption: motifName + " " + productTitles[product] };
  });
  function selectColorClass() {
    switch (itemNo % 4) {
      case 0:
        return " section-with-colored-background ";
      case 1:
        return " section-with-light-colored-background ";
      case 3:
        return " section-with-light-colored-background ";
      default:
        return "";
    }
  }

  function getServiceText() {
    if (data.designId) return ourServiceDesigner;
    return ourServiceCustom;
  }

  function getPriceText() {
    if (data.designId) return ourPriceDesigner;
    return ourPriceCustom;
  }

  function getPath() {
    if (data.designId) return "/designer/" + data.designId;
    return "/contact";
  }

  function buttonClicked() {
    history.push({ pathname: getPath(), state: getState() });
  }

  function getState() {
    let selectedProduct = null;
    switch (product) {
      case "booklet":
        selectedProduct = { cardlet: true };
        break;
      case "thankyou":
        selectedProduct = { thankyou: true };
        break;
      case "invitation":
        selectedProduct = { griefcard: true };
        break;
      default:
        selectedProduct = null;
    }

    let contactReason = "customize";

    if (product === "b2b_condolence") contactReason = "custom_condolence";

    return { motif: motifName, products: selectedProduct, contactReason };
  }

  return (
    <Container
      fluid
      className={"content-container" + selectColorClass()}
      id={product}
    >
      <Container>
        <Row>
          <Col lg={itemNo % 2 === 0 ? { order: "last" } : { order: "first" }}>
            <h2 className="section-heading">{productTitles[product]}</h2>
            {productDescriptions[product]
              ?.split("\n")
              ?.map((description, key) => (
                <p key={`desc_${key}`}>{description}</p>
              ))}
            <hr />
            <Row>
              <Col xl="4" lg="12" md="3" className="no-padding-right">
                <p className="product-sub-heading">Unsere Leistung:</p>
              </Col>
              <Col xl="8" lg="12" md="9">
                {getServiceText()
                  [product]?.split("\n")
                  ?.map((description, key) => (
                    <p key={`service_${key}`} className="service-text">
                      {description}
                    </p>
                  ))}
              </Col>
            </Row>
            <hr />
            {/*(<Row>
              <Col lg="4" sm="3" className="no-padding-right">
                <p className="product-price-heading">Unser Preis:</p>
              </Col>
              <Col lg="8" sm="9">
                {getPriceText()
                  [product]?.split("\n")
                  ?.map((price, key) => (
                    <p key={`price_${key}`} className="service-text">
                      {price}
                    </p>
                  ))}
              </Col>
            </Row>
            <hr />)*/}
            <Button
              variant="dark"
              type="submit"
              className="order-button"
              onClick={() => {
                buttonClicked();
              }}
            >
              {data.designId
                ? "Jetzt kostenlos gestalten"
                : "Kontakt aufnehmen"}
            </Button>
            {product === "invitation" && (
              <p>
                Hinweis: Wenn Sie doppelseitige Klappkarten mit unserem Motiv
                wünschen, gehen Sie bitte auf Ihr Bestattungshaus zu. Ihr
                Bestattungshaus kann uns gerne{" "}
                <Link to={{ pathname: "/contact", state: getState() }}>
                  kontaktieren
                </Link>{" "}
                um die Grafik-Dateien zu erwerben.
              </p>
            )}
            {product === "newspaper" && (
              <p>
                Hinweis: Bitte erfragen Sie bei Ihrem Bestattungshaus oder der
                Zeitung die möglichen Formate der Zeitungsanzeige. Ihr
                Wunschformat können Sie dann in unserem Designer einstellen.
              </p>
            )}
          </Col>
          <Col lg>
            <ImageCarousel images={imageObjects} interval={null} size="60%" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default ProductArea;
