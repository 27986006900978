import React from "react";
import PropTypes from "prop-types";

import "./warningFrame.css";

WarningFrame.propTypes = {width: PropTypes.number,};

function WarningFrame({ width = 300 }) {
  const warningStyle = {
    width: width * 0.8 < 440 ? `${width * 0.8}px` : "440px",
    left: width * 0.8 < 440 ? `${width * 0.1}px` : `${(width - 440) / 2}px`,
  };

  const warningFontSize = {
    fontSize: width * 0.025 < 13 ? `${width * 0.025}px` : "13px",
  };
  return (
    <div className="warningFrame" style={warningStyle} data-testid="_warningFrame_">
      <p className="warningText" style={warningFontSize} data-testid="_warningText_">
        Der Text übersteigt den verfügbaren Platz.
        <br />
        Bitte verändern Sie die Schriftgröße oder die Textlänge.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/WarningSign.svg"}
        className="warningImg"
        data-testid="_warningImage_"
        alt="Warnschild"
      />
    </div>
  );
}

export default WarningFrame;
