import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import MotifTile from "./MotifTile";

import { Pagetitle } from "../HelperComponents/Pagetitle";

import "./Page_MotifTiles.css";
import "../App.css";
import { useAlert } from "react-alert";

Page_MotifTiles.propTypes = {};

const tileColors = ["#ddddee", "#ffffff", "#bababa"];
const ERROR_MESSAGE_500 =
  "Es gab Probleme bei der Kommunikation mit unseren Servern. Versuchen Sie es bitte erneut.";
const ERROR_MESSAGE_404 =
  "Bitte versuchen Sie es erneut oder wenden sich an support@jungetrauer.de";

function Page_MotifTiles(props) {
  const [motifs, setMotifs] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    fetch("/api/motifs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setMotifs(data);
            console.log(data);
          });
        } else {
          if (res.status === 404) {
            alert.show(ERROR_MESSAGE_404, {
              title: "Keine Daten gefunden",
            });
          } else {
            alert.show(ERROR_MESSAGE_500, {
              title: "Fehler",
            });
          }
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        alert.show(ERROR_MESSAGE_500, {
          title: "Fehler",
        });
        console.log(error);
      });
  }, [alert]);

  function createRows(motifs) {
    let rows = [];
    const chunk = 3;
    for (let i = 0; i < motifs.length; i += chunk) {
      let rowNumber = i / chunk;
      rows.push(createRow(motifs.slice(i, i + chunk), rowNumber));
    }
    return rows;
  }

  function createRow(row, rowNumber) {
    return (
      <div key={rowNumber} className="tile-row">
        {createTiles(row, rowNumber)}
      </div>
    );
  }

  function createTiles(row, rowNumber) {
    let tiles = [];
    for (let i = 0; i < 3; i++) {
      if (row[i] == null) {
        tiles.push(<MotifTile />);
      } else {
        let colorMover = rowNumber % 3 === 2 ? rowNumber - 2 : rowNumber;
        let colorNumber = (colorMover + i + Math.floor(rowNumber / 3)) % 3;
        tiles.push(
          <MotifTile
            key={row[i].name}
            motif={row[i]}
            tileColor={tileColors[colorNumber]}
          />
        );
      }
    }
    return tiles;
  }

  return (
    <Container fluid>
      <Pagetitle
        title={`Motive`}
        description={`Hier finden Sie all unsere Motive, die Sie für Todesanzeigen, Traueranzeigen, Zeitungsanzeigen, Danksagungskarten oder Beileidskarten verwenden können.`}
      />
      <Container
        fluid
        className="content-container section-with-light-colored-background"
      >
        <Container>
          <h3 className="content-heading">Wählen Sie Ihr Wunschmotiv. </h3>
        </Container>
        <Container>{createRows(motifs)}</Container>
      </Container>
    </Container>
  );
}

export default Page_MotifTiles;
