import React from "react";
import { Container } from "react-bootstrap";
import { Pagetitle } from "../HelperComponents/Pagetitle";


import "../App.css";
import "./FooterPages.css";

function Imprint() {
  return (
    <Container fluid className="content-container">
      <Pagetitle title="Impressum"/>
      <Container className="section-with-light-colored-background padded">
        <h2 className="section-heading">Impressum</h2>

        <h3 className="content-heading">Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
        <p>
          jungeTrauer
          <br />
          Sarah Hoffmann, Nikolai Merz GbR<br />
          Abelsbergstr. 60<br />
          70188 Stuttgart
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Sarah Merz
          <br />
          Nikolai Merz
        </p>

        <p>Umsatzsteuer-ID gemäß § 27a Umsatzsteuergesetz: DE334077048</p>

        <h3 className="content-heading">Kontakt</h3>
        <p>
          Telefon: +49 1522 6838935
          <br />
          E-Mail: <a href="mailto:kontakt@jungetrauer.de">kontakt@jungetrauer.de</a>
        </p>

        <h3 className="content-heading">EU-Streitschlichtung</h3>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
      </Container>
    </Container>
  );
}

export default Imprint;
