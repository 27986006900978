import { createSlice } from "@reduxjs/toolkit";

// REDUCER

const initialState = { paths: [], texts: [] };

const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    addPath: {
      reducer(state, action) {
        state.paths.push(action.payload);
      },
      prepare(path) {
        return { payload: path };
      },
    },
    addText: {
      reducer(state, action) {
        state.texts.push(action.payload);
      },
      prepare(text) {
        return { payload: text };
      },
    },
    addDesignBoundings: {
      reducer(state, action) {
        state.designBoundings = action.payload;
      },
      prepare(designBoundings) {
        return { payload: designBoundings };
      },
    },
    resetPrinter: (state) => {
      state.texts = [];
      state.paths = [];
      state.designBoundings = {};
    },
  },
});

export const {
  addPath,
  addText,
  addDesignBoundings,
  resetPrinter,
} = printerSlice.actions;

export const selectTexts = (state) => {
  return state.printer.texts;
};


export const selectPaths = (state) => {
  return state.printer.paths;
};

export const selectDesignBoundings = (state) => {
  return state.printer.designBoundings;
};


export default printerSlice.reducer;
