import React, { useRef, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  createSelectLayoutSections,
  selectLayoutType,
  selectLayoutLevel,
  updateAddHeight,
  selectLayoutDesignId,
} from "./layoutSlice";
import { selectPxHeight, updateSpaceAlert } from "./../Design/designSlice";

import StaticArea from "./../StaticArea";
import TextArea from "./../TextArea";

function Layout({
  layoutId = "",
  layoutHeight = 30,
  layoutWidth = 30,
  layoutPosX = 0,
  layoutPosY = 0,
  devMode = false,
  editable = true,
  designLoaded = false,
  setSpace: setSpaceParent = (id, space) => true,
}) {
  const dispatch = useDispatch();
  const selectLayoutSections = useMemo(createSelectLayoutSections, []);
  const sections =
    useSelector((state) => selectLayoutSections(state, layoutId)) ?? [];
  const layoutType =
    useSelector((state) => selectLayoutType(state, layoutId)) ?? "row-layout";
  const layoutLevel =
    useSelector((state) => selectLayoutLevel(state, layoutId)) ?? 0;
  const designId = useSelector((state) =>
    selectLayoutDesignId(state, layoutId)
  );
  const pxHeight = useSelector((state) => selectPxHeight(state, designId));

  const previousHeight = useRef(-1);
  const previousLayoutSpace = useRef(-1);
  const sectionSpaces = useRef({});
  const expectedSectionSpaces = useRef({});

  useEffect(() => {
    if (layoutHeight !== previousHeight.current) {
      previousHeight.current = layoutHeight;
      recalculateLayoutSpacing();
    }
    // eslint-disable-next-line
  }, [layoutHeight]);

  const horizontalScale = layoutWidth / 1000;
  const verticalScale = layoutHeight / 1000;

  function recalculateLayoutSpacing() {
    // At the end of the Layout Rendering Process we check whether the space is
    // distributed evenly. If not the Section sizes get recalculated
    if (pxHeight == null) return; // block resizing if not rendered yet.
    if (layoutType === "column-layout") {
      // Horizontal layout behavior
      const smallestColumnSpace = getSmallestColumnSpace();
      updateLayoutSpace(smallestColumnSpace);
      return;
    }

    // Vertical layout Behavior
    verticalLayoutSectionResizer();
  }

  function verticalLayoutSectionResizer() {
    let layout_spaceLeft = 0;
    let layout_fullWeight = 0;
    const sectionsWithSpace = Object.entries(sectionSpaces.current).map(
      (section) => {
        const [id, space] = section;
        const { addHeight, height } = sections.find(
          (section) => section.id === id
        );
        layout_spaceLeft = layout_spaceLeft + space;
        layout_fullWeight = layout_fullWeight + height;
        return {
          id: id,
          addHeight: addHeight ?? 0,
          space: space,
          weight: height,
        };
      }
    );
    updateLayoutSpace(layout_spaceLeft); // adapting the new "complete-space" of the layout
    const fullAddHeight = layout_spaceLeft;
    sectionsWithSpace.forEach((section) => {
      // distributing the space evenly between all sections (based on calculated weight)
      const newSectionSpace =
        fullAddHeight * (section.weight / layout_fullWeight);
      const additionalHeight = newSectionSpace - section.space;
      const newAdditionalHeight = section.addHeight + additionalHeight;

      // storing the expectedSectionSpaces, to prevent a retrigger of this function, if the section has already the correct spacing
      expectedSectionSpaces.current = {
        ...expectedSectionSpaces.current,
        [section.id]: newSectionSpace,
      };
      // adding some height on top of the original height of the section
      dispatch(updateAddHeight(layoutId, section.id, newAdditionalHeight));
    });
  }

  function getSmallestColumnSpace() {
    // needed for horizontal layout --> here only the least space is relevant for higher order layouts
    const sectionsWithSpace = Object.entries(sectionSpaces.current);
    if (sectionsWithSpace.length === 0) return 0;
    let smallestColumnSpace;
    sectionsWithSpace.forEach((section) => {
      if (smallestColumnSpace == null || section[1] < smallestColumnSpace) {
        smallestColumnSpace = section[1];
      }
    });
    return smallestColumnSpace;
  }

  function updateLayoutSpace(space) {
    if (space === previousLayoutSpace.current) return;
    previousLayoutSpace.current = space;
    if (layoutLevel === 0) {
      // Handle Warning for too less space here
      const spaceAlertSet = space >= 0 ? false : true;
      dispatch(updateSpaceAlert(designId, spaceAlertSet));
      return;
    }
    setSpaceParent(layoutId, space);
  }

  function setSectionSpace(sectionId, space) {
    sectionSpaces.current = { ...sectionSpaces.current, [sectionId]: space };
    const expectedSpace = expectedSectionSpaces.current[sectionId];
    if (
      expectedSpace == null ||
      expectedSpace - space > 0.1 ||
      expectedSpace - space < -0.1
    ) {
      recalculateLayoutSpacing();
    }
  }

  function calculateSectionPositionAndSize(section) {
    // Anhand des Layouts werden die Positionen der einzelnen Felder berechnet
    // Es werden die positionen und größen in px berechnet
    const { position } = section ?? 0;
    // Felder mit höherer Layout-Prio schieben niedrigere Prios nach rechts.
    const higherOrderSections = sections.filter((currSection) => {
      return currSection.position < position;
    });
    if (layoutType === "column-layout") {
      let posX = 0;
      higherOrderSections.forEach((higherSection) => {
        posX = posX + higherSection.width;
      });
      return {
        width: horizontalScale * section.width,
        height: layoutHeight,
        posX: horizontalScale * posX,
        posY: 0,
      };
    } else {
      // We use row-layout as default if something is wrong
      let posY = 0;
      // Felder mit höherer Layout-Prio schieben niedrigere Prios nach unten.
      higherOrderSections.forEach((higherSection) => {
        posY =
          posY +
          verticalScale * higherSection.height +
          (higherSection.addHeight ?? 0);
      });
      return {
        width: layoutWidth,
        height: verticalScale * section.height + (section.addHeight ?? 0),
        posX: 0,
        posY: posY,
      };
    }
  }

  function addSectionBySectionType(section) {
    const sectionProperties = calculateSectionPositionAndSize(section);
    switch (section.sectionType) {
      case "layout":
        return (
          <Layout
            key={section.id}
            layoutId={section.id}
            layoutPosX={sectionProperties.posX}
            layoutPosY={sectionProperties.posY}
            layoutWidth={sectionProperties.width}
            layoutHeight={sectionProperties.height}
            devMode={devMode}
            setSpace={setSectionSpace}
            editable={editable}
            designLoaded={designLoaded}
          />
        );
      case "text-area":
        return (
          <TextArea
            key={section.id}
            textAreaId={section.id}
            textAreaPosX={sectionProperties.posX}
            textAreaPosY={sectionProperties.posY}
            textAreaWidth={sectionProperties.width}
            textAreaHeight={sectionProperties.height}
            devMode={devMode}
            setSpace={setSectionSpace}
            editable={editable}
            designLoaded={designLoaded}
          />
        );
      case "static-area":
        return (
          <StaticArea
            key={section.id}
            staticAreaId={section.id}
            staticAreaPosX={sectionProperties.posX}
            staticAreaPosY={sectionProperties.posY}
            staticAreaWidth={sectionProperties.width}
            staticAreaHeight={sectionProperties.height}
            staticAreaMinHeight={
              section.minHeight != null
                ? section.minHeight * verticalScale
                : null
            }
            devMode={devMode}
            setSpace={setSectionSpace}
            editable={editable}
            designLoaded={designLoaded}
          />
        );
      default:
        return null;
    }
  }

  let layoutStyle = {
    width: layoutWidth.toString() + "px",
    height: layoutHeight.toString() + "px",
    top: layoutPosY.toString() + "px",
    left: layoutPosX.toString() + "px",
    borderStyle: "none",
    position: "absolute",
    overflow: "visible",
    margin: "0px",
    padding: "0px",
  };

  return (
    <div style={layoutStyle} data-testid={`_layout_${layoutId}`}>
      {sections.map((section) => addSectionBySectionType(section))}
    </div>
  );
}

Layout.propTypes = {
  layoutId: PropTypes.string,
  layoutHeight: PropTypes.number,
  layoutWidth: PropTypes.number,
  layoutPosX: PropTypes.number,
  layoutPosY: PropTypes.number,
};

export default Layout;
