import React, { useState } from "react";
import { Container, Collapse, Row, Col } from "react-bootstrap";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";

import "./../../../App.css";
import "./SettingCollapser.css";

function SettingCollapser({ title, children }) {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Row
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        style={{ cursor: "pointer" }}
      >
        <Col xs={10}>
          <h3 className="settingTitle">{title}</h3>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <h3 className="settingTitle">
            {open ? <BsChevronCompactUp /> : <BsChevronCompactDown />}
          </h3>
        </Col>
      </Row>
      <Collapse in={open}>
        <div className="setting">
          <div className="space" />
          {React.Children.map(children, (child) => child)}
        </div>
      </Collapse>

      <hr />
    </Container>
  );
}

export default SettingCollapser;
