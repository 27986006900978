import React from "react";
import { FormattedMessage } from "react-intl";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "../Home.css";
import "../../App.css";

function Intro() {
  return (
    <Container fluid className="content-container" id="intro">
      <Container>
        <h2 className="section-heading">
          <FormattedMessage id="home.intro.section1heading" />
        </h2>

        <h3 className="content-heading">
          <FormattedMessage id="home.intro.heading01" />
        </h3>
        <p>
          <FormattedMessage id="home.intro.text01" values={{ br: <br /> }} />
        </p>
        <div style={{ position: "relative", height: "25px" }}></div>
        <h3 className="content-heading">
          <FormattedMessage id="home.intro.heading02" />
        </h3>
        <p>
          <FormattedMessage id="home.intro.text02" values={{ br: <br /> }} />
        </p>
        <div style={{ position: "relative", height: "25px" }}></div>
        <Row>
          <Col md="6">
            <h3 className="sub-content-heading">
              <FormattedMessage id="home.cause.illness.title" />
            </h3>
            <p>
              <FormattedMessage id="home.cause.illness.text" />
            </p>
          </Col>
          <Col md="6">
            <h3 className="sub-content-heading">
              <FormattedMessage id="home.cause.accident.title" />
            </h3>
            <p>
              <FormattedMessage id="home.cause.accident.text" />
            </p>
          </Col>
          <Col md="6">
            <h3 className="sub-content-heading">
              <FormattedMessage id="home.cause.suicide.title" />
            </h3>
            <p>
              <FormattedMessage id="home.cause.suicide.text" />
            </p>
          </Col>
          <Col md="6">
            <h3 className="sub-content-heading">
              <FormattedMessage id="home.cause.stillborn.title" />
            </h3>
            <p>
              <FormattedMessage id="home.cause.stillborn.text" />
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
