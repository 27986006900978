import React, { useState } from "react";
import { Container, Collapse, Row, Col } from "react-bootstrap";
import { BsChevronCompactDown, BsChevronCompactUp} from "react-icons/bs";


import "../../App.css";
import "./Collapser.css";

function Collapser(props) {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Row
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        style={{cursor:"pointer"}}
      >
        <Col xs={10}>
          <h3 className="question">{props.question}</h3>
        </Col>
        <Col
            style={{textAlign: "right"}}>
            <h3 className="question">{open?<BsChevronCompactUp />:<BsChevronCompactDown />}</h3>

        </Col>
      </Row>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <p className="answer"><br />{props.answer}</p>
        </div>
      </Collapse>

      <hr />
    </Container>
  );
}

export default Collapser;
