export const motifs = [
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Amsel_BKJE_KUo_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Amsel_BKJE_KUo.jpg",
    caption: 'Trauerkarte "Amsel"',
    id: '461928dc-1520-412a-997c-ae2a804afee8',
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Feder_BKJE_KUS_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Feder_BKJE_KUS.jpg",
    caption: 'Trauerkarte "Feder"',
    id: "60838590-6dcf-4694-a34a-80c620dec078",
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Hand_BKoo_KUS_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Hand_BKoo_KUS.png",
    caption: 'Trauerkarte "Hand"',
    id: '779be3d6-1869-4036-82cd-2fafd5a0199f',
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Pusteblume_ooJE_ooS_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Pusteblume_ooJE_ooS.jpg",
    caption: 'Trauerkarte "Pusteblume"',
    id: '91148408-ccd2-4d63-a5f9-b0b7bd32ee32',
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Sterntaler_BKoo_KUo_farblos+(1).png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Sterntaler_BKoo_KUo.jpg",
    caption: 'Trauerkarte "Sterntaler"',
    id: 'c6de25ed-8e8b-46d7-a1b8-37c33ef9be26',
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Taube_BKJE_oUS_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Taube_BKJE_oUS.jpg",
    caption: 'Trauerkarte "Taube"',
    id: 'ac4ad2bd-9e48-4c9c-8409-adbf557363d0',
  },
  {
    url:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/Trauerkarte_DINA6_quer_Foto_BKJE_KUO_farblos.png",
    urlWhite:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/motifs/withBackground/Trauerkarte_DINA6_quer_Foto_BKJE_KUO.png",
    caption: 'Trauerkarte "Foto"',
    id: '0b92a018-dce2-4eea-868a-c7b0b698b102',
  },
];
