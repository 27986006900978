import React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";
import { Pagetitle } from "../HelperComponents/Pagetitle";


import "../App.css";
import "./FooterPages.css";
import "../HelperComponents/LoremIpsum";

function AGB() {
  return (
    <Container fluid className="content-container">
    <Pagetitle title="AGBs"/>
      <Container className="section-with-light-colored-background padded">
        <h1 className="section-heading"><FormattedMessage id="agb.title"/> </h1>
        <h4><FormattedMessage id="agb.p1.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p1.text1"/></li>
          <li><FormattedMessage id="agb.p1.text2"/></li>
          <li><FormattedMessage id="agb.p1.text3"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p2.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p2.text1"/></li>
          <li><FormattedMessage id="agb.p2.text2"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p3.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p3.text1"/></li>
          <li><FormattedMessage id="agb.p3.text2"/></li>
          <li><FormattedMessage id="agb.p3.text3"/></li>
          <li><FormattedMessage id="agb.p3.text4"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p4.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p4.text1"/></li>
          <li><FormattedMessage id="agb.p4.text2"/></li>
          <li><FormattedMessage id="agb.p4.text4"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p5.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p5.text"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p6.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p6.text1"/></li>
          <li><FormattedMessage id="agb.p6.text2"/></li>
          <li><FormattedMessage id="agb.p6.text3"/></li>
        </ol>
        <h4><FormattedMessage id="agb.p7.title"/></h4>
        <ol>
          <li><FormattedMessage id="agb.p7.text1"/></li>
          <li><FormattedMessage id="agb.p7.text2"/></li>
          <li><FormattedMessage id="agb.p7.text3"/></li>
        </ol>
        
      </Container>
    </Container>
  );
}

export default AGB;
