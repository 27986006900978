import React from "react";
import Logo from "../Logo/";
import {FormattedMessage} from "react-intl";

import "./Success.css";
import "../App.css";

function Success() {
  return (
    <div className="fillPage section-with-colored-background">
      <div className="logoCircle">
        <Logo className="circle-logo" twoLines size="36" />
      </div>
      <h2 className="section-heading"><FormattedMessage id="success.thanks" /></h2>
      <p><FormattedMessage id="success.mail" /></p>
      <p><small><FormattedMessage id="success.hint" /></small></p>
    </div>
  );
}

export default Success;
