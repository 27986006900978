export const dateIcons= [
    { value: "a", label: "   a     A" },
    { value: "b", label: "   b     B" },
    { value: "c", label: "   c     C" },
    { value: "d", label: "   d     D" },
    { value: "e", label: "   e     E" },
    { value: "f", label: "   f     F" },
    { value: "g", label: "   g     G" },
    { value: "h", label: "   h     H" },
    { value: "i", label: "   i     I" },
    { value: "j", label: "   j     J" },
]