import React from "react";
import { FormattedMessage } from "react-intl";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Container from "react-bootstrap/Container";

import "../Home.css";
import "../../App.css";
import "./AboutUs.css";

function AboutUs() {
  return (
    <Container
      fluid
      className="content-container section-with-colored-background"
      id="aboutus"
    >
      <Container>
        <h2 className="section-heading">
          <FormattedMessage id="home.aboutus.title" />
        </h2>
        <Row>
          <Col lg={6} xl={8} className="vertically-centered">
            <h3 className="content-heading">
              <FormattedMessage id="home.aboutus.heading" />
            </h3>

            <p style={{ paddingRight: "5%" }}>
              <FormattedMessage
                id="home.aboutus.text"
                values={{ br: <br /> }}
              />
            </p>
          </Col>
          <Col lg={6} xl={4} className="center">
          <div className="aboutus-image"></div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default AboutUs;
