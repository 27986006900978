import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// REDUCER

const textAreaAdapter = createEntityAdapter();

const initialState = textAreaAdapter.getInitialState();

const textAreaSlice = createSlice({
  name: "textareas",
  initialState,
  reducers: {
    deleteTextAreasByDesignId(state, action) {
      const designId = action.payload;
      const idsToDelete = state.ids.filter(
        (id) => state.entities[id].design === designId
      );
      textAreaAdapter.removeMany(state, idsToDelete);
    },
    generateTextAreasWithDesign: {
      reducer(state, action) {
        textAreaAdapter.addMany(state, action.payload);
      },
      prepare(textsAreas, designId) {
        const textAreasWithDesignId = textsAreas.map((textArea) => {
          return { ...textArea, design: designId };
        });
        return {
          payload: textAreasWithDesignId,
        };
      },
    },
  },
});

export const {
  deleteTextAreasByDesignId,
  generateTextAreasWithDesign,
} = textAreaSlice.actions;

export const textAreaSelectors = textAreaAdapter.getSelectors(
  (state) => state.textArea
);

export const createSelectTextAreaPaths = () =>
  createSelector(
    (state, textAreaId) => {
      return state.textArea.entities[textAreaId]?.paths;
    },
    (paths) => {
      return paths;
    }
  );

export const createSelectTextArea = () =>
  createSelector(
    (state, textAreaId) => {
      return state.textArea.entities[textAreaId];
    },
    (mTextArea) => {
      return mTextArea;
    }
  );

export const selectTextAreaDesignId = (state, textAreaId) => {
  return state.textArea.entities[textAreaId]?.design;
};

export default textAreaSlice.reducer;
