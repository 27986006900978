import React, { useRef, useState } from "react";

import Container from "react-bootstrap/Container";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useDeviceType, {device} from "../../HelperComponents/useDeviceType";

import "../../App.css";
import "./Header.css";

function Header(props) {
  let samen01 = useRef(null);
  let samen02 = useRef(null);
  let samen03 = useRef(null);
  let samen04 = useRef(null);
  let samen05 = useRef(null);
  let samen06 = useRef(null);
  let samen07 = useRef(null);
  let samen08 = useRef(null);
  let samen09 = useRef(null);
  let samen10 = useRef(null);
  let samen11 = useRef(null);

  let schmetterling1 = useRef(null);
  let schmetterling1Vertikal = useRef(null);
  let s1Fluegel1 = useRef(null);
  let s1Fluegel2 = useRef(null);

  let schmetterling2 = useRef(null);
  let schmetterling2Vertikal = useRef(null);
  let s2Fluegel1 = useRef(null);
  let s2Fluegel2 = useRef(null);

  let title = useRef(null);

  let [scrollover, setScrollover] = useState(false);


  const deviceType = useDeviceType();
  let maxScrollPosition = -700;

  if (deviceType > device.tablet) {
    maxScrollPosition = -700;
  } else if (deviceType > device.smartphone) {
    maxScrollPosition = -500;
  } else {
    maxScrollPosition = -400;
  }

  // Scroll Behavior

  useScrollPosition(({ prevPos, currPos }) => {
    onScroll(prevPos, currPos);
  });

  function onScroll(prevPos, currPos) {
    // For Apple devices: When scrolling over, the Image shouldn't be fixed anymore but move with the content
    if (currPos.y > 0) {
      if (!scrollover) setScrollover(true);
    }

    if (scrollover) {
      if (prevPos.y > currPos.y) {
        if (currPos.y < 1) {
          setScrollover(false);
        }
      }
    }

    // Animation of Header
    if (samen01) {
      if (currPos.y >= maxScrollPosition) {
        let linear = -(currPos.y / 10);
        let squareRoot = -Math.sqrt(Math.abs(currPos.y)) * 2;
        let cosinus = Math.cos((currPos.y - 60) / 80);
        let cosinusFast = Math.cos((currPos.y - 33) / 50);

        if (deviceType >= device.smartphone) {
          samen01.style.transform =
            `translateX(${linear}px)` +
            `translateY(${squareRoot}px)` +
            `rotate(${-15 + linear}deg)`;

          samen02.style.transform =
            `translateY(${squareRoot * 1.5}px) rotate(${15 + linear}deg)`;

          samen03.style.transform =
            `translateX(${squareRoot}px)` +
            `translateY(${squareRoot}px)` +
            `rotate(${-15 + linear / 2}deg)`;

          samen04.style.transform =
            `translateX(${-linear / 2}px)` +
            `translateY(${squareRoot * 2}px)` +
            `rotate(${25 - linear}deg)`;

          samen10.style.transform =
            `translateX(${squareRoot / 2}px)` +
            `translateY(${squareRoot * 2}px)` +
            `rotate(${-50 - linear}deg)`;

          schmetterling2.style.transform =
            `translateX(${-squareRoot}px) translateY(${squareRoot}px)`;

          schmetterling2Vertikal.style.transform = `translateY(${
            cosinusFast * 10
          }px)`;

          s2Fluegel1.style.transform = `rotateX(${cosinusFast * 80 - 90}deg)`;

          s2Fluegel2.style.transform = `rotateX(${cosinusFast * -80 + 90}deg)`;

          
          if (currPos.y <= 0) {
            title.style.transform = `translateY(${-linear * 3}px)`;
            title.style.opacity = 1 / (1 + linear / 2);
          }else{
            title.style.opacity = 1;
            title.style.transform = `translateY(0px)`;
          }
        }

        if (deviceType >= device.tablet) {
          samen05.style.transform =
            `translateX(${linear}px)` +
            `translateY(${squareRoot}px)` +
            `rotate(${31 + linear}deg)`;

          samen06.style.transform =
            `translateX(${-squareRoot * 2}px)` +
            `translateY(${-linear * 1.5}px)` +
            `rotate(${-28 + linear / 2}deg)`;
        }

        if (deviceType >= device.normalDesktop) {
          samen07.style.transform =
            `translateX(${linear}px)` +
            `translateY(${-linear * 2}px)` +
            `rotate(${-15 + linear}deg)`;

          samen08.style.transform =
            `translateX(${linear * 2}px)` +
            `translateY(${-linear / 2}px)` +
            `rotate(${-20 - squareRoot}deg)`;

          samen09.style.transform =
            `translateX(${linear * 2}px)` +
            `translateY(${-linear}px)` +
            `rotate(${-20 - squareRoot}deg)`;

          samen11.style.transform =
            `translateX(${-squareRoot * 2}px)` +
            `translateY(${-linear / 2}px)` +
            `rotate(${23 - squareRoot / 2}deg)`;

          schmetterling1.style.transform =
            `translateX(${linear * 2}px) translateY(${-linear / 2}px)`;

          schmetterling1Vertikal.style.transform = `translateY(${
            cosinus * 10
          }px)`;

          s1Fluegel1.style.transform = `rotateX(${cosinus * 80 - 90}deg)`;

          s1Fluegel2.style.transform = `rotateX(${cosinus * -80 + 90}deg)`;
        }
      }
    }
  }

  return (
    <Container fluid className="section-with-colored-background header-wrapper">
      <Container className="header">
        <div
          className="header-container"
          style={{ position: scrollover ? "absolute" : "fixed" }}
        >
          <img
            className="header-image"
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Pusteblume.svg"
            alt="Pusteblumen Bild"
          />
          <img
            className="samen-1"
            ref={(el) => {
              samen01 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen08.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-2"
            ref={(el) => {
              samen02 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen01.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-3"
            ref={(el) => {
              samen03 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen02.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-4"
            ref={(el) => {
              samen04 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen03.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-5"
            ref={(el) => {
              samen05 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen04.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-6"
            ref={(el) => {
              samen06 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen05.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-7"
            ref={(el) => {
              samen07 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen06.svg"
            alt="Pusteblumen Samen"
          />
          <img
            className="samen-8"
            ref={(el) => {
              samen08 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen07.svg"
            alt="Pusteblumen Samen"
          />

          <img
            className="samen-9"
            ref={(el) => {
              samen09 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen04.svg"
            alt="Pusteblumen Samen"
          />

          <img
            className="samen-10"
            ref={(el) => {
              samen10 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen06.svg"
            alt="Pusteblumen Samen"
          />

          <img
            className="samen-11"
            ref={(el) => {
              samen11 = el;
            }}
            src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Samen06.svg"
            alt="Pusteblumen Samen"
          />

          <div
            className="schmetterling-1"
            ref={(el) => {
              schmetterling1 = el;
            }}
          >
            <div
              className="schmetterling-vertical-animation"
              ref={(el) => {
                schmetterling1Vertikal = el;
              }}
            >
              <div className="schmetterling-zb">
                <img
                  className="schmetterling-fluegel1"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
                  alt="Schmetterling-Flügel"
                  ref={(el) => {
                    s1Fluegel1 = el;
                  }}
                />
                <img
                  className="schmetterling-koerper"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Koerper.svg"
                  alt="Schmetterling-Körper"
                />
                <img
                  className="schmetterling-fluegel2"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
                  alt="Schmetterling-Flügel"
                  ref={(el) => {
                    s1Fluegel2 = el;
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="schmetterling-2"
            ref={(el) => {
              schmetterling2 = el;
            }}
          >
            <div
              className="schmetterling-vertical-animation"
              ref={(el) => {
                schmetterling2Vertikal = el;
              }}
            >
              <div className="schmetterling-zb">
                <img
                  className="schmetterling-fluegel1"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
                  alt="Schmetterling-Flügel"
                  ref={(el) => {
                    s2Fluegel1 = el;
                  }}
                />
                <img
                  className="schmetterling-koerper"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Koerper.svg"
                  alt="Schmetterling-Körper"
                />
                <img
                  className="schmetterling-fluegel2"
                  src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
                  alt="Schmetterling-Flügel"
                  ref={(el) => {
                    s2Fluegel2 = el;
                  }}
                />
              </div>
            </div>
          </div>
          <h1
            className="title"
            ref={(el) => {
              title = el;
            }}
          >
            {props.notfound ? '404' : 'Warum Du?'}
            <br />
            {props.notfound ? 'Seite nicht gefunden' : 'Warum so früh?'}
            
          </h1>
        </div>
        <div className="window"></div>
      </Container>
    </Container>
  );
}

export default Header;
