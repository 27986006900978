import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Bowser from "bowser";

import { DisappearedLoading } from "react-loadingg";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Design from "../../DesignerV2/Design";
import useDeviceType, { device } from "../../HelperComponents/useDeviceType";
import WaitButterfly from "../../Page_Contact/ContactForm/WaitButterfly";

import { FaRegQuestionCircle } from "react-icons/fa";

import "../order.css";

let PayPalButton;// 

const paymentType = {
  noSelection: "noSelection",
  paypal: "paypal",
  invoice: "invoice",
  free: "free",
};

function OrderForm({ intl, designId = "DESIGN_V2", printData = {} }) {
  const history = useHistory();
  useEffect(() => {
    const paypalScript = document.createElement("script");
    paypalScript.src = "https://www.paypal.com/sdk/js?client-id=ARaOINkPwurEv7n-mKBTykIai97aUT8K1Pw2fwd_-RxQ_TNM6WQz2nTRkfHmik2Mnu6YRTO7b-FkxtpE&currency=EUR&disable-funding=credit,card,sepa,sofort,giropay";
    paypalScript.async = true;
    document.head.append(paypalScript);
    console.log(window.paypal);
    paypalScript.onload = () => {
      PayPalButton=window.paypal.Buttons.driver("react", { React, ReactDOM });
    };

    return () => {
      paypalScript.remove();
    };
  }, []);

  // Send the E-Mail
  function sendPDF(customerData, token) {
    const data = {
      customer: customerData,
      design: printData,
      captchatoken: token,
      email: customerData.email,
      order: {
        sum: sum,
        paymentMethod: payment,
      },
      feedback: feedback,
    };

    return new Promise((resolve, reject) => {
      fetch("/api/pdfDesign", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            setWaitForBackendResponse(false);
            if (res.status === 403) {
              alert.show(
                intl.formatMessage({ id: "error.mail.blacklist.instruction" }),
                {
                  title: intl.formatMessage({
                    id: "error.mail.blacklist.error",
                  }),
                }
              );
            } else {
              alert.show(
                intl.formatMessage({ id: "error.mail.default.instruction" }),
                {
                  title: intl.formatMessage({ id: "error.mail.default.error" }),
                }
              );
            }
          }
          reject("error, when receiving Data from Backend");
        })
        .catch((error) => {
          setWaitForBackendResponse(false);
          alert.show(
            intl.formatMessage({ id: "error.mail.default.instruction" }),
            { title: intl.formatMessage({ id: "error.mail.default.error" }) }
          );
          console.log(error);
          reject(error);
        });
    });
  }

  // ReCAPTCHA Stuff
  let recaptchaRef = useRef(null);
  const onSubmitWithReCAPTCHA = async () => {
    try {
      const token = await recaptchaRef.executeAsync();
      recaptchaRef.reset();
      return token;
    } catch (err) {
      console.log("Error");
      throw new Error(err);
    }
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (recaptchaRef = ref);
    }
  };

  // Message Data to be sent to Backend
  const emptyCustomerData = {
    email: "",
    firstname: "",
    lastname: "",
    zipcode: "",
    address: "",
    city: "",
  };

  const [customerData, setCustomerData] = useState(emptyCustomerData);

  function onChange(event) {
    console.log(customerData);
    const { name, value } = event.target;
    setCustomerData((prevCData) => {
      return {
        ...prevCData,
        [name]: value,
      };
    });
  }

  // Payment handling
  const [payment, setPayment] = useState(paymentType.noSelection);
  const [sum, setSum] = useState("");
  const [feedback, setFeedback] = useState("");

  // Handle Design stuff
  const deviceType = useDeviceType();

  const getDesignerWidth = () => {
    switch (deviceType) {
      case device.smartphone:
        return 350;
      case device.tablet:
        return 500;
      case device.smallDesktop:
        return 600;
      case device.normalDesktop:
        return 700;
      case device.largeDesktop:
        return 400;
      default:
        return 0;
    }
  };

  // Form validation
  const [validated, setValidated] = useState(false);
  const [waitForBackendResponse, setWaitForBackendResponse] = useState(false);
  const myForm = useRef(null);

  // Form Submission
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      onSubmitWithReCAPTCHA()
        .then((token) => {
          setWaitForBackendResponse(true);
          return sendPDF(customerData, token);
        })
        .then(() => history.push("/success"))
        .catch((e) => {
          setWaitForBackendResponse(false);
          console.log(e);
        });
    }

    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  function isNotSafari() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowser().name !== "Safari";
  }

  // Alerts
  const alert = useAlert();

  function handlePriceInput(e) {
    const re = /(^[0-9\b]{1,4}[,.]{0,1}[0-9\b]{0,2}$)/;

    const newValue = e.target.value;
    if (re.test(newValue) || newValue === "") {
      const numberString = newValue.replace(",", ".");
      checkForPaymentMethod(numberString);
      setSum(numberString);
    }
  }

  function checkForPaymentMethod(value) {
    if (value === "") return;
    if (parseFloat(value) > 0 && payment === paymentType.free) {
      setPayment(paymentType.invoice);
      return;
    }
    if (parseFloat(value) <= 0 && payment !== paymentType.free) {
      setPayment(paymentType.free);
      setSum(0);
      return;
    }
  }

  function normalizePriceInput() {
    const regex_correct = /(^[0-9\b]{1,4}[.][0-9\b]{2}$)/;
    const regex_noComma = /(^[0-9\b]{1,6}$)/;
    const regex_onlyOneAfterComma = /(^[0-9\b]{1,4}[.][0-9\b]$)/;
    const regex_nothingAfterComma = /(^[0-9\b]{1,4}[.]$)/;

    if (regex_correct.test(sum) || sum === "") return;
    if (regex_noComma.test(sum)) return setSum(sum + ".00");
    if (regex_onlyOneAfterComma.test(sum)) return setSum(sum + "0");
    if (regex_nothingAfterComma.test(sum)) return setSum(sum + "00");
  }

  // PayPal checkout
  const createOrder = (data, actions) => {
    if (
      myForm.current.checkValidity() === true &&
      waitForBackendResponse === false
    ) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: sum,
            },
          },
        ],
      });
    }
    setValidated(true);
  };

  const onApprove = (data, actions) => {
    onSubmitWithReCAPTCHA()
      .then((token) => {
        setWaitForBackendResponse(true);
        return sendPDF(customerData, token);
      })
      .then(() => actions.order.capture())
      .then(() => history.push("/success"))
      .catch((e) => {
        setWaitForBackendResponse(false);
        console.log(e);
      });
  };

  function onQuestionmark1Click() {
    alert.show("Bitte geben Sie diesen in das Textfeld ein.", {
      title: "Sie bestimmen den Preis",
    });
  }

  function onQuestionmark2Click() {
    alert.show(
      "Wir benötigen Ihre Adresse um eine gültige Rechnung für Sie erstellen zu können.",
      {
        title: "Wozu benötigt jungeTrauer meine Adresse?",
      }
    );
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={myForm}>
      <Row>
        {isNotSafari() && (
          <Col xl className="center">
            <Form.Group className="design-margin" controlId="formImageCarousel">
              <Container fluid>
                <Design
                  designId={designId}
                  width={getDesignerWidth()}
                  editable={false}
                />
              </Container>
            </Form.Group>
          </Col>
        )}
        <Col xl>
          <hr />
          <Form.Group>
            <h5 className="checkout-heading">
              Betrag{" "}
              <span className="icon" onClick={onQuestionmark1Click}>
                <FaRegQuestionCircle />
              </span>
            </h5>
            <Row>
              <Col>
                <Form.Control
                  onChange={(e) => handlePriceInput(e)}
                  onBlur={() => normalizePriceInput()}
                  value={sum}
                  type="text"
                  name="sum"
                  placeholder="Pay what you want"
                  required={payment === paymentType.free ? false : true}
                />
              </Col>
              <Col>
                <p className="euro"> €</p>
              </Col>
            </Row>
          </Form.Group>
          <hr />
          <Form.Group>
            <h5 className="checkout-heading">Zahlart</h5>
            <Form.Check
              type="radio"
              name="payment"
              checked={payment === paymentType.paypal}
              onChange={() => setPayment(paymentType.paypal)}
              id="paypal_radio"
              label="PayPal"
              disabled={sum !== "" && parseFloat(sum) < 1 ? true : false}
              required
            />
            {payment === paymentType.paypal && (
              <p className="payment-description">
                Sie werden beim Check-Out direkt zu PayPal weitergeleitet.
              </p>
            )}
            <Form.Check
              type="radio"
              name="payment"
              checked={payment === paymentType.invoice}
              onChange={() => setPayment(paymentType.invoice)}
              id="invoice"
              label="Rechnung"
              disabled={sum !== "" && parseFloat(sum) <= 0 ? true : false}
              required
            />
            {payment === paymentType.invoice && (
              <p className="payment-description">
                Die Rechnung erhalten Sie zusammen mit dem Design als PDF-Datei.
              </p>
            )}
            <Form.Check
              type="radio"
              name="payment"
              checked={payment === paymentType.free}
              onChange={() => {
                setPayment(paymentType.free);
                setSum(0);
              }}
              id="free"
              label="Ich möchte nichts bezahlen"
              required
            />
            {payment === paymentType.free && (
              <div>
                <p className="payment-description">
                  Schade... Was können wir besser machen, damit Sie für unseren
                  Service bezahlen würden?
                </p>
                <Form.Label className="feedback-description">
                  Ihr Feedback für uns
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="payment-description"
                  onChange={(e) => setFeedback(e.target.value)}
                  value={feedback}
                  type="feedback"
                  name="feedback"
                />
              </div>
            )}
          </Form.Group>
          <hr />
          <h5 className="checkout-heading">Ihre persönlichen Daten</h5>
          <Row>
            <Col md>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="createForm.order.firstname.label" />
                </Form.Label>
                <Form.Control
                  onChange={onChange}
                  value={customerData.firstname}
                  type="text"
                  name="firstname"
                  placeholder={intl.formatMessage({
                    id: "createForm.order.firstname.placeholder",
                  })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="createForm.order.lastname.label" />
                </Form.Label>
                <Form.Control
                  onChange={onChange}
                  value={customerData.lastname}
                  type="text"
                  name="lastname"
                  placeholder={intl.formatMessage({
                    id: "createForm.order.lastname.placeholder",
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          {payment !== paymentType.free && payment !== paymentType.noSelection && (
            <Form.Group>
              <Form.Group controlId="formGridAddress1">
                <Form.Label>
                  Straße, Hausnummer{" "}
                  <span className="icon grey" onClick={onQuestionmark2Click}>
                    <FaRegQuestionCircle />
                  </span>
                </Form.Label>
                <Form.Control
                  placeholder="Straße, Hausnummer"
                  onChange={onChange}
                  value={customerData.address}
                  type="address"
                  name="address"
                  required
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Postleitzahl</Form.Label>
                  <Form.Control
                    placeholder="Plz"
                    onChange={onChange}
                    value={customerData.zipcode}
                    type="zipcode"
                    name="zipcode"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Stadt/Ort</Form.Label>
                  <Form.Control
                    placeholder="Stadt/Ort"
                    onChange={onChange}
                    value={customerData.city}
                    type="city"
                    name="city"
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>
              <FormattedMessage id="createForm.order.email.label" />
            </Form.Label>
            <Form.Control
              onChange={onChange}
              value={customerData.email}
              type="email"
              name="email"
              placeholder={intl.formatMessage({
                id: "createForm.order.email.placeholder",
              })}
              required
            />
          </Form.Group>
          <Form.Group controlId="formAGBCheckbox">
            <Form.Check
              type="checkbox"
              required
              label={
                <FormattedMessage
                  id="createForm.order.agb.label"
                  values={{
                    AGBs: (
                      <a href="/AGB" target="_blank">
                        AGBs
                      </a>
                    ),
                  }}
                />
              }
            />
          </Form.Group>
          <Form.Group controlId="formDataprotectionCheckbox">
            <Form.Check
              type="checkbox"
              required
              label={
                <FormattedMessage
                  id="createForm.order.dataprotection.label"
                  values={{
                    dataprotection: (
                      <a href="/dataprotection" target="_blank">
                        Datenschutzerklärung
                      </a>
                    ),
                  }}
                />
              }
            />
          </Form.Group>
          <hr />
          <Form.Group>
            <Row>
              <Col>
                <p className="fat">Produkt:</p>
              </Col>
              <Col>
                <p>
                  Personalisiertes Trauerdesign im PDF Format
                  {isNotSafari() && " wie oben dargestellt"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="fat">Lieferung:</p>
              </Col>
              <Col>
                <p>
                  Sofort per E-Mail{" "}
                  {customerData.email === "" ? "" : `an ${customerData.email}`}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="fat">Rechnungsbetrag:</p>
              </Col>
              <Col>
                <p>{sum === "" ? "---" : `${sum} €`}</p>
              </Col>
            </Row>
          </Form.Group>

          <hr />

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_reCAPCHA_Client_Key}
            size="invisible"
            style={{ zIndex: 10 }}
            ref={(r) => setCaptchaRef(r)}
          />
          <div
            style={{
              position: "relative",
              marginLeft: "20px",
              marginTop: "50px",
            }}
          >
            {payment !== paymentType.paypal && (
              <Button
                variant="dark"
                type="submit"
                style={{
                  width: "100%",
                  height: "55px",
                  zIndex: "5",
                  position: "relative",
                }}
                disabled={waitForBackendResponse ? true : false}
              >
                {waitForBackendResponse ? (
                  <DisappearedLoading
                    color="#ffffff"
                    style={{
                      position: "relative",
                      margin: "auto",
                      width: "50px",
                      height: "5px",
                    }}
                  />
                ) : payment === paymentType.free ? (
                  "Jetzt bestellen"
                ) : (
                  "Jetzt kostenpflichtig bestellen"
                )}
              </Button>
            )}
            {payment === paymentType.paypal && (
              <PayPalButton
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                locale="de_DE"
                style={{
                  color: "black",
                }}
                disabled={true}
              />
            )}
            <WaitButterfly
              active={waitForBackendResponse ? true : false}
              style={{
                width: "50px",
                height: "50px",
                left: "-30px",
                top: "-36px",
              }}
            />
          </div>
          <p className="required-hint">
            Wir erheben nur Daten die unbedingt notwendig sind.
            <br />
            Deshalb sind alle dargestellten Felder Pflichtfelder.
          </p>
        </Col>
      </Row>
    </Form>
  );
}

export default injectIntl(OrderForm);
