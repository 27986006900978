import React from "react";
import { useParams, useHistory } from "react-router-dom";

function Redirect(props) {
  let { motif } = useParams();
  let history = useHistory();
  switch (motif) {
    case "wunschkarte-pusteblume":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/ZCJ4MGQVHG95L"
      );
      break;
    case "wunschkarte-sterntaler":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/T87ZVQYZLDX6E"
      );
      break;
    case "beileidskarte-pusteblume":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/Q5EQRT55FY8P2"
      );
      break;
    case "beileidskarte-stern":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/4KPQX949XMZ9C"
      );
      break;
    case "beileidskarte-feder":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/WN899GTEAVREW"
      );
      break;
    case "beileidskarte-set":
      window.location.replace(
        "https://www.paypal.com/instantcommerce/checkout/QDVQAATTNE6P8"
      );
      break;
    default:
      history.push({ pathname: "/" });
      break;
  }

  return <div></div>;
}

export default Redirect;
