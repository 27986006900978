import React from "react";
import Collapser from "../HelperComponents/Collapser/";
import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";
import { Pagetitle } from "../HelperComponents/Pagetitle";
import { Link } from "react-router-dom";


import "../App.css";
import "./FooterPages.css";
import "../HelperComponents/LoremIpsum";

function DataProtection() {
  return (
    <Container fluid className="content-container">
      <Pagetitle title="FAQ" description="Hier finden Sie oft gefragte Fragen auf jungeTrauer.de"/>
      <Container className="section-with-light-colored-background padded">
        <h2 className="section-heading">Häufig gefragt...</h2>
        <Collapser
          question={<FormattedMessage id={`faq.q01.q`} />}
          answer={<FormattedMessage id={`faq.q01.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q02.q`} />}
          answer={<FormattedMessage id={`faq.q02.a`} values={{ link: <Link to="/motifs"><FormattedMessage id={`faq.q02.link`} /></Link>}}/>}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q03.q`} />}
          answer={<FormattedMessage id={`faq.q03.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q04.q`} />}
          answer={<FormattedMessage id={`faq.q04.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q05.q`} />}
          answer={<FormattedMessage id={`faq.q05.a`} values={{ link: <Link to="/contact"><FormattedMessage id={`faq.q05.link`} /></Link>}}/>}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q06.q`} />}
          answer={<FormattedMessage id={`faq.q06.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q07.q`} />}
          answer={<FormattedMessage id={`faq.q07.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q08.q`} />}
          answer={<FormattedMessage id={`faq.q08.a`} />}
        />
        <Collapser
          question={<FormattedMessage id={`faq.q09.q`} />}
          answer={<FormattedMessage id={`faq.q09.a`} values={{ link: <Link to="/contact"><FormattedMessage id={`faq.q09.link`} /></Link>}}/>}
        />
      </Container>
    </Container>
  );
}

export default DataProtection;
