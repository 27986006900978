import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatOptions } from "../../../../assets/formatList";
import { frameOptions } from "../../../../assets/frames/frames";
import { dateIcons } from "../../../../assets/dateIcons";
import JTSelect from "./../JTSelect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./FormatSetting.css";

FormatSetting.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  setDesignSize: PropTypes.func,
  setFrame: PropTypes.func,
  icon: PropTypes.string,
  setIcon: PropTypes.func,
};

function FormatSetting({
  width = 1480,
  height = 1480,
  setDesignSize = () => {},
  frame = "none",
  setFrame = () => {},
  icon = "a",
  setIcon = () => {},
}) {
  const selectedFormatOption =
    formatOptions.find(
      (option) => option.value.height === height && option.value.width === width
    ) ?? formatOptions[formatOptions.length - 1];

  const [size, setSize] = useState({ width: width / 10, height: height / 10 });

  useEffect(() => {
    setSize({ height: height / 10, width: width / 10 });
  }, [width, height]);
  
  function heightChanged(e) {
    const { value } = e.target;
    setSize((lastValue) => {
      return { ...lastValue, height: value };
    });
  }

  function widthChanged(e) {
    const { value } = e.target;
    setSize((lastValue) => {
      return { ...lastValue, width: value };
    });
  }

  function correctSizeWidth(e) {
    let { width, height } = size;
    if (height / width > 1) height = width;
    if (width / height > 2) height = width / 2;

    if (width > 300) width = 300;
    if (width < 30) width = 30;
    if (height > 300) height = 300;
    if (height < 30) height = 30;

    setSize({ height: height, width: width });
    setDesignSize({
      height: Math.floor(height * 10),
      width: Math.floor(width * 10),
    });
  }

  function correctSizeHeight(e) {
    let { width, height } = size;
    if (height / width > 1) width = height;
    if (width / height > 2) width = height * 2;

    if (width > 300) width = 300;
    if (width < 30) width = 30;
    if (height > 300) height = 300;
    if (height < 30) height = 30;

    setSize({
      height: Math.floor(height * 10) / 10,
      width: Math.floor(width * 10) / 10,
    });
    setDesignSize({
      height: Math.floor(height * 10),
      width: Math.floor(width * 10),
    });
  }

  function sizeSelected(value) {
    let { width, height } = value.value;
    setSize({ height: height / 10, width: width / 10 });
    setDesignSize(value.value);
  }

  const selectedFrame =
    frameOptions.find((option) => option.value === frame) ?? frameOptions[0];

  const selectedIcon =
    dateIcons.find((option) => option.value === icon) ?? dateIcons[0];

  function frameSelected(value) {
    const frame = value.value;
    setFrame(frame);
  }

  function iconSelected(value) {
    const icon = value.value;
    setIcon(icon);
  }

  const userDefinedActive = selectedFormatOption.label === "Benutzerdefiniert";

  return (
    <div>
      <Row className="align-items-center">
        <Col xs="4" className="formatTitle" data-testid={`_format-title_`}>
          Format:
        </Col>
        <Col xs="8" data-testid={`_format-selector_column`}>
          <JTSelect
            value={selectedFormatOption}
            onChange={sizeSelected}
            defaultValue={formatOptions[0]}
            label="Single select"
            options={formatOptions}
          />
        </Col>
      </Row>
      {userDefinedActive && <div className="spaceSm" />}
      {userDefinedActive && (
        <Row className="align-items-center noMargin">
          <Col xs="4" className="formatTitle" data-testid={`_format-title_`}>
            Höhe:
          </Col>
          <Col xs="5">
            <input
              type="number"
              name="height"
              value={size.height}
              onChange={heightChanged}
              onBlur={correctSizeHeight}
              style={{ width: "100%" }}
              data-testid="height-input"
            />
          </Col>
          <Col xs="3" className="formatTitle">
            mm
          </Col>
        </Row>
      )}
      {userDefinedActive && <div className="spaceSm noMargin" />}
      {userDefinedActive && (
        <Row className="align-items-center noMargin">
          <Col xs="4" className="formatTitle" data-testid={`_format-title_`}>
            Breite:
          </Col>
          <Col xs="5" className="noMargin">
            <input
              type="number"
              name="width"
              value={size.width}
              onChange={widthChanged}
              onBlur={correctSizeWidth}
              style={{ width: "100%" }}
              data-testid="width-input"
            />
          </Col>
          <Col xs="3" className="formatTitle">
            mm
          </Col>
        </Row>
      )}
      <div className="spaceLg noMargin" />
      <Row className="align-items-center">
        <Col xs="4" className="formatTitle" data-testid={`_format-title_`}>
          Rahmen:
        </Col>
        <Col xs="8">
          <JTSelect
            value={selectedFrame}
            onChange={frameSelected}
            defaultValue={frameOptions[0]}
            label="Single select"
            options={frameOptions}
          />
        </Col>
      </Row>
      <div className="spaceLg noMargin" />
      <Row className="align-items-center">
        <Col xs="4" className="formatTitle" data-testid={`_format-title_`}>
          Symbole:
        </Col>
        <Col xs="8">
          <JTSelect
            value={selectedIcon}
            onChange={iconSelected}
            defaultValue={dateIcons[0]}
            label="Single select"
            options={dateIcons}
            icons={true}
          />
        </Col>
      </Row>
    </div>
  );
}

export default FormatSetting;
