import {
  addNewDesign,
  designSelectors,
} from "../DesignerV2/Design/designSlice";
import {
  generateTextsWithDesign,
  selectTextsByDesign,
} from "../DesignerV2/Textframe/textSlice";

export function storeDesignData(designId, store) {
  const cookieData = {
    design: designSelectors().selectById(store.getState(), designId),
    texts: selectTextsByDesign(store.getState(), designId),
  };
  const cookieName = "jTDesign_" + designId;
  storeData(cookieName, cookieData);
}

export function retrieveDesignFromStore(designId, store) {
  const cookieData = getStoredData("jTDesign_" + designId);
  if (cookieData == null) return;
  if (cookieData.design != null)
    store.dispatch(addNewDesign(cookieData.design, designId));
  if (cookieData.texts != null)
    store.dispatch(generateTextsWithDesign(cookieData.texts, designId));
}

// Code below based on https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie

export function storeData(name, value) {
  if (typeof Storage === "undefined") return;
  localStorage.setItem(name, JSON.stringify(value));
}

export function getStoredData(s_name) {
  if (typeof Storage === "undefined") return;
  const storedData = localStorage.getItem(s_name);
  if (storedData!=null && storedData?.length>0) {
    return JSON.parse(storedData);
  }
  return null;
}
