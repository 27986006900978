import React, { useEffect, useRef, useMemo } from "react";
import { storeDesignData } from "../../HelperComponents/StorageHelper";

import {
  updateSelectedText,
  createSelectDesignById,
  selectPxHeight,
  updateSelectedTextType,
} from "./../Design/designSlice";

import {
  updateText,
  textSelectors,
} from "./../Textframe/textSlice";
import { useDispatch, useSelector, useStore } from "react-redux";
import { textType } from "./../../../assets/designDataTypes";
import {
  highlight_icon,
  check_icon,
  quote_icon,
} from "./../../../assets/designerIcons/icons";

import "./Editor.css";

const Editor = ({ designId = "" }) => {
  const store = useStore();
  const selectDesignById = useMemo(createSelectDesignById, []);
  const ref = useRef(null);
  const beautifyButtonClicked = useRef(false);
  const selectionEnd = useRef(0);
  const selectionStart = useRef(0);
  const dispatch = useDispatch();
  const designHeight = useSelector((state) => selectPxHeight(state, designId));

  const design = useSelector((state) => selectDesignById(state, designId));
  const { text, id: textId } = {
    /* spread operator used to handle if Selector returns "undefined" --> In this case text and textId will be "undefined", but no Error is thrown */
    ...useSelector((state) =>
      textSelectors.selectById(state, design?.selectedTextId)
    ),
  };

  useEffect(() => {
    //Focus Textfield when Text is selected
    if (textId) ref.current.focus();
  }, [textId]);

  useEffect(() => {
    //Select the Highlight Text after Highlight Button was pressed
    if (beautifyButtonClicked.current === true) {
      ref.current.focus();
      beautifyButtonClicked.current = false;
      ref.current.selectionStart = selectionStart.current;
      ref.current.selectionEnd = selectionEnd.current;
    }
  });

  function onTextBeautyButtonPress() {
    if (typeof text === "undefined") return;

    const cursorStart = ref.current.selectionStart;
    const cursorEnd = ref.current.selectionEnd;
    beautifyButtonClicked.current = true;

    if (cursorStart === cursorEnd) return;

    const extendedCursorStart = cursorStart - 2 >= 0 ? cursorStart - 2 : 0; // extended to find Stars
    const extendedCursorEnd = cursorEnd - 2 >= 0 ? cursorEnd - 2 : 0;
    let starsAtStart = text
      .slice(extendedCursorStart, cursorStart + 2)
      .indexOf("**");
    let starsAtEnd = text.slice(extendedCursorEnd, cursorEnd + 2).indexOf("**");

    if (starsAtEnd === -1 || starsAtStart === -1) {
      // Add Stars
      let textWithStarsAtEnd = [
        text.slice(0, cursorEnd),
        "**",
        text.slice(cursorEnd),
      ].join("");
      let textWithStars = [
        textWithStarsAtEnd.slice(0, cursorStart),
        "**",
        textWithStarsAtEnd.slice(cursorStart),
      ].join("");
      setText(textWithStars);
      selectionStart.current = cursorStart + 2;
      selectionEnd.current = cursorEnd + 2;
    } else {
      //remove Stars
      let textWithoutStars = [
        text.slice(0, extendedCursorStart + starsAtStart),
        text.slice(
          extendedCursorStart + starsAtStart + 2,
          extendedCursorEnd + starsAtEnd
        ),
        text.slice(extendedCursorEnd + starsAtEnd + 2),
      ].join("");
      setText(textWithoutStars);
      selectionStart.current = cursorStart - 2;
      selectionEnd.current = cursorEnd - 2;
    }
  }

  function checkAndDeleteAuthor(){
    const lines = text.split("\n");
    const lastLine = lines.length-1;
    if(lines[lastLine].substring(0,2)==="//"){
      const textLength = text.length;
      const authorLength = lines[lastLine].length + 1;
      const textWithoutAuthor = text.substring(0,textLength-authorLength);
      setText(textWithoutAuthor);
      return true;
    }
    return false;
  }

  function onQuoteButtonPressed(){
    if (typeof text === "undefined") return;
    if (checkAndDeleteAuthor()) return;
    // Create new Text
    const textWithAuthor = `${text}\n//- Autor:in`;
    // Prepare Selection of newly added Text
    beautifyButtonClicked.current = true;
    const cursorEnd = textWithAuthor.length;
    const cursorStart = textWithAuthor.length-8;
    selectionStart.current = cursorStart;
    selectionEnd.current = cursorEnd;
    
    setText(textWithAuthor);
  }

  function handleOnChange(event) {
    const { value } = event.target;
    if (typeof value !== "undefined") {
      dispatch(updateText(textId, value));
    }
  }

  function setText(text) {
    if (typeof text !== "undefined") {
      dispatch(updateText(textId, text));
    }
  }

  function onAcceptClick() {
    storeDesignData(designId, store);
    dispatch(updateSelectedText(designId, ""));
    dispatch(updateSelectedTextType(designId, ""));
  }

  function displayBeautifyButton() {
    const selectedTextType = design?.selectedTextType;
    return (
      selectedTextType === textType.poem ||
      selectedTextType === textType.relatives ||
      selectedTextType === textType.info
    );
  }

  function displayQuoteButton() {
    const selectedTextType = design?.selectedTextType;
    return selectedTextType === textType.poem;
  }

  return (
    <div style={{ height: `${designHeight}px` }} data-testid="_editorFrame_">
      <div className="settingHeader">
        {displayBeautifyButton() && (
          <svg
            width="30px"
            height="30px"
            viewBox={highlight_icon.viewbox}
            className="standardIcon"
            onClick={onTextBeautyButtonPress}
            data-testid="editor-highlightTextButton"
          >
            <title>Markierten Text in Highlight Schriftart</title>
            <path d={highlight_icon.path} />
          </svg>
        )}
        {displayQuoteButton() && (
          <svg
            width="30px"
            height="30px"
            viewBox={quote_icon.viewbox}
            className="standardIcon"
            onClick={onQuoteButtonPressed}
            data-testid="editor-quoteButton"
          >
            <title>Zitat-Autor:in hinzufügen</title>
            <path d={quote_icon.path} />
          </svg>
        )}
        <svg
          width="25px"
          height="25px"
          viewBox={check_icon.viewbox}
          className="okIcon"
          data-testid="editor-okButton"
          onClick={onAcceptClick}
        >
          <title>Änderungen anwenden</title>
          <path d={check_icon.path} />
        </svg>
      </div>
      <textarea
        ref={ref}
        data-testid="editor-textarea"
        onChange={handleOnChange}
        value={text ?? "Nichts ausgewählt"}
        className="textEditor"
        style={{
          height: `${designHeight - 80}px`,
        }}
      />
      <div
        className="settingFooter"
        onClick={onAcceptClick}
        title="Änderungen anwenden"
        data-testid="editor-acceptButton"
      >
        <p className="okTextButton">Anwenden</p>
      </div>
    </div>
  );
};

export default Editor;
