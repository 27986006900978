import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Container from "react-bootstrap/Container";
import Logo from "../Logo/";

import "./Footer.css";
import "../App.css";

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
    }}
  />
);

function Footer() {

  const getYear = () => {
    const year = new Date().getFullYear();
    return year;
  };
  return (
    <Container
      fluid
      className="content-container section-with-dark-background footer"
    >
      <Row>
        <Col lg className="vertically-centered">
          <Row className="vertically-distanced">
            <Col>
              <Link to="/dataprotection" className="light-link">
                <small>
                  <FormattedMessage id="footer.dataprotection" />
                </small>
              </Link>
            </Col>
            <Col>
              <Link to="/AGB" className="light-link">
                <small>
                  <FormattedMessage id="footer.agb" />
                </small>
              </Link>
            </Col>
            <Col>
              <Link to="/imprint" className="light-link">
                <small>
                  <FormattedMessage id="footer.imprint" />
                </small>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col lg>
        <Link to="/" className="light-link">
            <Logo size="20" />
          </Link>
        </Col>
        <Col lg className="vertically-centered">
          <Row className="vertically-distanced">
            <Col>
              <Link to="/FAQ" className="light-link">
                <small>
                  <FormattedMessage id="footer.faq" />{" "}
                </small>
              </Link>
            </Col>
            <Col>
              <Link to="/contact" className="light-link">
                <small>
                  <FormattedMessage id="footer.contact" />
                </small>
              </Link>
            </Col>
            <Col>
              <small className="light-link">
                  ♥
              </small>
            </Col>
          </Row>
        </Col>
      </Row>
      <ColoredLine color="#7f7f7f" />
      <h3 className="vertically-distanced">
        <a href="https://www.instagram.com/jungetrauer/" className="light-link">
          <FaInstagram />
        </a>{" "}
        <a
          href="https://www.facebook.com/JungeTrauer-100541768444186"
          className="light-link"
        >
          <FaFacebookSquare />
        </a>{" "}
        <a
          href="https://www.linkedin.com/company/jungetrauer/"
          className="light-link"
        >
          <FaLinkedin />
        </a>
      </h3>
      <p className="copyright-text">
        mit ♥ gestaltet von
        <br />© jungeTrauer {getYear()}
      </p>
    </Container>
  );
}

export default Footer;
