import React from "react";
import { FormattedMessage } from "react-intl";

import Container from "react-bootstrap/Container";
import ImageCarousel from "./ImageCarousel";
import { motifs } from "../../../assets/staticImages";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import "../Home.css";
import "../../App.css";
import "./motifs.css";

function Motifs() {
  return (
    <Container
      fluid
      className="content-container section-with-light-colored-background"
      id="motifs"
    >
      <Container>
        <h2 className="section-heading">
          <FormattedMessage id="home.motifs.title" />
        </h2>
        <p>
          <FormattedMessage id="home.motifs.text1" values={{ br: <br /> }} />
        </p>
        <Container fluid className="center carousel-margin">
          <ImageCarousel images={motifs} interval={6000} />
        </Container>
      </Container>
      <Container className="center">
        <Button
          variant="outline-dark"
          className="floating-button"
          as={Link}
          to="/motifs"
        >
          <FormattedMessage id="home.motifs.text2" />
        </Button>
      </Container>
      
    </Container>
  );
}

export default Motifs;
