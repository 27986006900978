import React, { useEffect } from "react";

import { Events, animateScroll as scroll } from "react-scroll";

import Container from "react-bootstrap/Container";
import Intro from "./Intro/";
import Motifs from "./Motifs/";
import Partner from "./Partner";
import AboutUs from "./AboutUs/";
import Packages from "./Packages/";
import Service from "./Service/";
import { Pagetitle } from "../HelperComponents/Pagetitle";

import "./Home.css";
import "../App.css";

function Home(props) {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
    });

    Events.scrollEvent.register("end", function () {
    });
    scroll.scrollToTop();
  });

  return (
    <Container fluid>
      <Pagetitle description="Wir erstellen Grafiken für einen würdevollen und altersgemäßen Abschied von jungen Verstorbenen. Ob Trauerkarte, Zeitungsanzeige, Sterbebildchen oder Danksagungskarten – unsere Designs können Sie für alle Drucksachen nutzen." />
      <Intro />
      <Motifs />
      <Service />
      <Packages />
      <AboutUs />
      <Partner />
    </Container>
  );
}
//<div className="whatsappLink">W</div>
export default Home;
