import React, { useState, useEffect, useMemo } from "react";
import useScreenSize from "./useScreenSize";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { retrieveDesignFromStore } from "../HelperComponents/StorageHelper";

import Bowser from "bowser";

import WarningFrame from "./WarningFrame";
import Settings from "./Settings/";
import Design from "./Design/";
import Editor from "./Editor/";
import { HeartBoomLoading } from "react-loadingg";

import { storeDesignData } from "../HelperComponents/StorageHelper";

import { useSelector, useDispatch, useStore } from "react-redux";
import {
  resetPrinter,
  selectDesignBoundings,
  selectPaths,
  selectTexts,
} from "./printerSlice";
import {
  pathSelectors,
  generatePathsWithDesign,
  deletePathsByDesignId,
} from "./Pathdrawer/pathSlice";
import {
  textSelectors,
  generateTextsWithDesign,
  deleteTextsByDesignId,
} from "./Textframe/textSlice";
import {
  addNewDesign,
  deleteDesignById,
  updateSelectedText,
  selectSelectedText,
  selectSpaceAlert,
  updateDesignGenerator,
  selectDesignGenerator,
  createSelectDesignById,
  checkDesignAvailable,
} from "./Design/designSlice";
import {
  generateNewLayoutWithDesign,
  deleteLayoutsByDesignId,
} from "./Layout/layoutSlice";
import {
  generateStaticAreasWithDesign,
  deleteStaticAreasByDesignId,
} from "./StaticArea/staticAreaSlice";
import {
  generateTextAreasWithDesign,
  deleteTextAreasByDesignId,
} from "./TextArea/textAreaSlice";

import "../App.css";
import "./designer.css";
import { useCallback } from "react";

const ERROR_MESSAGE_500 =
  "Es gab Probleme bei der Kommunikation mit unseren Servern. Versuchen Sie es bitte erneut.";
const ERROR_MESSAGE_404 =
  "Bitte versuchen Sie es erneut oder wenden sich an support@jungetrauer.de";

function Designer({ designId = "" }) {
  const store = useStore();
  const dispatch = useDispatch();
  //const [devMode, setDevmode] = useState(false);
  const screenSize = useScreenSize();
  const selectedText = useSelector((state) =>
    selectSelectedText(state, designId)
  );

  const spaceAlert = useSelector((state) => selectSpaceAlert(state, designId));
  const alert = useAlert();

  // Stuff for getting the Design from Backend
  const designInRedux = useSelector((state) =>
    checkDesignAvailable(state, designId)
  );

  const [designLoaded, setDesignLoaded] = useState(false);

  const loadDataInRedux = useCallback(
    (designData) => {
      dispatch(deleteDesignById(designData.id));
      dispatch(deleteLayoutsByDesignId(designData.id));
      dispatch(deleteTextAreasByDesignId(designData));
      dispatch(deleteStaticAreasByDesignId(designData.id));
      dispatch(deletePathsByDesignId(designData.id));
      dispatch(deleteTextsByDesignId(designData.id));

      dispatch(generateTextsWithDesign(designData.texts, designData.id));
      dispatch(generatePathsWithDesign(designData.paths, designData.id));
      dispatch(
        generateStaticAreasWithDesign(designData.staticAreas, designData.id)
      );
      dispatch(
        generateTextAreasWithDesign(designData.textAreas, designData.id)
      );
      dispatch(generateNewLayoutWithDesign(designData.layouts, designData.id));
      dispatch(addNewDesign(designData.designInfo, designData.id));
      retrieveDesignFromStore(designData.id, store);
      dispatch(updateSelectedText(designId, ""));
      document.fonts.ready.then(() => setDesignLoaded(true));
    },
    [designId, dispatch, store]
  );

  const fetchFullDesign = useCallback(() => {
    fetch("/api/designs/" + designId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setTimeout(() => loadDataInRedux(data), 1000);
          });
        } else {
          if (res.status === 404) {
            alert.show(ERROR_MESSAGE_404, {
              title: "Das angeforderte Design existiert nicht.",
            });
          } else {
            alert.show(ERROR_MESSAGE_500, {
              title: "Fehler",
            });
          }
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        alert.show(ERROR_MESSAGE_500, {
          title: "Fehler",
        });
        console.log(error);
      });
  }, [alert, designId, loadDataInRedux]);

  function fetchAndResetDesignInfo() {
    fetch("/api/designs/" + designId + "/design-info", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            dispatch(addNewDesign(data.designInfo, designId));
            storeDesignData(designId, store);
          });
        } else {
          alert.show(ERROR_MESSAGE_500, {
            title: "Fehler",
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        alert.show(ERROR_MESSAGE_500, {
          title: "Fehler",
        });
        console.log(error);
      });
  }

  useEffect(() => {
    if (!designInRedux) {
      fetchFullDesign();
    } else {
      if (document.fonts == null) setDesignLoaded(true);
      // JEST does not support document.fonts --> ignore it for tests
      else document.fonts.ready.then(() => setDesignLoaded(true));
    }
  }, [designInRedux, fetchFullDesign]);

  // Stuff for Data Export

  const history = useHistory(); // --> History needed for forwarding to order page
  const selectDesignById = useMemo(createSelectDesignById, []);
  const design = useSelector((state) => selectDesignById(state, designId));
  const designGenerator = useSelector((state) =>
    selectDesignGenerator(state, designId)
  );
  const texts_export = useSelector((state) => selectTexts(state));
  const paths_export = useSelector((state) => selectPaths(state));
  const designBoundings_export = useSelector((state) =>
    selectDesignBoundings(state)
  );

  const mm = useCallback(
    (px) => {
      const mmPerPx = (design?.width * 0.1) / design?.pxWidth;
      return px * mmPerPx;
    },
    [design?.pxWidth, design?.width]
  );

  const mm_text = useCallback(
    (fontSize) => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      if (browser.getBrowser().name !== "Safari")
        return (fontSize / 1000) * design?.height * 0.1;
      // Safari Workaround (Because Safari does not support non-integer font sizes)
      const pxFontSize = (fontSize / 1000) * design.pxHeight;
      return mm(Math.round(pxFontSize));
    },
    [design?.height, design?.pxHeight, mm]
  );

  const retrievePathsFromStore = useCallback(
    (mm) => {
      return paths_export.map((path_pre) => {
        let pathData = {};
        if (path_pre.frame !== true) {
          pathData = pathSelectors.selectById(
            store.getState(),
            path_pre.pathId
          );
          if (pathData == null) return null;
        } else {
          pathData = { path: path_pre.path, viewBox: path_pre.viewBox };
        }

        const { path, viewBox } = pathData;
        let posX = path_pre.posX;
        let posY = path_pre.posY;

        if (path_pre.frame !== true) {
          posX = posX - designBoundings_export.x;
          posY = posY - designBoundings_export.y;
        }
        const pathItemForBackend = {
          id: path_pre.pathId,
          posX: mm(posX),
          posY: mm(posY),
          width: mm(path_pre.width),
          height: mm(path_pre.height),
          path: path,
          rotation: path_pre.rotation,
          viewbox: viewBox,
          preserveAspectRatio: path_pre.frame ? "none" : "xMidYMid meet",
        };

        return pathItemForBackend;
      });
    },
    [designBoundings_export?.x, designBoundings_export?.y, paths_export, store]
  );

  const retrieveTextsFromStore = useCallback(
    (mm) => {
      return texts_export.map((text_pre) => {
        const textData = textSelectors.selectById(
          store.getState(),
          text_pre.textId
        );

        if (textData == null) return null;

        const { text } = textData;
        const posX = text_pre.posX - designBoundings_export.x;
        const posY = text_pre.posY - designBoundings_export.y;
        const textItemForBackend = {
          id: text_pre.textId,
          posX: mm(posX),
          posY: mm(posY),
          width: mm(text_pre.width) + 0.1,
          height: mm(text_pre.height) + 0.1,
          text: text,
          type: text_pre.type,
          horizontalAlign: text_pre.horizontalAlign,
        };

        return textItemForBackend;
      });
    },
    [designBoundings_export?.x, designBoundings_export?.y, store, texts_export]
  );

  useEffect(() => {
    if (designGenerator === 1) {
      dispatch(updateDesignGenerator(designId, 2));
    }
    if (designGenerator === 2) {
      const paths_forBackend = retrievePathsFromStore(mm).filter((item) => item!==null);
      const texts_forBackend = retrieveTextsFromStore(mm).filter((item) => item!==null);
      const design_forBackend = {
        id: design.id,
        width: design.width * 0.1,
        height: design.height * 0.1,
        color: design.color,
        textColor: design.textColor,
        pathColor: design.pathColor,
        dateIcon: design.dateIcon,
        standardFontFamily: design.standardFontFamily,
        standardFontSize: mm_text(design.standardFontSize),
        dateFontFamily: design.dateFontFamily,
        dateFontSize: mm_text(design.dateFontSize),
        nameFontFamily: design.nameFontFamily,
        nameFontSize: mm_text(design.nameFontSize),
        highlightFontFamily: design.highlightFontFamily,
        highlightFontSize: mm_text(design.highlightFontSize),
      };

      const cardForBackend = {
        ...design_forBackend,
        paths: paths_forBackend,
        texts: texts_forBackend,
      };

      console.log(paths_forBackend);

      dispatch(updateDesignGenerator(designId, 0));
      history.push({ pathname: "/order/" + designId, state: cardForBackend });
    }
  }, [
    designGenerator,
    design?.color,
    design?.dateFontFamily,
    design?.dateFontSize,
    design?.dateIcon,
    design?.height,
    design?.highlightFontFamily,
    design?.highlightFontSize,
    design?.id,
    design?.nameFontFamily,
    design?.nameFontSize,
    design?.pathColor,
    design?.standardFontFamily,
    design?.standardFontSize,
    design?.textColor,
    design?.width,
    designId,
    dispatch,
    history,
    mm,
    mm_text,
    retrievePathsFromStore,
    retrieveTextsFromStore,
  ]);

  function generatePdf() {
    dispatch(resetPrinter());
    dispatch(updateDesignGenerator(designId, 1));
  }

  // -----

  const selectDesignWidth = () => {
    if (screenSize >= 850) return screenSize - 400;
    return screenSize - 100;
  };

  const selectSettingWidth = () => {
    if (screenSize >= 850) return 300;
    return screenSize - 100;
  };

  const settingColStyle = {
    width: `${selectSettingWidth()}px`,
  };

  const designColStyle = {
    width: `${selectDesignWidth()}px`,
  };

  return (
    <div className="outerFrame">
      {(!designInRedux || !designLoaded) && (
        <div className="loadingCover">
          <div className="loadingIndication">
            <HeartBoomLoading
              color="#dddddd"
              style={{
                position: "relative",
                margin: "auto",
              }}
            />
            <p>Designer wird geladen</p>
          </div>
        </div>
      )}
      <div className="designerFrame" data-testid="_designerFrame_">
        <div
          className="designCol"
          style={designColStyle}
          data-testid="_designColumn_"
        >
          {spaceAlert && <WarningFrame width={selectDesignWidth()} />}
          <Design
            designId={designId}
            width={selectDesignWidth()}
            devMode={false}
            designLoaded={designLoaded}
          />
        </div>
        <div
          className="settingCol"
          style={settingColStyle}
          data-testid="_settingColumn_"
        >
          {selectedText !== "" && selectedText != null && (
            <Editor designId={designId} />
          )}
          {(selectedText == null || selectedText === "") && (
            <Settings
              designId={designId}
              devMode={false}
              generatePdf={generatePdf}
              fetchAndResetDesignInfo={fetchAndResetDesignInfo}
            />
          )}
        </div>
      </div>
    </div>
  );

  /* For Dev Mode Activation, add anywhere in Designer:         
        <FormCheck
          type="checkbox"
          label="Devmode"
          onChange={(e) => setDevmode(!devMode)}
          value={devMode}
        />
  */
}

export default Designer;
