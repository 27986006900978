import React from "react";
import Container from "react-bootstrap/Container";
import { Pagetitle } from "../HelperComponents/Pagetitle";
import { useLocation } from "react-router-dom";


import ContactForm from "./ContactForm";

import "./contact.css";

function ContactPage() {
  const { state } = useLocation();
  return (
    <Container fluid>
      <Pagetitle
        title="Kontaktaufnahme"
        description="Nehmen Sie Kontakt zu jungeTrauer auf"
      />
      <Container
        fluid
        className="content-container section-with-colored-background"
      >
        <Container>
          <h2 className="section-heading">Konktakt aufnehmen</h2>
          <h3 className="content-heading">
            Wir freuen uns auf den direkten Austausch mit Ihnen.
          </h3>
          <p>
            Bitte füllen Sie das Kontaktformular aus und wir melden uns
            schnellstmöglich innerhalb eines Tages bei Ihnen.
            <br /><br />
            Wir freuen uns auf Ihre Nachricht!
          </p>
        </Container>
      </Container>

      <Container fluid className="content-container">
        <Container>
          <ContactForm state={state} />
        </Container>
      </Container>
    </Container>
  );
}

export default ContactPage;
