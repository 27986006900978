import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, NavLink } from "react-router-dom";
import { Link as ScrollLink, Events } from "react-scroll";
import useDeviceType, { device } from "../HelperComponents/useDeviceType";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Logo from "../Logo/";

import "./NavigationBar.css";

const offset_desktop = -70;
const offset_touch = -300;

function NavigationBar() {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
  });

  const deviceType = useDeviceType();

  const [expanded, setExpanded] = useState(false);

  let location = useLocation();
  return (
    <Navbar
      variant="dark"
      expand="lg"
      sticky={location.pathname !== "/designer" ? "top" : ""}
      expanded={expanded}
    >
      <Navbar.Brand as={NavLink} to="/" exact>
        <Logo size="20" />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              onClick={() => setExpanded(false)}
              to="/"
              exact
            >
              <FormattedMessage id="navigationbar.home" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{ display: location.pathname === "/" ? "block" : "none" }}
          >
            <Nav.Link
              as={ScrollLink}
              activeClass="active"
              to="motifs"
              spy={true}
              smooth={true}
              offset={
                deviceType < device.normalDesktop
                  ? offset_touch
                  : offset_desktop
              }
              duration={500}
              onClick={() => setExpanded(false)}
            >
              <small>
                <FormattedMessage id="navigationbar.motifs" />
              </small>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{ display: location.pathname === "/" ? "block" : "none" }}
          >
            <Nav.Link
              as={ScrollLink}
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              offset={
                deviceType < device.normalDesktop
                  ? offset_touch
                  : offset_desktop
              }
              duration={500}
              onClick={() => setExpanded(false)}
            >
              <small>Leistungen</small>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{ display: location.pathname === "/" ? "block" : "none" }}
          >
            <Nav.Link
              as={ScrollLink}
              activeClass="active"
              to="packages"
              spy={true}
              smooth={true}
              offset={
                deviceType < device.normalDesktop
                  ? offset_touch
                  : offset_desktop
              }
              duration={500}
              onClick={() => setExpanded(false)}
            >
              <small>
                <FormattedMessage id="navigationbar.packages" />
              </small>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{ display: location.pathname === "/" ? "block" : "none" }}
          >
            <Nav.Link
              as={ScrollLink}
              activeClass="active"
              to="aboutus"
              spy={true}
              smooth={true}
              offset={
                deviceType < device.normalDesktop
                  ? offset_touch
                  : offset_desktop
              }
              duration={500}
              onClick={() => setExpanded(false)}
            >
              <small>
                <FormattedMessage id="navigationbar.aboutus" />
              </small>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/motifs"
              onClick={() => setExpanded(false)}
            >
              <FormattedMessage id="navigationbar.create" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
