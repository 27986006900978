import { useState, useEffect } from "react";

function useScreenSize() {
  let [screenSize, setScreenWidth] = useState(1000);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return function cleanup() {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  function updateWindowDimensions() {
    let screenWidth = window.innerWidth;
    if (screenWidth > 1200) {
      setScreenWidth(1200);
    } else if (screenWidth >= 1150) {
      setScreenWidth(1150);
    } else if (screenWidth >= 1100) {
      setScreenWidth(1100);
    } else if (screenWidth >= 1000) {
      setScreenWidth(1000);
    } else if (screenWidth >= 950) {
      setScreenWidth(950);
    } else if (screenWidth >= 900) {
      setScreenWidth(900);
    } else if (screenWidth >= 850) {
      setScreenWidth(850);
    } else if (screenWidth >= 800) {
      setScreenWidth(800);
    } else if (screenWidth >= 750) {
      setScreenWidth(750);
    } else if (screenWidth >= 700) {
      setScreenWidth(700);
    } else if (screenWidth >= 650) {
      setScreenWidth(650);
    } else if (screenWidth >= 600) {
      setScreenWidth(600);
    } else if (screenWidth >= 550) {
      setScreenWidth(550);
    } else if (screenWidth >= 500) {
      setScreenWidth(500);
    } else if (screenWidth >= 450) {
      setScreenWidth(450);
    } else if (screenWidth >= 400) {
      setScreenWidth(400);
    } else {
      setScreenWidth(350);
    }
  }

  return screenSize;
}

export default useScreenSize;
