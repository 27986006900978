import React from "react";
import Select from "react-select";
import { fontOptions, fontOptions_Text } from "../../../../assets/fontList";
const height = 20;
const fontStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: height,
    minHeight: height,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: height,
    padding: "0 3px",
    fontSize: 12,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: height,
    padding: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const font = data.value;
    return {
      ...styles,
      fontFamily: font,
      fontSize: 12,
    };
  },

  singleValue: (styles, { data }) => ({ ...styles, fontFamily: data.value }),
};

function FontSelector({ value, onChange, withoutHighlight }) {
  const defaultValue = withoutHighlight ? fontOptions_Text[0] : fontOptions[0];
  const options = withoutHighlight ? fontOptions_Text : fontOptions;
  return (
    <Select
      value={
        value
          ? options.filter((option) => option.value === value)
          : options[1]
      }
      onChange={(value) => onChange(value)}
      defaultValue={defaultValue}
      isSearchable={false}
      label="Single select"
      options={options}
      styles={fontStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#eeeeff",
          primary50: "#ddddee",
          primary: "#343a40",
        },
      })}
    />
  );
}

export default FontSelector;
