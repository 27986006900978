import React from "react";

import { LogoSamen as Samen } from "./LogoSamen.js";

import "./Logo.css";

/*
Component props: 
  size: if you change this value the logo gets smaller or bigger
  noLogo: if you set this you will only get the Logo text

Example:
  <Logo size="20" noLogo />
*/

function Logo(props) {
  const inlineFatTextSize = {
    fontSize: `${props.size}px`,
    position: "relative",
    top: props.twoLines ? `${props.size}px` : "0px",
    left: props.twoLines ? `-${props.size * 3.4}px` : "0px",
  };

  const inlineText = {
    fontSize: `${props.size}px`,
    left: `${props.size * 1.6}px`,
  };

  return (
    <div className={`jt-logo ${props.className}`}>
      {!props.noLogo && (
        <Samen height={props.size * 3} width={props.size * 3} />
      )}
      <span
        className={`text ${!props.noLogo && "with-image"}`}
        style={inlineText}
      >
        junge
        <span className="fat" style={inlineFatTextSize}>
          Trauer
        </span>
      </span>
    </div>
  );
}

export default Logo;
