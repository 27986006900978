import { useState, useEffect } from "react";

function useDeviceType() {
  let [deviceType, setDeviceType] = useState(device.desktop);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return function cleanup() {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  function updateWindowDimensions() {
    let screenWidth = window.innerWidth;
    if (screenWidth > 1200) {
      setDeviceType(device.largeDesktop);
    } else if (screenWidth > 992) {
      setDeviceType(device.normalDesktop);
    } else if (screenWidth > 892) {
      setDeviceType(device.smallDesktop);
    } else if (screenWidth > 576) {
      setDeviceType(device.tablet);
    } else {
      setDeviceType(device.smartphone);
    }
  }

  return deviceType;
}

export const device = {
  smartphone: 1,
  tablet: 2,
  smallDesktop: 3,
  normalDesktop: 4,
  largeDesktop: 5,
};

export default useDeviceType;
