export const fontOptions = [
  { value: "a Auto Signature", label: "a Auto Signature" },
  { value: "metafors", label: "metafors" },
  { value: "Poppins", label: "Poppins" },
  { value: "Sacramento", label: "Sacramento" },
  { value: "Source Sans Pro", label: "Source Sans Pro" },
  { value: "Source Sans Pro Light", label: "Source Sans Pro Light" },
  { value: "Source Serif Pro", label: "Source Serif Pro" },
  { value: "Source Serif Pro Light", label: "Source Serif Pro Light" },
  { value: "Urbanist", label: "Urbanist" },
  { value: "Wremena", label: "Wremena" },
];

export const fontOptions_Text = [
  { value: "metafors", label: "metafors" },
  { value: "Poppins", label: "Poppins" },
  { value: "Source Sans Pro", label: "Source Sans Pro" },
  { value: "Source Sans Pro Light", label: "Source Sans Pro Light" },
  { value: "Source Serif Pro", label: "Source Serif Pro" },
  { value: "Source Serif Pro Light", label: "Source Serif Pro Light" },
  { value: "Urbanist", label: "Urbanist" },
  { value: "Wremena", label: "Wremena" },
];


