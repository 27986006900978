import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// REDUCER

const staticAreaAdapter = createEntityAdapter();

const initialState = staticAreaAdapter.getInitialState();

const staticAreaSlice = createSlice({
  name: "staticareas",
  initialState,
  reducers: {
    deleteStaticAreasByDesignId(state, action) {
      const designId = action.payload;
      const idsToDelete = state.ids.filter(
        (id) => state.entities[id].design === designId
      );
      staticAreaAdapter.removeMany(state, idsToDelete);
    },
    generateStaticAreasWithDesign: {
      reducer(state, action) {
        staticAreaAdapter.addMany(state, action.payload);
      },
      prepare(staticAreas, designId) {
        const staticAreasWithDesignId = staticAreas.map((staticArea) => {
          return { ...staticArea, design: designId };
        });
        return {
          payload: staticAreasWithDesignId,
        };
      },
    },
  },
});

export const {
  generateStaticAreasWithDesign,
  deleteStaticAreasByDesignId,
} = staticAreaSlice.actions;

export const staticAreaSelectors = staticAreaAdapter.getSelectors(
  (state) => state.staticArea
);

export const createSelectStaticAreaPaths = () => createSelector(
  (state, staticAreaId) => {
    return state.staticArea.entities[staticAreaId]?.paths;
  },
  (paths) => {
    return paths;
  }
);


export const createSelectStaticAreaTexts = () => createSelector(
  (state, staticAreaId) => {
    return state.staticArea.entities[staticAreaId]?.texts;
  },
  (texts) => {
    return texts;
  }
);


export const selectStaticAreaDesignId = (state, staticAreaId) => {
  return state.staticArea.entities[staticAreaId]?.design;
}

export default staticAreaSlice.reducer;
