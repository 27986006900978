import React from "react";

import Container from "react-bootstrap/Container";

import PartnerItem from "./PartnerItem";

import "../Home.css";
import "../../App.css";

const partner = [
  {
    name: "Bohana",
    url: "https://bohana.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Bohana.png",
  },
  {
    name: "Lavia",
    url: "https://www.lavia.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Lavia.png",
  },
  {
    name: "Emmora",
    url: "https://emmora.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Emmora.png",
  },
  {
    name: "Trotzdem Dasein",
    url: "https://trotzdem-dasein.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/TrozdemDasein.png",
  },
  {
    name: "Mechthild Schroeter-Rupieper",
    url: "https://www.familientrauerbegleitung.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Mechthild_Schroeter-Rupieper.png",
  },
  {
    name: "meny",
    url: "https://meny-bestattungshaus.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Meny.png",
  },
  {
    name: "hyggested.de",
    url: "https://hyggested.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/hyggested.png",
  },
  {
    name: "Regenbogenland",
    url: "https://kinderhospiz-regenbogenland.de",
    imageUrl:
    "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Regenbogenland_Hospiz.png",
  },
  {
    name: "Trosthelden",
    url: "https://www.trosthelden.de",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Trosthelden.png",
  },
  {
    name: "Uniklinik Dresden",
    url: "https://www.uniklinikum-dresden.de/de/das-klinikum/kliniken-polikliniken-institute/kik",
    imageUrl:
      "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/partner/Uniklinik_Dresden.png",
  },
];

function Partner() {
  return (
    <Container fluid className="content-container" id="partner">
      <Container>
        <h2 className="section-heading">Unsere Partner</h2>
        <div className="partner-area">
          {partner.map((item) => {
            return (
              <PartnerItem
                key={item.name}
                imageUrl={item.imageUrl}
                link={item.url}
                name={item.name}
              />
            );
          })}
        </div>
      </Container>
    </Container>
  );
}

export default Partner;
