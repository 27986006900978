import React, { useState } from "react";
import { SketchPicker } from "react-color";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ColorSetting({
  onChange = () => {},
  color = "#000000",
  title = "Farbe",
}) {
  const [displayPicker, setDisplayPicker] = useState(false);

  const presetColors = [
    "#000000",
    "#3B3B3B",
    "#5B5EA6",
    "#8D9440",
    "#9A8B4F",
    "#926AA6",
    "#798EA4",
    "#A47990",
    "#55B4B0",
    "#E9897E",
    "#EFC050",
    "#98B4D4",
    "#D4CE9D",
    "#DBB1CD",
    "#CDCDCD",
    "#FFFFFF",
  ];

  const styles = {
    color: {
      width: "20px",
      height: "20px",
      borderRadius: "10px",
      borderStyle: "solid",
      borderColor: "#aaaaaa",
      borderWidth: "1px",
      background: color,
    },
    swatch: {
      padding: "5px",
      borderRadius: "13px",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
      left: "-180px",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
    fontTitle: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "small",
    },
  };

  const handleClick = () => {
    setDisplayPicker(!displayPicker);
  };

  const handleClose = () => {
    setDisplayPicker(false);
  };

  return (
    <Row className="align-items-center" data-testid={`_colorSetting_${title}`}>
      <Col xs="9" style={styles.fontTitle} data-testid={`_colorSetting_title`}>
        {title}
      </Col>
      <Col
        xs="3"
        className="align-items-center"
        style={{ height: "30px" }}
        data-testid={`_colorSetting_colorSelectColumn`}
      >
        <div
          style={styles.swatch}
          onClick={handleClick}
          data-testid={`_colorSetting_colorSelectButton`}
        >
          <div
            style={styles.color}
            data-testid={`_colorSetting_colorSelectColor`}
          />
        </div>
        {displayPicker ? (
          <div style={styles.popover} data-testid={`_colorSetting_popover`}>
            <div
              style={styles.cover}
              onClick={handleClose}
              data-testid={`_colorSetting_closeCover`}
            />
            <SketchPicker
              color={color}
              onChange={onChange}
              disableAlpha
              presetColors={presetColors}
            />
          </div>
        ) : null}
      </Col>
    </Row>
  );
}

export default ColorSetting;
