import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// REDUCER

const layoutAdapter = createEntityAdapter();

const initialState = layoutAdapter.getInitialState();

const layoutSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    updateAddHeight: {
      reducer(state, action) {
        const sections = state.entities[action.payload.layoutId]?.sections;
        if (!sections) return;
        const section = sections.find(
          (section) => section.id === action.payload.sectionId
        );
        section.addHeight = action.payload.addHeight;
      },
      prepare(layoutId, sectionId, addHeight) {
        return {
          payload: {
            layoutId: layoutId,
            sectionId: sectionId,
            addHeight: addHeight,
          },
        };
      },
    },
    resetAddHeight: {
      reducer(state, action) {
        const designId = action.payload;
        const layoutIdsToReset = state.ids.filter(
          (id) => state.entities[id].design === designId
        );
        layoutIdsToReset.forEach((id) => {
          state.entities[id].sections.forEach((section) => {
            delete section["addHeight"];
          });
        });
      },
      prepare(designId) {
        return { payload: designId };
      },
    },
    deleteLayoutsByDesignId(state, action) {
      const designId = action.payload;
      const idsToDelete = state.ids.filter(
        (id) => state.entities[id].design === designId
      );
      layoutAdapter.removeMany(state, idsToDelete);
    },
    generateNewLayoutWithDesign: {
      reducer(state, action) {
        layoutAdapter.addMany(state, action.payload);
      },
      prepare(layouts, designId) {
        const layoutWithDesignId = layouts.map((layout) => {
          return { ...layout, design: designId };
        });
        return {
          payload: layoutWithDesignId,
        };
      },
    },
  },
});

export const {
  generateNewLayoutWithDesign,
  deleteLayoutsByDesignId,
  updateAddHeight,
  resetAddHeight,
} = layoutSlice.actions;

export const layoutSelectors = layoutAdapter.getSelectors(
  (state) => state.layout
);

export const selectRootLayoutByDesign = (state, designId) => {
  const layoutsOfDesign = state.layout.ids.filter(
    (id) => state.layout.entities[id].design === designId
  );
  return layoutsOfDesign.find((id) => state.layout.entities[id].level === 0);
};

export const createSelectLayoutSections = () =>
  createSelector(
    (state, layoutId) => {
      return state.layout.entities[layoutId]?.sections;
    },
    (sections) => {
      return sections;
    }
  );

export const selectLayoutType = (state, layoutId) => {
  return state.layout.entities[layoutId]?.layoutType;
};

export const selectLayoutLevel = (state, layoutId) => {
  return state.layout.entities[layoutId]?.level;
};

export const selectLayoutDesignId = (state, layoutId) => {
  return state.layout.entities[layoutId]?.design;
};

export default layoutSlice.reducer;
