import React from "react";
import { injectIntl } from "react-intl";
import CookieConsent, {Cookies} from "react-cookie-consent";
import GA from "../HelperComponents/GoogleAnalytics";

import "./MyCookieBanner.css";

const cookie_banner_style = {
  banner: {
    backgroundColor: "rgba(52, 64, 81, 0.88)",
    fontSize: "12px",
    fontWeight: 600,
    position: "fixed",
    fontFamily: "Poppins",
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    lineHeight: "32px",
    background: "white",
    color: "#343a40",
    fontSize: "12px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
  },
  declineButton: {
    border: "1px solid white",
    borderRadius: 4,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "12px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
  },
  message: {
    display: "block",
    lineHeight: 1.3,
    textAlign: "left",
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

const handleAcceptCookie =() => {
  GA.init();
}

function MyCookieBanner(props) {
  const { intl } = props;

  return (
    <CookieConsent
      enableDeclineButton
      style={cookie_banner_style.banner}
      buttonStyle={cookie_banner_style.button}
      location="bottom"
      className="my-cookie-banner"
      buttonText="Alle Cookies akzeptieren"
      declineButtonText="Nur notwendige Cookies nutzen"
      declineButtonStyle={cookie_banner_style.declineButton}
      cookieName="user-has-accepted-cookies"
      onDecline={handleDeclineCookie}
      onAccept={handleAcceptCookie}
    >
      {intl.formatMessage({ id: "cookie.text" })}
      <a href="/dataprotection"  target="_blank" style={{ fontSize: "10px" }}>
        <br />
        {intl.formatMessage({ id: "cookie.link" })}
      </a>
    </CookieConsent>
  );
}

export default injectIntl(MyCookieBanner);
