import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Pagetitle } from "../HelperComponents/Pagetitle";

import { useLocation, useParams } from "react-router-dom";

import OrderForm from "./OrderForm";

import "./order.css";
import { useSelector } from "react-redux";
import { createSelectDesignById } from "../DesignerV2/Design/designSlice";

function OrderPage() {
  let { id } = useParams();
  const selectDesignById = createSelectDesignById();
  const design = useSelector((state) => selectDesignById(state, id));
  const { state } = useLocation();

  // Error Message if state or design is corrupt
  if (design == null || state?.id !== id) {
    return (
      <Container fluid>
        <Pagetitle
          title="Fehler"
          description="Es ist ein Fehler im Bestellprozess aufgetreten."
        />
        <Container
          fluid
          className="content-container section-with-colored-background"
        >
          <Container>
            <h2 className="section-heading">
              Es tut uns Leid: Ein Fehler ist aufgetreten.
            </h2>
            <p>
              Bitte kehren Sie mit der Zurück-Taste zum Designer zurück und
              versuchen es erneut.
            </p>
          </Container>
        </Container>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Pagetitle
        title="Bestellung"
        description="Bestellung des Designs abschließen"
      />
      <Container
        fluid
        className="content-container section-with-colored-background"
      >
        <Container>
          <h2 className="section-heading">Bestellung abschließen</h2>
          <h3 className="content-heading">
            Haben Sie ein Design gestaltet, das Ihnen gefällt?
          </h3>
          <p>
            Wir arbeiten mit Hochdruck an weiteren Funktionen des Designers.
            Während der Beta-Phase bestimmen Sie den Preis und wir freuen uns
            über Ihre Unterstützung.
          </p>
          <p>Sie können den Betrag nachfolgend eingeben.</p>
        </Container>
      </Container>

      <Container fluid className="content-container">
        <Container>
          <OrderForm designId={id} printData={state} />
        </Container>
      </Container>
    </Container>
  );
}

export default OrderPage;
