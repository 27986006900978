import React, { useState, useRef } from "react";
import {FormattedMessage} from "react-intl";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Container from "react-bootstrap/Container";

import "../../../App.css";
import "./ImageTable.css";

function ImageTable(props) {
  let [clickedImage, setClickedImage] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });

  let references = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  function onImageClick(id) {
    setClickedImage((prevValue) => {
      return {
        ...prevValue,
        [id]: true,
      };
    });
  }

  // Wenn die Maus das jeweilige Bild verlässt soll der "onClick" wieder zurückgesetzt werden
  function clearClickedImage(id) {
    setClickedImage((prevValue) => {
      return {
        ...prevValue,
        [id]: false,
      };
    });
  }

  function createImageRow(image, index) {
    return (
      <Col
        sm={6}
        md={6}
        lg={6}
        xl={3}
        key={"Package-" + index}
        style={{ marginBottom: "20px", height: "100%" }}
      >
        <div className="image-container">
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              outline: "2px",
              outlineColor: "var(--dark)",
              outlineStyle: "solid",
            }}
            src={image.url}
            alt={image.caption}
          />
          <div
            className="after"
            style={{ cursor: clickedImage[index] ? "context-menu" : "pointer" }}
            ref={(el) => {
              references[index] = el;
            }}
            onClick={() => {
              onImageClick(index);
            }}
            onMouseOut={(event) => {
              if (event.toElement || event.relatedTarget) {
                var e = event.toElement || event.relatedTarget;

                if (
                  /* Checkt zuerst ob die Maus das Element auf ein Child verlässt
                   nur wenn nicht über ein Child gehovert wird, wird alles zurückgesetzt */
                  !(
                    e.parentNode === references[index] ||
                    e === references[index]
                  )
                ) {
                  clearClickedImage(index);
                }
              }
            }}
          >
            <p
              className={
                clickedImage[index] ? "move-up image-label" : "image-label"
              }
            >
              {image.caption}
            </p>
            <p
              className={
                clickedImage[index]
                  ? "fade-in image-description"
                  : "image-description"
              }
            >
              {image.description}
            </p>
          </div>
        </div>
      </Col>
    );
  }

  return (
    <Container>
      <Row>{props.images.map(createImageRow)}</Row>
      <Row>
        <Col>
          <div className="image-container">
            <div className="newspaper-image"></div>
            <div
              className="after"
              style={{
                cursor: clickedImage[4] ? "context-menu" : "pointer",
              }}
              ref={(el) => {
                references[4] = el;
              }}
              onClick={() => {
                onImageClick(4);
              }}
              onMouseOut={(event) => {
                if (event.toElement || event.relatedTarget) {
                  var e = event.toElement || event.relatedTarget;

                  if (
                    /* Checkt zuerst ob die Maus das Element auf ein Child verlässt
                   nur wenn nicht über ein Child gehovert wird, wird alles zurückgesetzt */
                    !(
                      e.parentNode === references[4] ||
                      e === references[4]
                    )
                  ) {
                    clearClickedImage(4);
                  }
                }
              }}
            >
              <p
                className={
                  clickedImage[4] ? "move-up image-label" : "image-label"
                }
              >
                <FormattedMessage id="home.packages.newspaper.caption" />
              </p>
              <p
                className={
                  clickedImage[4]
                    ? "fade-in image-description"
                    : "image-description"
                }
              >
                <FormattedMessage id="home.packages.newspaper.description" />
              </p>
            </div>
          </div>
        </Col>
      </Row>

    </Container>
  );
}

export default ImageTable;
