import React, { useState, useMemo } from "react";
import SettingCollapser from "./SettingCollapser/SettingCollapser";
import FontStyleSetting from "./FontStyleSetting";
import ColorSetting from "./ColorSetting";
import FormatSetting from "./FormatSetting";

import {
  createSelectDesignById,
  updateDateFontFamily,
  updateHighlightFontFamily,
  updateNameFontFamily,
  updateStandardFontFamily,
  updateDateFontSize,
  updateHighlightFontSize,
  updateNameFontSize,
  updateStandardFontSize,
  updateHeight,
  updateWidth,
  updatePathColor,
  updateBackgroundColor,
  updateTextColor,
  updateFrame,
  updateDateIcon,
} from "./../Design/designSlice";
import { useDispatch, useSelector, useStore } from "react-redux";
import "./settings.css";
import Button from "react-bootstrap/Button";
import { storeDesignData } from "../../HelperComponents/StorageHelper";

function Settings({
  designId = "",
  devMode = false,
  generatePdf = () => {},
  fetchAndResetDesignInfo = () => {},
}) {
  const store = useStore();
  const dispatch = useDispatch();
  const selectDesignById = useMemo(createSelectDesignById, []);
  const design = useSelector((state) => selectDesignById(state, designId));

  const standardFont = design?.standardFontFamily ?? "Times News Roman";
  const standardFontSize = design?.standardFontSize ?? 12;
  const nameFont = design?.nameFontFamily ?? "Times News Roman";
  const nameFontSize = design?.nameFontSize ?? 12;
  const highlightFont = design?.highlightFontFamily ?? "Times News Roman";
  const highlightFontSize = design?.highlightFontSize ?? 12;
  const dateFont = design?.dateFontFamily ?? "Times News Roman";
  const dateFontSize = design?.dateFontSize ?? 12;
  const backgroundColor = design?.color ?? "#ffffff";
  const textColor = design?.textColor ?? "#000000";
  const frame = design?.frame ?? "none";
  const width = design?.width ?? 1480;
  const height = design?.height ?? 1050;
  const icon = design?.dateIcon ?? "a";

  const [highlightFontScale, setHighlightFontScale] = useState(
    highlightFontSize / standardFontSize
  );

  function onTextColorSelect(color) {
    dispatch(updateTextColor(designId, color.hex));
    dispatch(updatePathColor(designId, color.hex));
    storeDesignData(designId, store);
  }

  function onBackgroundColorSelect(color) {
    dispatch(updateBackgroundColor(designId, color.hex));
    storeDesignData(designId, store);
  }

  function onStandardFontSizeChange(fontSize) {
    dispatch(updateStandardFontSize(designId, fontSize));
    dispatch(updateHighlightFontSize(designId, fontSize * highlightFontScale));
  }

  function onHighlightFontScaleChange(fontScale) {
    setHighlightFontScale(fontScale);
    dispatch(updateHighlightFontSize(designId, standardFontSize * fontScale));
  }

  function setDesignSize(size) {
    dispatch(updateWidth(designId, size.width));
    dispatch(updateHeight(designId, size.height));
    storeDesignData(designId, store);
  }

  function setFrame(frame) {
    dispatch(updateFrame(designId, frame));
    storeDesignData(designId, store);
  }

  function setIcon(icon) {
    dispatch(updateDateIcon(designId, icon));
    storeDesignData(designId, store);
  }

  return (
    <div className="settingsFrame" data-testid="_settingsFrame_">
      <SettingCollapser title="Schrift">
        <FontStyleSetting
          title="Text"
          onFontSizeSelect={(size) => onStandardFontSizeChange(size)}
          onFontSelect={(font) => {
            dispatch(updateStandardFontFamily(designId, font));
            storeDesignData(designId, store);
          }}
          fontValue={standardFont}
          sizeValue={standardFontSize}
          minSize={10}
          maxSize={70}
          withoutHighlight={true}
        />
        <FontStyleSetting
          title="Highlight"
          onFontSizeSelect={(size) => onHighlightFontScaleChange(size / 100)}
          onFontSelect={(font) => {
            dispatch(updateHighlightFontFamily(designId, font));
            storeDesignData(designId, store);
          }}
          fontValue={highlightFont}
          sizeValue={highlightFontScale * 100}
          minSize={50}
          maxSize={200}
        />

        <FontStyleSetting
          title="Name"
          onFontSizeSelect={(size) =>
            dispatch(updateNameFontSize(designId, size))
          }
          onFontSelect={(font) => {
            dispatch(updateNameFontFamily(designId, font));
            storeDesignData(designId, store);
          }}
          fontValue={nameFont}
          sizeValue={nameFontSize}
          minSize={20}
          maxSize={200}
        />

        <FontStyleSetting
          title="Datum"
          onFontSizeSelect={(size) =>
            dispatch(updateDateFontSize(designId, size))
          }
          onFontSelect={(font) =>
            dispatch(updateDateFontFamily(designId, font))
          }
          fontValue={dateFont}
          sizeValue={dateFontSize}
          minSize={10}
          maxSize={100}
        />
      </SettingCollapser>

      <SettingCollapser title="Farbe">
        <ColorSetting
          title="Textfarbe"
          color={textColor}
          onChange={onTextColorSelect}
        />
        <ColorSetting
          title="Hintergrundfarbe"
          color={backgroundColor}
          onChange={onBackgroundColorSelect}
        />
      </SettingCollapser>

      <SettingCollapser title="Format">
        <FormatSetting
          width={width}
          height={height}
          setDesignSize={setDesignSize}
          frame={frame}
          setFrame={setFrame}
          icon={icon}
          setIcon={setIcon}
        />
      </SettingCollapser>
      <Button
        variant="dark"
        type="submit"
        style={{
          width: "100%",
        }}
        onClick={() => {
          fetchAndResetDesignInfo();
        }}
      >
        Layout zurücksetzen
      </Button>
      <Button
        variant="dark"
        type="submit"
        style={{
          width: "100%",
          marginTop: "10px",
        }}
        onClick={() => {
          storeDesignData(designId, store);
          generatePdf();
        }}
      >
        Design als PDF bestellen
      </Button>
    </div>
  );
}

export default Settings;
