import React from "react";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../Home.css";
import "../../App.css";

function Service() {
  return (
    <Container
      fluid
      id="service"
      className="content-container section-with-colored-background"
    >
      <Container>
        <h2 className="section-heading">
          <FormattedMessage id="home.service.heading" />
        </h2>

        <Row>
          <Col lg={{ span: 6 }}>
            <h3 className="sub-content-heading">Für Privatpersonen</h3>
            <p>
              Sie können die Motive in unserem Designer kostenlos
              individualisieren und herunterladen.
            </p>
            <p>
              Sprechen Sie mit Ihrem Bestattungshaus, um die Designs direkt bei
              Ihnen vor Ort drucken oder anpassen zu lassen.
            </p>
          </Col>
          <Col lg={{ span: 6 }}>
            <h3 className="sub-content-heading">Für Bestatter:innen</h3>
            <p>
              Sie können unsere Motive auch dauerhaft zur Gestaltung Ihrer
              Trauerdrucke nutzen, indem Sie die Grafikdateien erwerben.
            </p>
            <p>
              Melden Sie sich gerne über das{" "}
              <Link to="/contact">Kontaktformular</Link>.
            </p>
          </Col>
          <Col lg={{ span: 12 }}>
            <h3 className="sub-content-heading">Darüber hinaus</h3>
            <p>
              Mit jungeTrauer möchten wir unsere Fähigkeiten für diejenigen zur
              Verfügung stellen, die sie in diesem Moment am meisten brauchen.{" "}
              <br />
              Wenn Sie uns in unserer Arbeit unterstützen möchten, können Sie
              dies während des Check-Out Prozesses tun.
            </p>
            <p>
              Wenn Sie individuelle Fragen haben,{" "}
              <Link to="/contact">melden Sie sich gerne direkt bei uns</Link>.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Service;
