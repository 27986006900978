export const textType = {
  info: "info",
  address: "address",
  poem: "poem",
  relatives: "relatives",
  name: "name",
  birthdate: "birthdate",
  deathdate: "deathdate",
};

export const layoutSectionTypes = {
  textArea: "text-area",
  staticArea: "static-area",
  layout: "layout",
};

export const layoutTypes = {
  columnLayout: "column-layout",
  rowLayout: "row-layout",
};
