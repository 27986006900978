import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import WaitButterfly from "./WaitButterfly";
import { DisappearedLoading } from "react-loadingg";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

import "../contact.css";

const contactReasons = [
  { value: "general", label: "Allgemeine Kontaktaufnahme" },
  {
    value: "customize",
    label: "Anfrage Grafik-Dateien für Bestatter",
  },
  {
    value: "custom_condolence",
    label: "Anfrage Beileidskarten für Unternehmen",
  },
  { value: "coop", label: "Kooperationsanfrage" },
];

function ContactForm({ intl, state }) {
  const history = useHistory();

  // Send the E-Mail
  function sendEmail(token) {
    fetch("/api/send-mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact,
        message,
        motif,
        products,
        contactReason,
        captchatoken: token,
      }),
    })
      .then((res) => {
        if (res.ok) {
          history.push("/success");
        } else {
          setWaitForBackendResponse(false);
          if (res.status === 403) {
            alert.show(
              intl.formatMessage({ id: "error.mail.blacklist.instruction" }),
              {
                title: intl.formatMessage({ id: "error.mail.blacklist.error" }),
              }
            );
          } else {
            alert.show(
              intl.formatMessage({ id: "error.mail.default.instruction" }),
              { title: intl.formatMessage({ id: "error.mail.default.error" }) }
            );
          }
        }
        return res.json();
      })
      .catch((error) => {
        setWaitForBackendResponse(false);
        alert.show(
          intl.formatMessage({ id: "error.mail.default.instruction" }),
          { title: intl.formatMessage({ id: "error.mail.default.error" }) }
        );
        console.log(error);
      });
  }

  // ReCAPTCHA Stuff
  let recaptchaRef = useRef(null);
  const onSubmitWithReCAPTCHA = async () => {
    try {
      const token = await recaptchaRef.executeAsync();
      recaptchaRef.reset();
      return token;
    } catch (err) {
      console.log("Error");
      throw new Error(err);
    }
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (recaptchaRef = ref);
    }
  };

  // Message Data to be sent to Backend
  const emptyContact = {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  };

  const emptyProducts = {
    griefcard: false,
    cardlet: false,
    thankyou: false,
  };

  useEffect(() => {
    const emptyProducts = {
      griefcard: false,
      cardlet: false,
      thankyou: false,
    };

    state?.contactReason &&
      setContactReason(
        contactReasons.find((reason) => reason.value === state.contactReason)
      );
    state?.motif && setMotif(state.motif);
    state?.products && setProducts({ ...emptyProducts, ...state.products });
  }, [state]);

  const [contact, setContact] = useState(emptyContact);
  const [message, setMessage] = useState("");
  const [motif, setMotif] = useState("");
  const [products, setProducts] = useState(emptyProducts);
  const [contactReason, setContactReason] = useState(contactReasons[0]);

  function onProductChanged(event) {
    const { name, checked } = event.target;
    setProducts((prevProd) => {
      return { ...prevProd, [name]: checked };
    });
  }

  function onMessageChange(event) {
    const { value } = event.target;
    if (value.length <= 1000) setMessage(value);
  }

  function onMotifChange(event) {
    const { value } = event.target;
    setMotif(value);
  }

  function onContactChange(event) {
    const { name, value } = event.target;
    setContact((prevMsg) => {
      return {
        ...prevMsg,
        [name]: value,
      };
    });
  }

  // Form validation
  const [validated, setValidated] = useState(false);

  const [waitForBackendResponse, setWaitForBackendResponse] = useState(false);
  // Form Submission
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      onSubmitWithReCAPTCHA()
        .then((token) => {
          setWaitForBackendResponse(true);
          sendEmail(token);
        })
        .catch((e) => {
          setWaitForBackendResponse(false);
          console.log(e);
        });
    }

    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  // Alerts
  const alert = useAlert();

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Grund ihrer Anfrage</Form.Label>
        <Select
          isSearchable={false}
          defaultValue={contactReasons[0]}
          label="Single select"
          options={contactReasons}
          value={contactReason}
          onChange={setContactReason}
        />
      </Form.Group>
      <hr />
      {/*contactReason.value === "customize" && (
        <>
          <p>
            Teilen Sie uns bitte mit, welche Produkte und welches Motiv Sie
            wünschen. Alle weiteren Details klären wir dann persönlich mit
            Ihnen.
          </p>
          <Row>
            <Col md>
              <Form.Group>
                <Form.Label>Gewünschte Produkte *</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="griefcard"
                  checked={products.griefcard}
                  onChange={onProductChanged}
                  label="Trauerkarte"
                  id="Trauerkarte"
                />
                <Form.Check
                  type="checkbox"
                  name="cardlet"
                  checked={products.cardlet}
                  onChange={onProductChanged}
                  id="Sterbebild"
                  label="Sterbebild"
                />
                <Form.Check
                  type="checkbox"
                  name="thankyou"
                  checked={products.thankyou}
                  onChange={onProductChanged}
                  id="Danksagungskarte"
                  label="Danksagungskarte"
                />
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group>
                <Form.Label>Ihr Wunschmotiv *</Form.Label>
                <Form.Control
                  onChange={onMotifChange}
                  value={motif}
                  type="text"
                  name="motif"
                  placeholder="Wunschmotiv"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
        </>
      )*/}

      {(contactReason.value === "custom_condolence" ||
        contactReason.value === "customize") && (
        <Form.Group>
          <Form.Label>Ihr Wunschmotiv *</Form.Label>
          <Form.Control
            onChange={onMotifChange}
            value={motif}
            type="text"
            name="motif"
            placeholder="Wunschmotiv"
            required
          />
          <hr />
        </Form.Group>
      )}

      <Row>
        <Col md>
          <Form.Group>
            <Form.Label>Ihr Vorname *</Form.Label>
            <Form.Control
              onChange={onContactChange}
              value={contact.firstname}
              type="text"
              name="firstname"
              placeholder="Vorname"
              required
            />
          </Form.Group>
        </Col>
        <Col md>
          <Form.Group>
            <Form.Label>Ihr Nachname *</Form.Label>
            <Form.Control
              onChange={onContactChange}
              value={contact.lastname}
              type="text"
              name="lastname"
              placeholder="Nachname"
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>Ihre E-Mail Adresse * </Form.Label>
        <Form.Control
          onChange={onContactChange}
          value={contact.email}
          type="email"
          name="email"
          placeholder="Bitte geben Sie Ihre E-Mail Adresse ein"
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Ihre Telefon-/ Handynummer </Form.Label>
        <Form.Control
          onChange={onContactChange}
          value={contact.phone}
          type="phone"
          name="phone"
          placeholder="Falls Sie Kontakt via Telefon wünschen, geben Sie Ihre Telefonnummer ein"
        />
      </Form.Group>
      <hr />
      <Form.Group>
        <Form.Label>Ihre Nachricht an uns</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={message}
          onChange={onMessageChange}
        />
        <p className="message-counter">({message.length}/1000)</p>
      </Form.Group>
      <hr />
      {contactReason.value !== "general" && contactReason.value !== "coop" && (
        <Form.Group controlId="formAGBCheckbox">
          <Form.Check
            type="checkbox"
            required
            label={
              <FormattedMessage
                id="createForm.order.agb.label"
                values={{
                  AGBs: (
                    <a href="/AGB" target="_blank">
                      AGBs
                    </a>
                  ),
                }}
              />
            }
          />
        </Form.Group>
      )}
      <Form.Group controlId="formDataprotectionCheckbox">
        <Form.Check
          type="checkbox"
          required
          label={
            <FormattedMessage
              id="createForm.order.dataprotection.label"
              values={{
                dataprotection: (
                  <a href="/dataprotection" target="_blank">
                    Datenschutzerklärung
                  </a>
                ),
              }}
            />
          }
        />
      </Form.Group>

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_reCAPCHA_Client_Key}
        size="invisible"
        style={{ zIndex: 10 }}
        ref={(r) => setCaptchaRef(r)}
      />
      <div
        style={{
          position: "relative",
          marginLeft: "20px",
          marginTop: "50px",
        }}
      >
        <Button
          variant="dark"
          type="submit"
          style={{
            width: "100px",
            height: "40px",
            zIndex: "5",
            position: "relative",
          }}
          disabled={waitForBackendResponse ? true : false}
        >
          {waitForBackendResponse ? (
            <DisappearedLoading
              color="#ffffff"
              style={{
                position: "relative",
                margin: "auto",
                width: "50px",
                height: "5px",
              }}
            />
          ) : (
            "Senden"
          )}
        </Button>
        <WaitButterfly
          active={waitForBackendResponse ? true : false}
          style={{
            width: "50px",
            height: "50px",
            left: "-30px",
            top: "-36px",
          }}
        />
      </div>
    </Form>
  );
}

export default injectIntl(ContactForm);
