import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { pathSelectors } from "./pathSlice";
import {
  updateMainPathSize,
  selectMainPathHeight,
  selectMainPathWidth,
  selectDesignGenerator,
} from "./../Design/designSlice";
import { useDispatch, useSelector } from "react-redux";
import { addPath } from "../printerSlice";

function preparePxNumber(number) {
  return number.toString() + "px";
}

function checkCollision(rect1, rect2) {
  if (
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  ) {
    return false;
  } else return true;
}

function calculateNeededMovement(pathFrames, textPrintArea) {
  if (pathFrames == null || textPrintArea == null) return 0;
  try {
    let i = 0;
    let relevantLines = [];
    while (i < pathFrames.length) {
      // First check whether the outer frame collides with the path --> If not return the current frame
      if (!checkCollision(pathFrames[i], textPrintArea.outerRect)) return i;
      // Then which lines cause an collision
      const currFrameId = i; // Line needed to remove Linter Warning that i is an "unsafe reference"
      relevantLines = textPrintArea.lineRects.filter((line) => {
        return checkCollision(pathFrames[currFrameId], line);
      });
      // When there is no collision detected with any line --> rerturn the current frame
      if (relevantLines.length <= 0) return i;

      // If there is an actual collision retry with the next frame
      i++;
    }
    // If all frames cause an collision: Return the length of the pathFrame array (which does not point to any frame).
    return i;
  } catch (error) {
    return 0;
  }
}

const defaultPath = {
  path: "M 0,0 H 200 V 100 H 0 Z",
  viewBox: "0 0 100 100",
};

function Pathdrawer({
  devMode = false,
  id = "",
  posX = 500,
  posY = 500,
  rotation = 0,
  movePath = { x: 0, y: 0, s: 0 },
  maxMove = 0,
  width = 50,
  height = 50,
  areaWidth = 100,
  areaHeight = 100,
  color = "black",
  collisionDetection = false,
  frame = 0,
  textPrintArea = { outerRect: {}, lineRects: [] },
  getAreaRect = () => {
    return { left: 0, right: 0, top: 0, bottom: 0 };
  },
  mainDesign = false,
}) {
  const {
    path,
    viewBox,
    design: designId,
  } = useSelector((state) => pathSelectors.selectById(state, id)) ??
  defaultPath;

  const mainPathHeightPx = useSelector((state) =>
    selectMainPathHeight(state, designId)
  );
  const mainPathWidthPx = useSelector((state) =>
    selectMainPathWidth(state, designId)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // Store size of main design in Store (only if this is the main design)
    if (mainDesign) {
      dispatch(updateMainPathSize(designId, areaHeight, areaWidth));
    }
  }, [areaWidth, areaHeight, mainDesign, designId, dispatch]);

  // Path Aspect Ratio is needed to calculate Frame around the path (a correct sized is necessary for calculating the collission correctly)
  // we calculate it once and memorize it afterwards for performance reasons
  const viewBoxAspectRatio = useMemo(calculateAspectRatio, [viewBox]);

  function calculateAspectRatio() {
    const viewBoxSplitted = viewBox.split(" ");
    const viewBoxWidth = parseFloat(viewBoxSplitted[2]);
    const viewBoxHeight = parseFloat(viewBoxSplitted[3]);
    const aspectRatio = viewBoxHeight / viewBoxWidth;
    return aspectRatio;
  }

  // CALCULATE SIZES AND POSITIONS IN PX

  let posXPixelNumber = (posX / 1000) * areaWidth;
  let posYPixelNumber = (posY / 1000) * areaHeight;

  let widthPixelNumber;
  let heightPixelNumber;

  // set the size of all sub designs according the size of main design
  if (mainPathHeightPx == null || mainPathWidthPx == null || mainDesign) {
    widthPixelNumber = (width / 1000) * areaWidth;
    heightPixelNumber = (height / 1000) * areaHeight;
  } else {
    widthPixelNumber = (width / 1000) * mainPathWidthPx;
    heightPixelNumber = (height / 1000) * mainPathHeightPx;
  }

  // Set Path frame to actual Aspect Ratio (for correct collision detection)
  const currentAspectRatio = heightPixelNumber / widthPixelNumber;
  if (currentAspectRatio < viewBoxAspectRatio) {
    const memoPixelWidth = widthPixelNumber;
    widthPixelNumber = heightPixelNumber / viewBoxAspectRatio;
    posXPixelNumber = posXPixelNumber + (memoPixelWidth - widthPixelNumber) / 2;
  } else {
    const memoPixelHeight = heightPixelNumber;
    heightPixelNumber = widthPixelNumber * viewBoxAspectRatio;
    posYPixelNumber = posYPixelNumber + (memoPixelHeight - heightPixelNumber) / 2;
  }

  let posXPixel = preparePxNumber(posXPixelNumber);
  let posYPixel = preparePxNumber(posYPixelNumber);

  /* catch negative heights and widths 
  --> It could happen in some cases that width and 
  height is negative for a short period (e.g. after Screensize change) 
  --> In those cases it shall be set to 0 because negative sizes throw errors*/

  if (widthPixelNumber < 0) widthPixelNumber = 0;
  if (heightPixelNumber < 0) heightPixelNumber = 0;

  // END OF POSITION CALCULATION

  // PATH COLLISION DETECTION

  // We use "useMemo" here that the frames are not calculated on every render
  const pathFrames = useMemo(generatePathFrames, [
    areaWidth,
    areaHeight,
    collisionDetection,
    frame,
    heightPixelNumber,
    maxMove,
    movePath.s,
    movePath.x,
    movePath.y,
    posXPixelNumber,
    posYPixelNumber,
    widthPixelNumber,
  ]);

  // Path Frames (which are later checked for collision with Textarea)
  // are precalculated and stored for performance reasons. Path Frames is an array which
  // holds every possible position of the path

  function generatePathFrames() {
    // Path frames need to be in px (relative to parent Area) for correct comparison with printArea
    if (!collisionDetection) return [];
    const pathFrames_loc = [];

    const framePxHorizontal = (frame / 1000) * areaWidth;
    const framePxVertical = (frame / 1000) * areaHeight;
    const movePathPx = {
      x: (movePath.x / 1000) * areaWidth,
      y: (movePath.y / 1000) * areaHeight,
      s: movePath.s,
    };
    for (let i = 0; i <= maxMove; i++) {
      pathFrames_loc.push({
        left:
          posXPixelNumber -
          framePxHorizontal +
          movePathPx.x * i -
          ((movePathPx.s * widthPixelNumber) / 2) * i,
        right:
          posXPixelNumber +
          widthPixelNumber +
          framePxHorizontal +
          movePathPx.x * i +
          ((movePathPx.s * widthPixelNumber) / 2) * i,
        top:
          posYPixelNumber -
          framePxVertical +
          movePathPx.y * i -
          ((movePathPx.s * heightPixelNumber) / 2) * i,
        bottom:
          posYPixelNumber +
          heightPixelNumber +
          framePxVertical +
          movePathPx.y * i +
          ((movePathPx.s * heightPixelNumber) / 2) * i,
      });
    }
    return pathFrames_loc;
  }

  const currMove = collisionDetection
    ? calculateNeededMovement(pathFrames, textPrintArea)
    : 0;

  // END OF COLLISION DETECTION

  // PREPARE DATA FOR STYLING

  const heightPixel = preparePxNumber(heightPixelNumber);
  const widthPixel = preparePxNumber(widthPixelNumber);

  let visible = currMove <= maxMove ? true : false;

  const move = visible
    ? {
        x: (movePath.x / 1000) * areaWidth * currMove,
        y: (movePath.y / 1000) * areaHeight * currMove,
        s: 1 + movePath.s * currMove,
      }
    : { x: 0, y: 0, s: 1 };

  let style = {
    position: "absolute",
    fill: color,
    left: posXPixel,
    top: posYPixel,
    transform: `translate(${move.x}px, ${move.y}px) scale(${move.s},${move.s}) rotate(${rotation}deg)`,
    visibility: visible ? "visible" : "hidden",
    borderStyle: devMode ? "solid" : "none",
    borderWidth: devMode ? "1px" : "0px",
    borderColor: "green",
  };

  // GENERATE FINAL DESIGN
  const designGenerator = useSelector((state) =>
    selectDesignGenerator(state, designId)
  );
  const designGeneratorMemo = useRef(false);
  useEffect(() => {
    if (!designGenerator) {
      designGeneratorMemo.current = false;
      return;
    }

    if (designGeneratorMemo === designGenerator) return;
    designGeneratorMemo.current = true;

    if (!visible) return;

    let p_posX = posXPixelNumber;
    let p_posY = posYPixelNumber;
    let p_width = widthPixelNumber;
    let p_height = heightPixelNumber;

    if (currMove > 0) {
      // the frames have the "security space" (framePxHorizontal/Vertical) added
      // --> for printing they need to be removed
      const framePxHorizontal = (frame / 1000) * areaWidth;
      const framePxVertical = (frame / 1000) * areaHeight;

      p_posX = pathFrames[currMove].left + framePxHorizontal;
      p_posY = pathFrames[currMove].top + framePxVertical;
      p_width =
        pathFrames[currMove].right -
        pathFrames[currMove].left -
        2 * framePxHorizontal;
      p_height =
        pathFrames[currMove].bottom -
        pathFrames[currMove].top -
        2 * framePxVertical;
    }
    const areaRect = getAreaRect() || { left: 0, right: 0, top: 0, bottom: 0 };
    p_posX = p_posX + areaRect.left;
    p_posY = p_posY + areaRect.top;

    const pathItem = {
      pathId: id,
      posX: p_posX,
      posY: p_posY,
      width: p_width,
      height: p_height,
      rotation: rotation,
    };

    dispatch(addPath(pathItem));
  }, [
    designGenerator,
    areaHeight,
    areaWidth,
    currMove,
    dispatch,
    getAreaRect,
    frame,
    heightPixelNumber,
    id,
    pathFrames,
    posXPixelNumber,
    posYPixelNumber,
    rotation,
    visible,
    widthPixelNumber,
  ]);
  // ----

  return (
    <svg
      data-testid={`_path_${id}`}
      width={widthPixel}
      height={heightPixel}
      preserveAspectRatio="xMidYMid"
      viewBox={viewBox}
      style={style}
    >
      <path d={path} id="path4" />
    </svg>
  );
}

Pathdrawer.propTypes = {
  posX: PropTypes.number,
  posY: PropTypes.number,
  movePath: PropTypes.object,
  maxMove: PropTypes.number,
  currMove: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  areaWidth: PropTypes.number,
  areaHeight: PropTypes.number,
  color: PropTypes.string,
};

export default Pathdrawer;
