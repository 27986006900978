import React from "react";
import Designer from "../DesignerV2";
import { Pagetitle } from "../HelperComponents/Pagetitle";
import { useParams } from "react-router-dom";
import Bowser from "bowser";


import "../App.css";
import "./designerPage.css";
import { BsThreeDotsVertical } from "react-icons/bs";

function DesignerPage() {
  let { id } = useParams();
  const browser = Bowser.parse(window.navigator.userAgent);
  let ua = navigator.userAgent || navigator.vendor || window.opera;
  let isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  let isFacebook = ua.indexOf("FB_IAB") > -1 ? true : false;
  let isAndroid = browser.os.name === "Android";

  let isBuggy = (isInstagram || isFacebook) && isAndroid;

  if (isBuggy)
    return (
      <div className="bug-overlay">
        <Pagetitle
          title="Designer"
          description="Gestalten Sie eine moderne Traueranzeige"
        />
        <img className="arrow-image" src="/arrow.svg" />
        <p className="error-text">Der Designer wird von diesem In-App-Browser leider nicht unterstützt.<br/><br/>Klicken Sie bitte oben rechts auf <BsThreeDotsVertical/> und öffnen Sie die Seite mit Ihrem Standardbrowser.</p>
        <img className="pusti-image" src="/FaviconJT.png"/>
      </div>
    );

  return (
    <div className="section-with-light-colored-background designer-wrapper">
      <Pagetitle
        title="Designer"
        description="Gestalten Sie eine moderne Traueranzeige"
      />

      <Designer designId={id} />
    </div>
  );
}

export default DesignerPage;
