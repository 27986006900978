// Dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-mui";
import GA from "./HelperComponents/GoogleAnalytics";
import { Cookies } from "react-cookie-consent";


// Components
import Home from "./Page_Home/";
import NotFoundPage from "./Page_NotFound";
import Success from "./Page_Success";
import ContactPage from "./Page_Contact";
import NavigationBar from "./NavigationBar/";
import Header from "./Page_Home/Header/";
import Footer from "./Footer/";
import ScrollToTop from "./HelperComponents/ScrollToTop";
import Imprint from "./Pages_Footer/Imprint";
import DataProtection from "./Pages_Footer/DataProtection";
import AGB from "./Pages_Footer/AGB";
import FAQ from "./Pages_Footer/FAQ";
import MyCookieBanner from "./MyCookieBanner/";
import TextIdeas from "./Page_TextIdeas/";
import Backend from "./Page_Backend/";
import DesignerPage from "./Page_Designer/";
import OrderPage from "./Page_Order/"
import MotifSelectionPage from "./Page_MotifTiles";
import MotifPage from "./Page_Motif";
import Redirect from "./Redirect/"


// CSS
import "./App.css";

// Translations
import messages_de from "../translations/de.json";
import messages_en from "../translations/en.json";

const messages = {
  de: messages_de,
  en: messages_en,
};



function App() {
  const locale = "de";

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <AlertProvider template={AlertTemplate}>
        <React.Fragment>
          <MyCookieBanner />
          <Router>
            {Cookies.get("user-has-accepted-cookies") == "true" ? (GA.init() && <GA.RouteTracker />) : <GA.RouteTracker />}
            <ScrollToTop />
            <Route path="/" exact component={Header} />
            <NavigationBar />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route
                path="/seite-und-impressum"
                component={() => {
                  return <Redirect to="/" />;
                }}
              />
              <Route path="/faq" component={FAQ} />
              <Route path="/AGB" component={AGB} />
              <Route path="/dataprotection" component={DataProtection} />
              <Route path="/imprint" component={Imprint} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/success" component={Success} />
              <Route path="/anleitung" component={TextIdeas} />
              <Route path="/backend" component={Backend} />
              <Route path="/designer/:id" component={DesignerPage}/>
              <Route path="/order/:id" component={OrderPage}/>
              <Route exact path="/motifs" component={MotifSelectionPage}/>
              <Route exact path="/motifs/:id" component={MotifPage}/>
              <Route exact path="/paypal/:motif" component={Redirect}/>
              <Route component={NotFoundPage} />
            </Switch>
            <Footer />
          </Router>
        </React.Fragment>
      </AlertProvider>
    </IntlProvider>
  );
}

export default App;
