import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

JTSelect.propTypes = {
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

function JTSelect({ value, defaultValue, onChange, options, icons = false }) {
  const selectorHeight = 20;
  const selectorStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      height: selectorHeight,
      minHeight: selectorHeight,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: selectorHeight,
      padding: "0 3px",
      fontSize: icons ? 14 : 12,
      fontFamily: icons ? "jTIconFont" : "Poppins",
      whiteSpace: "pre-wrap",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: selectorHeight,
      paddingTop:"0px",
      paddingBottom:"0px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding:"0px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: icons ? 18 : 12,
        fontFamily: icons ? "jTIconFont" : "Poppins",
        whiteSpace: "pre-wrap",
      };
    },
  };
  return (
    <Select
      isSearchable={false}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      label="Single select"
      options={options}
      styles={selectorStyle}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#eeeeff",
          primary50: "#ddddee",
          primary: "#343a40",
        },
      })}
    />
  );
}

export default JTSelect;
