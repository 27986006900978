import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// REDUCER

const textAdapter = createEntityAdapter();

const initialState = textAdapter.getInitialState();

const textSlice = createSlice({
  name: "texts",
  initialState,
  reducers: {
    deleteTextsByDesignId(state, action) {
      const designId = action.payload;
      const idsToDelete = state.ids.filter(
        (id) => state.entities[id].design === designId
      );
      textAdapter.removeMany(state, idsToDelete);
    },
    updateText: {
      reducer(state, action) {
        textAdapter.updateOne(state, action.payload);
      },
      prepare(textId, text) {
        return {
          payload: { id: textId, changes: { text: text } },
        };
      },
    },
    generateTextsWithDesign: {
      reducer(state, action) {
        textAdapter.upsertMany(state, action.payload);
      },
      prepare(texts, designId) {
        const textsWithDesignId = texts.map((text) => {
          return { ...text, design: designId };
        });
        return {
          payload: textsWithDesignId,
        };
      },
    },
  },
});

export const { generateTextsWithDesign, deleteTextsByDesignId, updateText } =
  textSlice.actions;

export const textSelectors = textAdapter.getSelectors((state) => state.text);

export const textSelectManyById = createSelector(
  (state, textIds) => {
    const texts = textIds.map((textId) => state.text.entities[textId]);
    return texts;
  },
  (texts) => {
    return texts;
  }
);

export const selectTextById = (state, textId) => {
  return state.text.entities[textId]?.text;
};

export const selectTextsByDesign = createSelector(
  (state, designId) => {
    const textIds = state.text.ids.filter(
      (id) => state.text.entities[id].design === designId
    );
    const texts = textIds.map((textId) => state.text.entities[textId]);
    return texts;
  },
  (texts) => {
    return texts;
  }
);

export default textSlice.reducer;
