import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import ImageTable from "./ImageTable";

import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import Container from "react-bootstrap/Container";

import "../Home.css";
import "../../App.css";

function Packages(props) {
  const { intl } = props;
  const packages = [
    {
      url:
        "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/packages/Karte.png",
      caption: intl.formatMessage({ id: "home.packages.card.caption" }),
      description: intl.formatMessage({ id: "home.packages.card.description" }),
    },
    {
      url:
        "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/packages/Trauerheft.png",
      caption: intl.formatMessage({ id: "home.packages.picture.caption" }),
      description: intl.formatMessage({
        id: "home.packages.picture.description",
      }),
    },
    {
      url:
        "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/packages/Danksagung.png",
      caption: intl.formatMessage({ id: "home.packages.thank.caption" }),
      description: intl.formatMessage({
        id: "home.packages.thank.description",
      }),
    },
    {
      url:
        "https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/packages/Karte_bunt.png",
      caption: intl.formatMessage({ id: "home.packages.colorcard.caption" }),
      description: intl.formatMessage({
        id: "home.packages.colorcard.description",
      }),
    },
  ];

  return (
    <Container
      fluid
      className="content-container section-with-light-colored-background"
      id="packages"
    >
      <Container>
        <h2 className="section-heading">
          <FormattedMessage id="home.packages.title" />
        </h2>
        <p>
          <FormattedMessage id="home.packages.text" values={{ br: <br /> }} />
        </p>
        <ImageTable images={packages} columns="5" />
      </Container>

      <Container className="center">
        <Button
          variant="outline-dark"
          className="floating-button"
          as={Link}
          to="/motifs"
        >
          <FormattedMessage id="home.motifs.text2" />
        </Button>
      </Container>
    </Container>
  );
}

export default injectIntl(Packages);
