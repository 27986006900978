export const frameOptions = [
  { value: "none", label: "kein Rahmen" },
  { value: "scribble", label: "Handzeichnung" },
  { value: "single", label: "klassisch innen" },
  { value: "outer", label: "klassisch außen"},
];

export const scribble = {
  left: {
    width: 1.9768699,
    viewBox: "0 0 1.97687 149.99999",
    path:
      "m 0,49.855559 c 0.0103,5.585967 0.47466,11.491005 0.4965,17.055435 0.0219,5.57266 -0.0288,10.83987 -0.005,16.4169 0.0235,5.57351 -0.0385,10.51185 -0.0234,16.08786 0.0151,5.584776 0.42794,12.551606 0.42425,18.115966 -0.004,5.56945 -0.20941,10.72596 -0.24264,16.30525 -0.033,5.58391 0.40563,10.5983 0.33311,16.16301 l 0.39798,-0.0587 c 0.0725,-5.58442 -0.30911,-10.57475 -0.27614,-16.14047 0.0333,-5.57009 0.2284,-10.81599 0.23236,-16.39595 0.003,-5.58514 -0.43886,-12.31416 -0.4539,-17.879206 -0.015,-5.57367 0.13794,-10.69281 0.11446,-16.26905 -0.0238,-5.57269 -0.0382,-10.70388 -0.0601,-16.28088 C 0.91558,61.390014 0.46984,55.319572 0.46009,49.755671 0.45109,44.196841 1.05696,38.349418 1.06936,32.758863 1.08206,27.179063 0.89634,22.490366 0.94171,16.920817 0.98671,11.36582 1.88826,5.640801 1.97687,0.04771 L 1.51296,0 C 1.42516,5.555746 0.56904,11.315075 0.52341,16.90947 0.47791,22.48981 0.72543,27.152123 0.71278,32.722191 0.70038,38.281503 0.01053,44.253641 0.0203,49.844715 Z",
  },
  right: {
    width: 1.9768699,
    viewBox: "0 0 1.97687 149.99998",
    path:
      "M 1.97687,100.14444 C 1.96657,94.558478 1.5022,88.65343 1.48037,83.089011 1.45847,77.516356 1.50917,72.24913 1.48537,66.672106 1.46187,61.098594 1.52387,56.160272 1.50877,50.584248 1.49367,44.999463 1.08083,38.032623 1.08452,32.468275 1.08852,26.898821 1.29393,21.742318 1.32715,16.163016 1.36015,10.579105 0.92153,5.5647081 0.99405,0 L 0.59607,0.058682 c -0.0725,5.5844231 0.3091,10.574749 0.27614,16.140479 -0.0333,5.570081 -0.2284,10.816 -0.23236,16.395964 -0.003,5.58513 0.43886,12.314161 0.45389,17.87921 0.015,5.573668 -0.13793,10.692808 -0.11445,16.269035 0.0238,5.572691 0.0382,10.703889 0.0601,16.280889 0.0219,5.585701 0.46764,11.656149 0.47739,17.220071 0.009,5.55882 -0.59687,11.40625 -0.60926,16.9968 -0.0127,5.57981 0.17301,10.2685 0.12764,15.83805 C 0.99016,138.63417 0.08861,144.3592 0,149.95228 l 0.46391,0.0477 c 0.0878,-5.55575 0.94392,-11.31508 0.98955,-16.90949 0.0455,-5.58033 -0.20202,-10.24263 -0.18937,-15.8127 0.0124,-5.55931 0.70225,-11.53145 0.69248,-17.12253 z",
  },
  top: {
    height: 1.976781,
    viewBox: "0 0 149.99997 1.976781",
    path:
      "M 100.14447,0 C 94.55851,0.0098 88.65348,0.474672 83.08904,0.496511 77.51639,0.518441 72.24917,0.467661 66.67212,0.491511 61.09864,0.515001 56.16026,0.453011 50.58426,0.468021 44.99946,0.483101 38.03264,0.895963 32.46829,0.89227 26.89884,0.88817 21.74232,0.682861 16.16302,0.649636 10.57911,0.616666 5.5647,1.055262 0,0.982736 l 0.0587,0.397982 c 5.58442,0.07255 10.57476,-0.309099 16.14049,-0.276138 5.57008,0.03322 10.816,0.228402 16.39597,0.23236 5.58513,0.0033 12.31417,-0.438867 17.87921,-0.453896 5.57367,-0.015 10.69282,0.137937 16.26905,0.114458 5.57269,-0.02374 10.70389,-0.03826 16.28089,-0.06011 5.5857,-0.02193 11.65615,-0.46764 17.22009,-0.477392 5.55881,-0.0097 11.40622,0.596862 16.99679,0.609261 5.57981,0.0127 10.2685,-0.173016 15.83808,-0.127648 5.55496,0.04509 11.27998,0.946557 16.8731,1.035168 l 0.0476,-0.463912 C 144.44423,1.425039 138.6849,0.568949 133.09048,0.523316 127.51017,0.477896 122.84787,0.725347 117.27778,0.712683 111.71845,0.700313 105.74634,0.010432 100.15525,0.02021 Z",
  },
  bottom: {
    height: 1.5961612,
    viewBox: "0 0 150 1.5961612",
    path:
      "m 149.97141,0 c -4.56663,0.0436 -9.05241,0.77511 -13.5898,0.81951 -4.53973,0.0439 -9.03928,-0.41671 -13.60354,-0.37472 -4.55718,0.0416 -9.11797,0.30277 -13.66504,0.33834 -4.55845,0.0356 -8.77548,0.36108 -13.32124,0.38702 C 91.24773,1.19605 86.78324,0.78983 82.22306,0.80266 77.67429,0.81556 72.89525,0.77236 68.34023,0.76836 63.78867,0.76536 59.23389,0.63438 54.68164,0.61003 50.13688,0.58593 45.03201,0.30138 40.47296,0.2535 35.91396,0.2054 31.9093,0.47924 27.3648,0.40409 22.81005,0.32869 17.9128,0.39289 13.36511,0.28667 8.80902,0.18022 4.61865,0.18767 0.0731,0.04728 L 0,0.55894 c 4.5563,0.14069 8.75613,0.027 13.30276,0.1332 4.55501,0.10619 9.36657,0.004 13.91538,0.0783 4.55931,0.0757 8.79443,-0.24739 13.33962,-0.19926 4.54512,0.0478 9.44891,0.41136 14.00869,0.43543 4.5521,0.0244 9.2078,0.17277 13.76047,0.17652 4.54942,0.004 9.33233,0.12009 13.88754,0.10725 4.54427,-0.0129 9.01157,0.33033 13.57195,0.30438 4.55866,-0.0262 8.8862,-0.47555 13.43219,-0.51086 4.55738,-0.0358 9.0378,-0.26104 13.58463,-0.30276 4.54018,-0.0415 9.08165,0.52101 13.64548,0.47688 C 141.01554,1.21342 145.46284,0.59926 150,0.5562 l -0.0813,-0.513 z",
  },
};
export const single = {
  horizontal: {
    height: 2,
    viewBox: "0 0 150 2",
    path: "M 0,2 V 0 h 150 v 2 z",
  },
  vertical: {
    width: 2,
    viewBox: "0 0 2 150",
    path: "M 0,0 H 2 V 150 H 0 Z",
  },
};
export const double = { horizontal: "", vertical: "" };
