/*props:

    width: int
      --> Sets the width of the designer
*/

// React Imports
import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  createSelectDesignById,
  updateSizePx,
  selectDesignGenerator,
} from "./designSlice";
import {
  selectRootLayoutByDesign,
  resetAddHeight,
} from "./../Layout/layoutSlice";

// Component Imports
import Layout from "./../Layout";
import Frame from "./../Frame";

import "../../App.css";
import { addDesignBoundings } from "../printerSlice";

const defaultDesign = {
  width: 14.8,
  height: 10.5,
  color: "white",
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  paddingTop: 20,
  lineStyle: "none",
  standardFontFamily: "Times News Roman",
  standardFontSize: 30,
  dateFontFamily: "Times News Roman",
  dateFontSize: 40,
  nameFontFamily: "Poppins",
  nameFontSize: 90,
  frame: "none",
  highlightFontFamily: "Poppins",
  highlightFontSize: 30,
  id: "DUMMY_DATA",
};

function Design({
  width: canvas_width = 800,
  designId = "",
  devMode = false,
  editable = true,
  designLoaded = false,
}) {
  const selectDesignById = useMemo(createSelectDesignById, []);
  const design =
    useSelector((state) => selectDesignById(state, designId)) ?? defaultDesign;

  const designFrame = useRef(null);
  const scale = canvas_width / design.width;
  const canvas_height = design.height * scale;
  const dispatch = useDispatch();

  // GENERATE FINAL DESIGN
  const designGenerator = useSelector((state) =>
    selectDesignGenerator(state, designId)
  );
  useEffect(() => {
    if (designGenerator) {
      let { x, y } = designFrame.current.getBoundingClientRect();
      x = x + window.pageXOffset;
      y = y + window.pageYOffset;

      dispatch(addDesignBoundings({ x: x, y: y }));
    }
  }, [designGenerator, dispatch]);
  // ----

  useEffect(() => {
    if (design.pxHeight !== canvas_height || design.pxWidth !== canvas_width) {
      dispatch(updateSizePx(designId, canvas_height, canvas_width));
      dispatch(resetAddHeight(designId));
    }
  }, [
    design.width,
    design.height,
    canvas_width,
    canvas_height,
    design.pxHeight,
    design.pxWidth,
    design.id,
    designId,
    dispatch,
  ]);

  const paddingLeft = (canvas_width / 1000) * design.paddingLeft;
  const paddingRight = (canvas_width / 1000) * design.paddingRight;
  const paddingTop = (canvas_height / 1000) * design.paddingTop;
  const paddingBottom = (canvas_height / 1000) * design.paddingBottom;

  const height_rootLayout = canvas_height - paddingTop - paddingBottom;
  const width_rootLayout = canvas_width - paddingLeft - paddingRight;

  const rootLayoutId = useSelector((state) =>
    selectRootLayoutByDesign(state, designId)
  );

  let style = {
    width: canvas_width.toString() + "px",
    height: canvas_height.toString() + "px",
    backgroundColor: design.color || "white",
    borderStyle: design.frame==="outer"?"none":"solid",
    borderWidth: "1px",
    borderColor: "grey",
    position: "relative",
    overflow: "hidden",
    margin: "auto",
  };

  return (
    <div style={style} data-testid="_design_" ref={designFrame}>
      <Frame
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
        height={canvas_height}
        width={canvas_width}
        frametype={design.frame}
        color={design.textColor}
        designId={designId}
        designLoaded={designLoaded}
      />
      <Layout
        layoutHeight={height_rootLayout}
        layoutWidth={width_rootLayout}
        layoutId={rootLayoutId}
        layoutPosX={paddingLeft}
        layoutPosY={paddingTop}
        devMode={devMode}
        editable={editable}
        designLoaded={designLoaded}
      />
    </div>
  );
}

Design.propTypes = {
  width: PropTypes.number,
  designId: PropTypes.string,
};

export default Design;
