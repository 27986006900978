import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  createSelectStaticAreaTexts,
  createSelectStaticAreaPaths,
  selectStaticAreaDesignId,
} from "./staticAreaSlice";

import { textType } from "../../../assets/designDataTypes";

import {
  createSelectDesignById,
  updateSelectedText,
  updateSelectedTextType,
} from "./../Design/designSlice";

import Pathdrawer from "../Pathdrawer/";
import Textframe from "../Textframe/";

function StaticArea({
  staticAreaId = "",
  staticAreaHeight = 30,
  staticAreaWidth = 30,
  staticAreaPosX = 0,
  staticAreaPosY = 0,
  staticAreaMinHeight = null,
  devMode = false,
  setSpace: setSpaceParent = (id, space) => {},
  editable = true,
  designLoaded = false,
}) {
  // Stuff to determine the Area size (to later calculate the real positions for pdf creation)
  const staticAreaRef = useRef(null);

  function getAreaRect() {
    let { left, right, top, bottom } =
      staticAreaRef.current.getBoundingClientRect() ?? {};
    left = left + window.pageXOffset;
    right = right + window.pageXOffset;
    top = top + window.pageYOffset;
    bottom = bottom + window.pageYOffset;

    return { left, right, top, bottom };
  }

  const dispatch = useDispatch();

  /* 
    Generate and Memoize redux selectors. This is needed to memoize the state although 
    memoized selector is used by multiple components, with their individual props. 
    For Details see 
    https://react-redux.js.org/next/api/hooks#using-memoizing-selectors  
    (Last section)
  */
  const selectDesignById = useMemo(createSelectDesignById, []);
  const selectStaticAreaTexts = useMemo(createSelectStaticAreaTexts, []);
  const selectStaticAreaPaths = useMemo(createSelectStaticAreaPaths, []);

  useEffect(() => {
    if (staticAreaMinHeight === null) return;

    setSpaceParent(staticAreaId, staticAreaHeight - staticAreaMinHeight);
  }, [staticAreaHeight, staticAreaMinHeight, staticAreaId, setSpaceParent]);

  const pathDrawers = useSelector((state) =>
    selectStaticAreaPaths(state, staticAreaId)
  );
  const textFrames = useSelector((state) =>
    selectStaticAreaTexts(state, staticAreaId)
  );
  const designId = useSelector((state) =>
    selectStaticAreaDesignId(state, staticAreaId)
  );
  const design = useSelector((state) => selectDesignById(state, designId));

  function setTextPrintArea(id, rect) {}

  function onTextClicked(id) {
    const textId = id !== design?.selectedTextId ? id : "";
    const textTypeOfClickedFrame = textFrames.find((frame) => {
      return id === frame.textId;
    })?.textType;
    const textType =
      textTypeOfClickedFrame !== design?.selectedTextType
        ? textTypeOfClickedFrame
        : "";
    dispatch(updateSelectedText(designId, textId));
    dispatch(updateSelectedTextType(designId, textType));
  }

  function setOverflow(overflow) {}

  function addTextframe(textData) {
    let fontFamily;
    let fontSize;
    let highlightFontFamily = null;
    let highlightFontSize = null;
    let icon = null;
    switch (textData.textType) {
      case textType.name:
        fontFamily = design?.nameFontFamily;
        fontSize = design?.nameFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        break;
      case textType.birthdate:
        fontFamily = design?.dateFontFamily;
        fontSize = design?.dateFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        icon = design?.dateIcon;
        break;
      case textType.deathdate:
        fontFamily = design?.dateFontFamily;
        fontSize = design?.dateFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        icon = design?.dateIcon?.toUpperCase();
        break;
      default:
        fontFamily = design?.standardFontFamily;
        fontSize = design?.standardFontSize;
        highlightFontFamily = design?.highlightFontFamily;
        highlightFontSize = design?.highlightFontSize;
    }
    const lineHeight = textData.textType === textType.name ? 1 : 1.5;
    const active = textData.textId === design?.selectedTextId;
    return (
      <Textframe
        devMode={devMode}
        key={textData.textId}
        id={textData.textId}
        width={textData.width}
        height={textData.height}
        textAlign={textData.horizontalAlign}
        posX={textData.posX}
        posY={textData.posY}
        fontSize={fontSize}
        fontFamily={fontFamily}
        textColor={design?.textColor}
        highlightFontSize={highlightFontSize}
        highlightFontFamily={highlightFontFamily}
        setOverflow={setOverflow}
        onClick={onTextClicked}
        active={active}
        setPrintArea={setTextPrintArea}
        textPrintAreaResetter={false}
        areaWidth={staticAreaWidth}
        areaHeight={staticAreaHeight}
        designHeight={design?.pxHeight}
        textVerticalAlign={textData.verticalAlign}
        lineHeight={lineHeight}
        icon={icon}
        textType={textData.textType}
        editable={editable}
        designLoaded={designLoaded}
      />
    );
  }

  function addPath(pathData) {
    return (
      <Pathdrawer
        devMode={devMode}
        key={pathData.pathId}
        id={pathData.pathId}
        width={pathData.width}
        height={pathData.height}
        posX={pathData.posX}
        posY={pathData.posY}
        rotation={pathData.rotation}
        color={design?.pathColor}
        movePath={pathData.move}
        maxMove={pathData.maxMove}
        areaWidth={staticAreaWidth}
        areaHeight={staticAreaHeight}
        mainDesign={pathData.mainDesign}
        getAreaRect={getAreaRect}
        editable={editable}
      />
    );
  }

  const staticAreaStyle = {
    width: staticAreaWidth.toString() + "px",
    height: staticAreaHeight.toString() + "px",
    top: staticAreaPosY.toString() + "px",
    left: staticAreaPosX.toString() + "px",
    borderStyle: devMode ? "solid" : "none",
    borderWidth: devMode ? "1px" : "0px",
    borderColor: "black",
    position: "absolute",
    overflow: "visible",
    margin: "0px",
    padding: "0px",
  };

  return (
    <div
      style={staticAreaStyle}
      ref={staticAreaRef}
      data-testid={`_staticArea_${staticAreaId}`}
    >
      {textFrames && textFrames.map(addTextframe)}
      {pathDrawers && pathDrawers.map(addPath)}
    </div>
  );
}

StaticArea.propTypes = {
  staticAreaId: PropTypes.string,
  staticAreaHeight: PropTypes.number,
  staticAreaWidth: PropTypes.number,
  staticAreaPosX: PropTypes.number,
  staticAreaPosY: PropTypes.number,
  devMode: PropTypes.bool,
  setSpace: PropTypes.func,
};

export default StaticArea;
