import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";

import "../../../App.css";
import "./ImageCarousel.css";

/*
  Props:
    images:
      Pass here an Array of Image Objects. An image object looks as follows:
      {
        url: [IMAGE URL],
        caption: [IMAGE CAPTION]
      }
      Leave "caption" out if no caption is needed

    interval:
      Pass a number to set the delay between two "auto change". Unit of interval is ms
      set interval="null" if auto-change is not wished

    size:
      with this you can change the size with standard css (e.g. "40%")
*/

function ImageCarousel(props) {
  function generateCarouselItem(image, index) {
    return (
      <Carousel.Item key={index}>
        <img
          className="d-block w-100"
          src={image.url}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          alt={image.caption}
        />
        <Carousel.Caption>
          {props.caption && <h3 className="image-caption">{image.caption}</h3>}
        </Carousel.Caption>
      </Carousel.Item>
    );
  }

  const size = {
    width: props.size ? props.size : "65%",
    height: "20%",
    backgroundImage: props.background ? "url(" + props.background + ")" : "",
    backgroundSize: "cover",
  };

  return (
    <Container fluid className="center">
      <Carousel
        style={size}
        className={"imageCarousel-size" + (props.images.length <= 1 ? " single" : "")}
        interval={props.interval}
        index={props.index}
        onSelect={props.handleSelect}
      >
        {props.images.map(generateCarouselItem)}
      </Carousel>
    </Container>
  );
}

export default ImageCarousel;
