// utils/GoogleAnalytics.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  const tracking_code = process.env.REACT_APP_GA_TRACKING_CODE;
  var disableStr = "ga-disable-" + tracking_code;

  if (document.cookie.indexOf(disableStr + "=true") > -1) {
    window[disableStr] = true;
  }

  const env = process.env || {};
  const isGAEnabled = !!env.REACT_APP_GA_TRACKING_CODE;

  if (isGAEnabled) {
    ReactGA.initialize(env.REACT_APP_GA_TRACKING_CODE, {
      debug: env.REACT_APP_GA_DEBUG === "true",
      ...options,
    });
  }

  return isGAEnabled;
};

function optOut() {
  console.log("OptOut gesetzt");
  const tracking_code = process.env.REACT_APP_GA_TRACKING_CODE;
  var disableStr = "ga-disable-" + tracking_code;
  document.cookie =
    disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
  window[disableStr] = true;
  alert("Opt-Out Cookie wurde erfolgreich gesetzt");
}

let exportStuff = {
  GoogleAnalytics,
  RouteTracker,
  init,
  optOut,
};

export default exportStuff;
