import React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";
import { Pagetitle } from "../HelperComponents/Pagetitle";

import "../App.css";
import "../Pages_Footer/FooterPages.css";
import Collapser from "../HelperComponents/Collapser/";

function TextIdeas() {
  return (
    <Container fluid className="content-container">
      <Pagetitle
        title="Anleitung"
        description="Nutzen Sie unsere Anleitung und unsere Mustertexte, falls Sie unterstützung benötigen."
      />

      <Container className="section-with-light-colored-background padded">
        <h1 className="section-heading">
          <FormattedMessage id="textideas.title" />
        </h1>
        <p>
          <FormattedMessage id="textideas.description" />
        </p>
        <img
          style={{
            width: "80%",
            margin: "30px 10% ",
            outlineWidth: "1px",
            outlineStyle: "solid",
            outlineColor: "grey",
          }}
          src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/instruction/Anleitung_zur_Anzeigenerstellung.png"
          alt="Anleitung zur Anzeigenerstellung"
        />
        <p>
          <br />
          <FormattedMessage id="textideas.hint" />
          <br />
        </p>
        <Collapser
          question={<FormattedMessage id="textideas.oneliner.title" />}
          answer={
            <ul>
              <li>
                <FormattedMessage id="textideas.oneliner.text01" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text02" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text03" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text04" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text05" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text06" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text07" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text08" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text09" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text10" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text11" />
              </li>
              <li>
                <FormattedMessage id="textideas.oneliner.text12" />
              </li>
            </ul>
          }
        />

        <Collapser
          question={<FormattedMessage id="textideas.address.title" />}
          answer={
            <ul>
              <li>
                <FormattedMessage id="textideas.address.text01" />
              </li>
              <li>
                <FormattedMessage id="textideas.address.text02" />
              </li>
              <li>
                <FormattedMessage id="textideas.address.text03" />
              </li>
              <li>
                <FormattedMessage id="textideas.address.text04" />
              </li>
            </ul>
          }
        />
        <Collapser
          question={<FormattedMessage id="textideas.outro.title" />}
          answer={
            <div>
              <p>Sie können die Vorschläge beliebig kombinieren</p>
              <ul>
                <li>
                  <FormattedMessage id="textideas.outro.text01" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text02" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text03" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text04" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text05" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text06" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text07" />
                </li>
                <li>
                  <FormattedMessage id="textideas.outro.text08" />
                </li>
              </ul>
            </div>
          }
        />
      </Container>
    </Container>
  );
}

export default TextIdeas;
