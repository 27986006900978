import React from "react";

function preparePxNumber(number) {
  return number.toString() + "px";
}

function DevFrame({ left, right, top, bottom }) {
  
  const widthOnCanvas = right - left;
  const heightOnCanvas = bottom - top;
  const posXOnCanvas = left;
  const posYOnCanvas = top;

  const width = preparePxNumber(widthOnCanvas);
  const height = preparePxNumber(heightOnCanvas);

  const posX = preparePxNumber(posXOnCanvas);
  const posY = preparePxNumber(posYOnCanvas);

  const divStyle = {
    position: "absolute",
    width: width,
    height: height,
    left: posX,
    top: posY,
    borderStyle: "solid",
    borderColor: "red",
    borderWidth: "1px",
    backgroundColor: "#ff000055",
  };

  return <div style={divStyle}></div>;
}

export default DevFrame;
