import React, { useMemo, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  createSelectTextArea,
  createSelectTextAreaPaths,
  selectTextAreaDesignId,
} from "./textAreaSlice";

import {
  createSelectDesignById,
  updateSelectedText,
  updateSelectedTextType,
} from "./../Design/designSlice";

import { textType } from "../../../assets/designDataTypes";

import Pathdrawer from "../Pathdrawer/";
import Textframe from "../Textframe/";
import DevFrame from "../DevFrame/";

function TextArea({
  textAreaId = "",
  textAreaHeight = 30, // in px
  textAreaWidth = 30,
  textAreaPosX = 0,
  textAreaPosY = 0,
  devMode = false,
  setSpace: setSpaceParent = (id, space) => true,
  editable = true,
  designLoaded = false,
}) {
  /* 
    Generate and Memoize redux selectors. This is needed to memoize the state although 
    memoized selector is used by multiple components, with their individual props. 
    For Details see 
    https://react-redux.js.org/next/api/hooks#using-memoizing-selectors  
    (Last section)
  */

  const selectTextAreaPaths = useMemo(createSelectTextAreaPaths, []);
  const selectTextArea = useMemo(createSelectTextArea, []);
  const selectDesignById = useMemo(createSelectDesignById, []);

  const currentSpace = useRef(-10000);
  const textAreaRef = useRef(null);

  const dispatch = useDispatch();
  const paths =
    useSelector((state) => selectTextAreaPaths(state, textAreaId)) ?? [];
  const textFrame = useSelector((state) => selectTextArea(state, textAreaId));
  const designId = useSelector((state) =>
    selectTextAreaDesignId(state, textAreaId)
  );
  const design = useSelector((state) => selectDesignById(state, designId));

  function onTextClicked(id) {
    const textId = id !== design?.selectedTextId ? id : "";
    const textType =
      textFrame.textType !== design?.selectedTextType ? textFrame.textType : "";
    dispatch(updateSelectedText(designId, textId));
    dispatch(updateSelectedTextType(designId, textType));
  }

  function setSpace(space) {
    setSpaceParent(textAreaId, space);
    currentSpace.current = space;
    return true;
  }

  // Stuff for Reposition the paths ------------

  const [textAreaRect, setTextAreaRect] = useState({
    left: 0,
    right: 1,
    top: 0,
    bottom: 1,
  });

  // printArea is set by "Textframe" and passed to every Path to check for collisions
  const [printArea, setPrintArea] = useState({});

  useEffect(() => {
    // here we calculate the frame of the textarea, where it's actually displayed on screen
    // it is needed for calculating, where exactly the text is located (for later checking whether it collides)
    // with paths. The result of this calculation is stored in "textAreaRect" and passed to "TextFrame".
    // To get the absolute positions (not relative to scrolling) we need to add the scroll position to the values (window.pageXOffset)

    if (paths.length <= 0) return;
    if (!textAreaRef.current) return;

    setTextAreaRect(getAreaRect());
  }, [textAreaId, textAreaHeight, textAreaWidth, textAreaPosX, textAreaPosY, paths.length]);

  function getAreaRect() {
    let { left, right, top, bottom } =
      textAreaRef.current.getBoundingClientRect() ?? {};
    left = left + window.pageXOffset;
    right = right + window.pageXOffset;
    top = top + window.pageYOffset;
    bottom = bottom + window.pageYOffset;

    return { left, right, top, bottom };
  }

  // End of stuff for Reposition the paths ------------

  function addTextframe(textData) {
    let fontFamily;
    let fontSize;
    let highlightFontFamily = null;
    let highlightFontSize = null;
    let icon = null;
    switch (textData.textType) {
      case textType.name:
        fontFamily = design?.nameFontFamily;
        fontSize = design?.nameFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        break;
      case textType.birthdate:
        fontFamily = design?.dateFontFamily;
        fontSize = design?.dateFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        icon = design?.dateIcon;
        break;
      case textType.deathdate:
        fontFamily = design?.dateFontFamily;
        fontSize = design?.dateFontSize;
        highlightFontFamily = fontFamily;
        highlightFontSize = fontSize;
        icon = design?.dateIcon?.toUpperCase();
        break;
      default:
        fontFamily = design?.standardFontFamily;
        fontSize = design?.standardFontSize;
        highlightFontFamily = design?.highlightFontFamily;
        highlightFontSize = design?.highlightFontSize;
    }
    const active = textData.textId === design?.selectedTextId;
    const lineHeight = textData.textType === textType.name ? 1 : 1.5;

    // the margins of the Text-Frame are relative to the complete size of the design
    // (not to the size of text frame) therefore some calculations need to be made, to correctly
    // set the positions of the textframe. The "destination-unit" of all the calculations
    // is the "relative-unit" (0-1000) of the textArea.
    const heightScale = (design?.pxHeight ?? 20) / textAreaHeight; // --> calculate the relation between design-size and text-area size
    const widthScale = (design?.pxWidth ?? 20) / textAreaWidth;
    const posX = widthScale * textData.marginLeft; // --> pos = 0px + margin(textFrame)
    const posY = heightScale * textData.marginTop; //     margin(textFrame) = margin(design) * relation(design/textFrame)
    const width = 1000 - posX - widthScale * textData.marginRight; //
    const height = 1000 - posY - heightScale * textData.marginBottom;
    return (
      <Textframe
        devMode={devMode}
        key={textData.textId}
        id={textData.textId}
        width={width}
        height={height}
        textAlign={textData.horizontalAlign}
        textVerticalAlign={textData.verticalAlign}
        posX={posX}
        posY={posY}
        fontSize={fontSize}
        fontFamily={fontFamily}
        textColor={design?.textColor}
        highlightFontSize={highlightFontSize}
        highlightFontFamily={highlightFontFamily}
        setOverflow={setSpace}
        onClick={onTextClicked}
        active={active}
        setPrintArea={setPrintArea}
        textPrintAreaResetter={false}
        areaWidth={textAreaWidth}
        areaHeight={textAreaHeight}
        areaRect={textAreaRect}
        designHeight={design?.pxHeight}
        collisionDetection={paths.length > 0}
        currentSpace={currentSpace.current}
        canUseQuote={textData.textType === "poem"}
        lineHeight={lineHeight}
        icon={icon}
        textType={textData.textType}
        editable={editable}
        designLoaded={designLoaded}
      />
    );
  }

  function addPath(pathData, key) {
    return (
      <Pathdrawer
        devMode={devMode}
        key={`${pathData.pathId}_${key}`}
        id={pathData.pathId}
        width={pathData.width}
        height={pathData.height}
        posX={pathData.posX}
        posY={pathData.posY}
        rotation={pathData.rotation}
        color={design?.pathColor}
        areaWidth={textAreaWidth}
        areaHeight={textAreaHeight}
        // data for collision detection
        collisionDetection={paths.length > 0}
        textPrintArea={printArea}
        movePath={pathData.move}
        maxMove={pathData.maxMove}
        frame={pathData.frame}
        mainDesign={pathData.mainDesign}
        getAreaRect={getAreaRect}
        editable={editable}
      />
    );
  }

  const textAreaStyle = {
    width: textAreaWidth.toString() + "px",
    height: textAreaHeight.toString() + "px",
    top: textAreaPosY.toString() + "px",
    left: textAreaPosX.toString() + "px",
    borderStyle: devMode ? "solid" : "none",
    borderWidth: devMode ? "1px" : "0px",
    borderColor: "black",
    position: "absolute",
    overflow: "visible",
    margin: "0px",
    padding: "0px",
  };

  return (
    <div
      style={textAreaStyle}
      ref={textAreaRef}
      data-testid={`_textArea_${textAreaId}`}
    >
      {textFrame && addTextframe(textFrame)}
      {paths && paths.map(addPath)}
      {devMode &&
        printArea.lineRects &&
        printArea.lineRects.map((printZone) => {
          return (
            <DevFrame
              left={printZone.left}
              right={printZone.right}
              top={printZone.top}
              bottom={printZone.bottom}
            />
          );
        })}
    </div>
  );
}

TextArea.propTypes = {
  textAreaId: PropTypes.string,
  textAreaHeight: PropTypes.number,
  textAreaWidth: PropTypes.number,
  textAreaPosX: PropTypes.number,
  textAreaPosY: PropTypes.number,
  devMode: PropTypes.bool,
  setSpace: PropTypes.func,
};

export default TextArea;
