import React from "react";
import { Container } from "react-bootstrap";
import { Pagetitle } from "../HelperComponents/Pagetitle";

import "../App.css";
import "./FooterPages.css";
import GA from "../HelperComponents/GoogleAnalytics";

function DataProtection() {

  return (
    <Container fluid className="content-container">
      <Pagetitle
        title="Datenschutz"
        description="Hier finden Sie die Datenschutzrichtlinien von jungeTrauer.de"
      />
      <Container className="section-with-light-colored-background padded">
        <h2 className="section-heading">
          <big>Datenschutzerklärung</big>
        </h2>
        <h3 className="content-heading">
          Allgemeiner Hinweis und Pflichtinformationen
        </h3>
        <h4 className="sub-content-heading">
          Benennung der verantwortlichen Stelle
        </h4>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          <span id="s3-t-firma">jungeTrauer</span>
          <br />
          <span id="s3-t-ansprechpartner">Nikolai Merz</span>
          <br />
          <span id="s3-t-strasse">Störzbachstr. 1</span>
          <br />
          <span id="s3-t-plz">70191</span> <span id="s3-t-ort">Stuttgart</span>
        </p>
        <p></p>
        <p>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>

        <h4 className="sub-content-heading">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h4>
        <p>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>

        <h4 className="sub-content-heading">
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </h4>
        <p>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>

        <h4 className="sub-content-heading">Recht auf Datenübertragbarkeit</h4>
        <p>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>

        <h4 className="sub-content-heading">
          Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        </h4>
        <p>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </p>

        <h4 className="sub-content-heading">SSL- bzw. TLS-Verschlüsselung</h4>
        <p>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>

        <h3 className="content-heading">Server-Log-Dateien</h3>
        <p>
          In Server-Log-Dateien erhebt und speichert der Provider der Website
          automatisch Informationen, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul>
          <li>Besuchte Seite auf unserer Domain</li>
          <li>Datum und Uhrzeit der Serveranfrage</li>
          <li>Browsertyp und Browserversion</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>IP-Adresse</li>
        </ul>
        <p></p>
        <p>
          Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
          statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b
          DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
          oder vorvertraglicher Maßnahmen gestattet.
        </p>

        <h3 className="content-heading">
          Datenübermittlung bei Vertragsschluss für Warenkauf und Warenversand
        </h3>
        <p>
          Personenbezogene Daten werden an Dritte nur übermittelt, sofern eine
          Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte können
          beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine
          weitergehende Übermittlung der Daten findet nicht statt bzw. nur dann,
          wenn Sie dieser ausdrücklich zugestimmt haben.
        </p>
        <p>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>

        <h3 className="content-heading">Registrierung auf dieser Website</h3>
        <p>
          Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website
          registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke
          der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der
          Registrierung abgefragte Pflichtangaben sind vollständig anzugeben.
          Andernfalls werden wir die Registrierung ablehnen.
        </p>
        <p>
          Im Falle wichtiger Änderungen, etwa aus technischen Gründen,
          informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse
          versendet, die bei der Registrierung angegeben wurde.
        </p>
        <p>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein
          Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich.
          Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
          Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom
          Widerruf unberührt.
        </p>
        <p>
          Wir speichern die bei der Registrierung erfassten Daten während des
          Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten
          werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>

        <h3 className="content-heading">Kontaktformular</h3>
        <p>
          Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
          Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
          für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
          findet ohne Ihre Einwilligung nicht statt.
        </p>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist
          jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung
          per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <p>
          Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
          Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
          Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen
          - bleiben unberührt.
        </p>

        <h3 className="content-heading">
          Speicherdauer von Beiträgen und Kommentaren
        </h3>
        <p>
          Beiträge und Kommentare sowie damit in Verbindung stehende Daten, wie
          beispielsweise IP-Adressen, werden gespeichert. Der Inhalt verbleibt
          auf unserer Website, bis er vollständig gelöscht wurde oder aus
          rechtlichen Gründen gelöscht werden musste.
        </p>
        <p>
          Die Speicherung der Beiträge und Kommentare erfolgt auf Grundlage
          Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer
          bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf
          genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit bereits
          erfolgter Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>

        <h3 className="content-heading">Cookies</h3>
        <p>
          Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr
          Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei,
          unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.{" "}
        </p>
        <p>
          Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende
          Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere
          Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen.
          Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website
          wiederzuerkennen.
        </p>
        <p>
          Mit einem modernen Webbrowser können Sie das Setzen von Cookies
          überwachen, einschränken oder unterbinden. Viele Webbrowser lassen
          sich so konfigurieren, dass Cookies mit dem Schließen des Programms
          von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine
          eingeschränkte Funktionalität unserer Website zur Folge haben.
        </p>
        <p>
          Das Setzen von Cookies, die zur Ausübung elektronischer
          Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
          haben wir ein berechtigtes Interesse an der Speicherung von Cookies
          zur technisch fehlerfreien und reibungslosen Bereitstellung unserer
          Dienste. Sofern die Setzung anderer Cookies (z.B. für
          Analyse-Funktionen) erfolgt, werden diese in dieser
          Datenschutzerklärung separat behandelt.
        </p>

        <h3 className="content-heading">Google Analytics</h3>
        <p>
          Unsere Website verwendet Funktionen des Webanalysedienstes Google
          Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p>
          Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die
          Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der
          Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen
          über Ihre Benutzung unserer Website werden an einen Server von Google
          übermittelt und dort gespeichert. Server-Standort ist im Regelfall die
          USA.
        </p>
        <p>
          Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir  ein
          berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser
          Webangebot und ggf. auch Werbung zu optimieren.
        </p>
        <h4 className="sub-content-heading">IP-Anonymisierung</h4>
        <p>
          Wir setzen Google Analytics in Verbindung mit der Funktion
          IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in
          denen Google die volle IP-Adresse an einen Server in den USA überträgt
          und dort kürzt. In unserem Auftrag wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
          Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung
          und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu
          erbringen. Es findet keine Zusammenführung der von Google Analytics
          übermittelten IP-Adresse mit anderen Daten von Google statt.
        </p>
        <h4 className="sub-content-heading">Browser Plugin</h4>
        <p>
          Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige
          Funktionen unserer Website könnten dadurch jedoch eingeschränkt
          werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer
          Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender
          Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das
          über folgenden Link erreichbare Browser-Plugin herunterladen und
          installieren:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <h4 className="sub-content-heading">
          Widerspruch gegen die Datenerfassung
        </h4>
        <p>
          Sie können die Erfassung Ihrer Daten durch Google Analytics
          verhindern, indem Sie auf folgenden Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
          Besuchen unserer Website verhindert: <span style={{color:"#007bff", cursor:"pointer"}} onClick={GA.optOut}>Google Analytics deaktivieren.</span>
        </p>
        <p>
          Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden
          Sie in der Datenschutzerklärung von Google:{" "}
          <a href="https://support.google.com/analytics/answer/6004245?hl=de">
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h4 className="sub-content-heading">Auftragsverarbeitung</h4>
        <p>
          Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben
          wir mit Google einen Vertrag über die Auftragsverarbeitung
          abgeschlossen.
        </p>
        <h4 className="sub-content-heading">
          Demografische Merkmale bei Google Analytics
        </h4>
        <p>
          Unsere Website verwendet die Funktion “demografische Merkmale” von
          Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen
          zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten.
          Diese Daten stammen aus interessenbezogener Werbung von Google sowie
          aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu
          einer bestimmten Person ist nicht möglich. Sie können diese Funktion
          jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in
          Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten
          durch Google Analytics, wie im Punkt “Widerspruch gegen die
          Datenerfassung” erläutert, generell untersagen.
        </p>

        <h3 className="content-heading">PayPal</h3>
        <p>
          Unsere Website ermöglicht die Bezahlung via PayPal. Anbieter des
          Bezahldienstes ist die PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24
          Boulevard Royal, L-2449 Luxembourg.
        </p>
        <p>
          Wenn Sie mit PayPal bezahlen, erfolgt eine Übermittlung der von Ihnen
          eingegebenen Zahlungsdaten an PayPal.
        </p>
        <p>
          Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
          (Verarbeitung zur Erfüllung eines Vertrags). Ein Widerruf Ihrer
          bereits erteilten Einwilligung ist jederzeit möglich. In der
          Vergangenheit liegende Datenverarbeitungsvorgänge bleiben bei einem
          Widerruf wirksam.
        </p>

        <h3 className="content-heading">Google Web Fonts</h3>
        <p>
          Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google
          Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p>
          Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
          gewünschte Darstellung unserer Website zu präsentieren, unabhängig
          davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt
          über den Abruf der Google Web Fonts von einem Server von Google in den
          USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei
          handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns
          besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage
          von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir
          ein berechtigtes Interesse an der optimalen Darstellung und
          Übertragung unseres Webauftritts.
        </p>
        <p>
          Das Unternehmen Google ist für das us-europäische
          Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
          Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden
          Datenschutzniveaus gewährleisten.
        </p>
        <p>
          Einzelheiten über Google Web Fonts finden Sie unter:{" "}
          <a href="https://www.google.com/fonts#AboutPlace:about">
            https://www.google.com/fonts#AboutPlace:about
          </a>{" "}
          und weitere Informationen in den Datenschutzbestimmungen von Google:{" "}
          <a href="https://policies.google.com/privacy/partners?hl=de">
            https://policies.google.com/privacy/partners?hl=de
          </a>
        </p>
        <h3 className="content-heading">BootstrapCDN</h3>
        <p>
          Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website)
          auf allen Geräten schnell und sicher ausliefern zu können, nutzen wir
          das Content Delivery Network (CDN) BootstrapCDN des amerikanischen
          Software-Unternehmens StackPath, LLC 2012 McKinney Ave. Suite 1100,
          Dallas, TX 75201, USA.
        </p>
        <p>
          Ein Content Delivery Network (CDN) ist ein Netzwerk regional
          verteilter Server, die über das Internet miteinander verbunden sind.
          Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien,
          auch bei großen Lastspitzen schnell ausgeliefert werden.
        </p>
        <p>
          BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken
          an Ihren Browser ausgeliefert werden. Lädt nun Ihr Browser eine Datei
          vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung
          zum Bootstrap-CDN-Server an das Unternehmen StockPath übermittelt.
        </p>
        <p>
          StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass
          das Unternehmen aggregierte und anonymisierte Daten von diversen
          Diensten (wie BootstrapCDN) für die Erweiterung der Sicherung und für
          andere StackPath-Dienste und Clients verwenden. All diese Daten können
          aber keine Person identifizieren.
        </p>
        <p>
          Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen
          JavaScript-Blocker (siehe beispielsweise{" "}
          <a href="https://noscript.net/">noscript.net</a>) installieren oder in
          Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Bitte
          beachten Sie aber, dass dadurch die Website nicht mehr das gewohnte
          Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.
        </p>
        <p>
          StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework, wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active">
            www.privacyshield.gov/participant
          </a>
          .
        </p>
        <p>
          Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN
          finden Sie auf{" "}
          <a href="https://www.bootstrapcdn.com/privacy-policy/">
            www.bootstrapcdn.com/privacy-policy/
          </a>
          .
        </p>
        <h3 className="content-heading">Google reCAPTCHA</h3>
        <p>
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
          bestmöglich zu sichern und zu schützen. Um das zu gewährleisten,
          verwenden wir Google reCAPTCHA der Firma Google Inc. Für den
          europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
          House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
          verantwortlich. Mit reCAPTCHA können wir feststellen, ob Sie auch
          wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder
          eine andere Spam-Software. Unter Spam verstehen wir jede, auf
          elektronischen Weg, unerwünschte Information, die uns ungefragter
          Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur
          Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von
          Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier
          reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen
          und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible
          reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie
          das genau funktioniert und vor allem welche Daten dafür verwendet
          werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.
        </p>

        <h4 className="sub-content-heading">Was ist reCAPTCHA?</h4>
        <p>
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
          Spam-Software und den Missbrauch durch nicht-menschliche Besucher
          schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie
          Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
          automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
          im Internet von einem Menschen und nicht von einem Bot vorgenommen
          wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan
          Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch
          fest. Bei Captchas übernimmt das auch der Computer bzw. ein
          Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben,
          die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche
          Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel
          mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
          von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich
          bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst
          das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den
          Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet
          die Software einen sogenannten Captcha-Score. Google berechnet mit
          diesem Score schon vor der Captcha-Eingabe wie hoch die
          Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw.
          Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots
          gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
          manipulieren oder missbrauchen könnten.
        </p>
        <h4 className="sub-content-heading">
          Warum verwenden wir reCAPTCHA auf unserer Webseite?
        </h4>
        <p>
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite
          begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen
          getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns
          zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
          anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma
          Google. So können wir uns ziemlich sicher sein, dass wir eine
          „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden
          Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich
          ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite
          und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte
          es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot
          möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren
          oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA
          können wir solche Botangriffe vermeiden.
        </p>
        <h4 className="sub-content-heading">
          Welche Daten werden von reCAPTCHA gespeichert?
        </h4>
        <p>
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen,
          ob die Handlungen auf unserer Webseite auch wirklich von Menschen
          stammen. Es kann also die IP-Adresse und andere Daten, die Google für
          den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen
          werden innerhalb der Mitgliedstaaten der EU oder anderer
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA
          landen. Die IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit
          Ihrem Google-Konto angemeldet sind. Zuerst prüft der
          reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von
          anderen Google-Diensten (YouTube. Gmail usw.) platziert sind.
          Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser
          und erfasst einen Schnappschuss Ihres Browserfensters.
        </p>
        <p>
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht
          den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von
          Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
        </p>
        <ul>
          <li>
            Referrer URL (die Adresse der Seite von der der Besucher kommt)
          </li>
          <li>IP-Adresse (z.B. 256.123.123.1)</li>
          <li>
            Infos über das Betriebssystem (die Software, die den Betrieb Ihres
            Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS
            X oder Linux)
          </li>
          <li>
            Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
          </li>
          <li>
            Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder
            der Tastatur ausführen wird gespeichert)
          </li>
          <li>
            Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie
            auf Ihrem PC voreingestellt haben wird gespeichert)
          </li>
          <li>
            Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
            Webseiten ermöglicht, sich an den User anzupassen.
            JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)
          </li>
          <li>
            Bildschirmauflösung (zeigt an aus wie vielen Pixeln die
            Bilddarstellung besteht)
          </li>
        </ul>
        <p>
          Unumstritten ist, dass Google diese Daten verwendet und analysiert
          noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der
          ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
          Daten Google genau speichert, erfährt man von Google nicht im Detail.
        </p>
        <p>
          Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir
          uns auf die reCAPTCHA Demo-Version von Google unter{" "}
          <a
            href="https://www.google.com/recaptcha/api2/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/recaptcha/api2/demo
          </a>
          . All diese Cookies benötigen zu Trackingzwecken eine eindeutige
          Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
          Demo-Version gesetzt hat:
        </p>
        <p>
          <strong>Name:</strong> IDE
          <br />
          <strong>Wert:</strong>{" "}
          WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-311210799-8
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie wird von der Firma
          DoubleClick (gehört auch Google) gesetzt, um die Aktionen eines Users
          auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und zu
          melden. So kann die Werbewirksamkeit gemessen und entsprechende
          Optimierungsmaßnahmen getroffen werden. IDE wird in Browsern unter der
          Domain doubleclick.net gespeichert.
          <br />
          <strong>Ablaufdatum:</strong> nach einem Jahr
        </p>
        <p>
          <strong>Name:</strong> 1P_JAR
          <br />
          <strong>Wert:</strong> 2019-5-14-12
          <br />
          <strong>Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken
          zur Webseite-Nutzung und misst Conversions. Eine Conversion entsteht
          z.B., wenn ein User zu einem Käufer wird. Das Cookie wird auch
          verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann
          man mit dem Cookie vermeiden, dass ein User dieselbe Anzeige mehr als
          einmal zu Gesicht bekommt.
          <br />
          <strong>Ablaufdatum:</strong> nach einem Monat
        </p>
        <p>
          <strong>Name:</strong> ANID
          <br />
          <strong>Wert:</strong> U7j1v3dZa3112107990xgZFmiqWppRWKOr
          <br />
          <strong>Verwendungszweck:</strong> Viele Infos konnten wir über dieses
          Cookie nicht in Erfahrung bringen. In der Datenschutzerklärung von
          Google wird das Cookie im Zusammenhang mit „Werbecookies“ wie z. B.
          &#8220;DSID&#8221;, &#8220;FLC&#8221;, &#8220;AID&#8221;,
          &#8220;TAID&#8221; erwähnt. ANID wird unter Domain google.com
          gespeichert.
          <br />
          <strong>Ablaufdatum:</strong> nach 9 Monaten
        </p>
        <p>
          <strong>Name:</strong> CONSENT
          <br />
          <strong>Wert: </strong>
          YES+AT.de+20150628-20-0
          <br />
          <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der
          Zustimmung eines Users zur Nutzung unterschiedlicher Services von
          Google. CONSENT dient auch der Sicherheit, um User zu überprüfen,
          Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor
          unbefugten Angriffen zu schützen.
          <br />
          <strong>Ablaufdatum:</strong> nach 19 Jahren
        </p>
        <p>
          <strong>Name:</strong> NID
          <br />
          <strong>Wert:</strong> 0WmuWqy311210799zILzqV_nmt3sDXwPeM5Q
          <br />
          <strong>Verwendungszweck:</strong> NID wird von Google verwendet, um
          Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies
          „erinnert“ sich Google an Ihre meist eingegebenen Suchanfragen oder
          Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer
          maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige
          ID, um persönliche Einstellungen des Users für Werbezwecke zu sammeln.
          <br />
          <strong>Ablaufdatum:</strong> nach 6 Monaten
        </p>
        <p>
          <strong>Name:</strong> DV
          <br />
          <strong>Wert:</strong> gEAABBCjJMXcI0dSAAAANbqc311210799-4
          <br />
          <strong>Verwendungszweck:</strong> Sobald Sie das „Ich bin kein
          Roboter“-Häkchen angekreuzt haben, wird dieses Cookie gesetzt. Das
          Cookie wird von Google Analytics für personalisierte Werbung
          verwendet. DV sammelt Informationen in anonymisierter Form und wird
          weiters benutzt, um User-Unterscheidungen zu treffen.
          <br />
          <strong>Ablaufdatum:</strong> nach 10 Minuten
        </p>
        <p>
          <strong>Anmerkung: </strong>Diese Aufzählung kann keinen Anspruch auf
          Vollständigkeit erheben, da Google erfahrungsgemäß die Wahl ihrer
          Cookies immer wieder auch verändert.
        </p>
        <h4 className="sub-content-heading">
          Wie lange und wo werden die Daten gespeichert?
        </h4>
        <p>
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
          Google-Server übertragen. Wo genau diese Daten gespeichert werden,
          stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar.
          Ohne eine Bestätigung von Google erhalten zu haben, ist davon
          auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
          Webseite oder Spracheinstellungen auf den europäischen oder
          amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die
          Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit
          anderen Google-Daten aus weiteren Google-Diensten zusammengeführt.
          Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
          Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.
          <strong> </strong>Dafür gelten die abweichenden
          Datenschutzbestimmungen der Firma Google.
        </p>
        <h4 className="sub-content-heading">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h4>
        <p>
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
          Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
          ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
          Daten sobald Sie unsere Seite aufrufen automatisch an Google
          übermittelt. Um diese Daten wieder zu löschen, müssen Sie den
          Google-Support auf{" "}
          <a
            href="https://support.google.com/?hl=de&amp;tid=311210799"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/?hl=de&amp;tid=311210799
          </a>{" "}
          kontaktieren.
        </p>
        <p>
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich
          einverstanden, dass Google LLC und deren Vertreter automatisch Daten
          erheben, bearbeiten und nutzen.
        </p>
        <p>
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
          Google auf{" "}
          <a
            href="https://developers.google.com/recaptcha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/recaptcha/
          </a>
          . Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
          näher ein, doch genaue Informationen über Datenspeicherung und
          datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
          Übersicht über die grundsätzliche Verwendung von Daten bei Google
          finden Sie in der hauseigenen Datenschutzerklärung auf{" "}
          <a
            href="https://policies.google.com/privacy?hl=de&amp;tid=311210799"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>

        <p>
          <small>
            Quelle: Datenschutz-Konfigurator von{" "}
            <a
              href="http://www.mein-datenschutzbeauftragter.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              mein-datenschutzbeauftragter.de
            </a>{" "}
            und dem{" "}
            <a
              href="https://www.adsimple.de/datenschutz-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz Generator
            </a>{" "}
            von AdSimple in Kooperation mit 123familie.de
          </small>
        </p>
      </Container>
    </Container>
  );
}

export default DataProtection;
