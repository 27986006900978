import React from "react";
import PropTypes from "prop-types";
import FontSelector from "./../FontSelector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./FontStyleSetting.css";

const FontStyleSetting = ({
  onFontSizeSelect = () => {},
  onFontSelect = () => {},
  title = "Schriftart",
  fontValue = "Arial",
  sizeValue = 10,
  minSize = 1,
  maxSize = 20,
  withoutHighlight = false,
}) => {
  function onSizeChange(e) {
    const { value } = e.target;
    onFontSizeSelect(parseInt(value));
  }
  function onFontChange(selectedFont) {
    onFontSelect(selectedFont.value);
  }
  return (
    <div className="fontStyleArea" data-testid={`_font-style-setting_${title}`}>
      <Row className="align-items-center selectorRow">
        <Col xs="4" className="fontTitle" data-testid={`_font-title_${title}`}>{title}:</Col>
        <Col xs="8" data-testid={`_font-selector-container_${title}`}>
          <FontSelector
            data-testid={`_font-selector_${title}`}
            onChange={onFontChange}
            value={fontValue}
            withoutHighlight={withoutHighlight}
          />
        </Col>
      </Row>
      <div className="spaceSm"/>

      <Row className="align-items-center sliderRow" data-testid={`_fontsize-sliderRow`}>
        <Col xs="1" className="smallLetter" data-testid={`_fontsize-smallLetter_`}>
          A
        </Col>
        <Col xs="9" className="sliderContainer" data-testid={`_fontsize-sliderContainer_`}>
          <input
            type="range"
            title={`Schriftgröße der ${title} Schriftart`}
            min={minSize}
            max={maxSize}
            value={sizeValue}
            onChange={onSizeChange}
            className="slider"
            data-testid={`_fontsize-selector_${title}`}
          />
        </Col>
        <Col xs="1" className="largeLetter" data-testid={`_fontsize-largeLetter_`}>
          A
        </Col>
      </Row>
      <div className="spaceLg"/>
    </div>
  );
};

FontStyleSetting.propTypes = {
  onFontSelect: PropTypes.func,
  onFontSizeSelect: PropTypes.func,
  title: PropTypes.string,
  fontValue: PropTypes.string,
  sizeValue: PropTypes.number,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
};

export default FontStyleSetting;
