import React from "react";

import "./WaitButterfly.css";

function WaitButterfly(props) {
  return (
    <div className="wait-butterfly" style={props.style}>
      <img
        className={ props.active ? "wing1-animation wait-butterfly-wing1" : "wait-butterfly-wing1"}        
        src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
        alt="Schmetterling-Flügel"
      />
      <img
        className={ props.active ? "wing2-animation wait-butterfly-wing2" : "wait-butterfly-wing2"}     
        src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Fluegel.svg"
        alt="Schmetterling-Körper"
      />
      <img
        className="wait-butterfly-body"
        src="https://junge-trauer-website-assets.s3.eu-central-1.amazonaws.com/header/Schmetterling_Koerper.svg"
        alt="Schmetterling-Flügel"
      />
    </div>
  );
}

export default WaitButterfly;