import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

// REDUCER

const pathAdapter = createEntityAdapter();

const initialState = pathAdapter.getInitialState();

const pathSlice = createSlice({
  name: "paths",
  initialState,
  reducers: {
    deletePathsByDesignId(state, action) {
      const designId = action.payload;
      const idsToDelete = state.ids.filter((id) => (state.entities[id].design === designId));
      pathAdapter.removeMany(state, idsToDelete);
    },
    generatePathsWithDesign: {
      reducer(state, action) {
        pathAdapter.addMany(state, action.payload);
      },
      prepare(paths, designId) {
        const pathsWithDesignId = paths.map((path) => {
          return { ...path, design: designId };
        });
        return {
          payload:  pathsWithDesignId ,
        };
      },
    },
  },
});

export const {
  generatePathsWithDesign,
  deletePathsByDesignId,
} = pathSlice.actions;

export const pathSelectors = pathAdapter.getSelectors((state) => state.path);

export default pathSlice.reducer;
