import React, { useState, useRef } from "react";
import { useAlert } from "react-alert";

import WaitButterfly from "../Page_Contact/ContactForm/WaitButterfly/";
import { DisappearedLoading } from "react-loadingg";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Bowser from "bowser";

function Backend() {
  const browser = Bowser.parse(window.navigator.userAgent);
  let ua = navigator.userAgent || navigator.vendor || window.opera;
  const downloadLink = useRef(null);
  function downloadOrders(username, password) {
    fetch("/api/orders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${window.btoa(
          credentials.username + ":" + credentials.password
        )}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            let csv = jsonToCsvConverter(data.orders);
            var dataPacket = new Blob([csv], { type: "text/plain" });
            var url = window.URL.createObjectURL(dataPacket);
            downloadLink.current.href = url;
            downloadLink.current.style = { display: "block" };
            setWaitForBackendResponse(false);
          });
        } else {
          setWaitForBackendResponse(false);

          alert.show("Passwort oder Username falsch...", {
            title: "Fehler",
          });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setWaitForBackendResponse(false);
        alert.show("Es gab Probleme bei der Kommunikation mit dem Backend", {
          title: "Fehler",
        });
        console.log(error);
      });
  }

  function jsonToCsvConverter(json) {
    console.log(json);
    var fields = [
      "_id",
      "timestamp",
      "designid",
      "orderid",
      "invoiceid",
      "sum",
      "firstname",
      "lastname",
      "paymentmethod",
      "source",
    ];
    var replacer = function (key, value) {
      return value === null ? "" : value;
    };
    var csv = json.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    csv = csv.join("\r\n");
    return csv;
  }

  // Message Data to be sent to Backend
  const emptyCredentials = {
    username: "",
    password: "",
  };

  const [credentials, setCredentials] = useState(emptyCredentials);

  function onChange(event) {
    const { name, value } = event.target;
    setCredentials((prevMsg) => {
      return {
        ...prevMsg,
        [name]: value,
      };
    });
  }

  // Form validation
  const [validated, setValidated] = useState(false);

  const [waitForBackendResponse, setWaitForBackendResponse] = useState(false);
  // Form Submission
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setValidated(true);
      setWaitForBackendResponse(true);
      downloadOrders(credentials.username, credentials.password);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  // Alerts
  const alert = useAlert();

  return (
    <Container fluid>
      <Container
        fluid
        className="content-container section-with-colored-background"
        id="packages"
      >
        <p>Google-Analytics Code: {process.env.REACT_APP_GA_TRACKING_CODE}</p>
        <p>Browser Name: {browser.browser.name}</p>
        <p>Browser Version: {browser.browser.version}</p>
        <p>OS: {browser.os.name}</p>
        <p>platform: {browser.platform.type}</p>
        <p>User agent: {ua}</p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              onChange={onChange}
              value={credentials.username}
              type="text"
              name="username"
              placeholder="Username"
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Passwort</Form.Label>
            <Form.Control
              onChange={onChange}
              type="password"
              name="password"
              placeholder="Passwort"
              value={credentials.password}
              required
            />
          </Form.Group>

          <div
            style={{
              position: "relative",
              marginLeft: "20px",
              marginTop: "50px",
            }}
          >
            <Button
              variant="dark"
              type="submit"
              style={{
                width: "100px",
                height: "40px",
                zIndex: "5",
                position: "relative",
              }}
              disabled={waitForBackendResponse ? true : false}
            >
              {waitForBackendResponse ? (
                <DisappearedLoading
                  color="#ffffff"
                  style={{
                    position: "relative",
                    margin: "auto",
                    width: "50px",
                    height: "5px",
                  }}
                />
              ) : (
                "Einloggen"
              )}
            </Button>
            <WaitButterfly
              active={waitForBackendResponse ? true : false}
              style={{
                width: "50px",
                height: "50px",
                left: "-30px",
                top: "-36px",
              }}
            />
          </div>
        </Form>
        <a
          download="orders.csv"
          href="https://google.de"
          style={{ display: "none" }}
          ref={downloadLink}
        >
          Bestelldaten herunterladen
        </a>
      </Container>
    </Container>
  );
}

export default Backend;
