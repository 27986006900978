import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../../App.css";
import "../ProduktArea/productArea.css";
import ImageCarousel from "../../Page_Home/Motifs/ImageCarousel";
import { useHistory } from "react-router";

CondolenceCardArea.propTypes = {
  data: PropTypes.object,
  itemNo: PropTypes.number,
  product: PropTypes.string,
  motifName: PropTypes.string,
};

const productTitles = {
  condolence: "Beileidskarte",
};

const productDescriptions = {
  condolence:
    "Eine Beileidskarte, Kondolenzkarte oder Trauerkarte nutzen Freunde und Bekannte, um den Angehörigen darin ihr Mitgefühl auszudrücken.",
};

const ourServiceExternal = {
  condolence:
    "Bestellen Sie die Beileidskarte einzeln oder als Set in unserem PayPal-Shop.",
};

const ourServiceCustom = {
  condolence:
    "Dieses Motiv gibt es noch nicht als Beileidskarte im Shop zu bestellen. Falls Sie Interesse an Beileidskarten mit diesem Motiv haben, können Sie gern Kontakt mit uns aufnehmen.",
};

function CondolenceCardArea({
  data,
  itemNo = 0,
  product = "",
  motifName = "",
}) {
  const history = useHistory();
  const imageObjects = data.productImageURLs.map((url) => {
    return { url, caption: motifName + " " + productTitles["condolence"] };
  });
  function selectColorClass() {
    switch (itemNo % 4) {
      case 0:
        return " section-with-colored-background ";
      case 1:
        return " section-with-light-colored-background ";
      case 3:
        return " section-with-light-colored-background ";
      default:
        return "";
    }
  }

  function getServiceText() {
    if (data.externalLink) return ourServiceExternal;
    return ourServiceCustom;
  }

  function buttonClicked() {
    if (!data.externalLink)
      return history.push({ pathname: "/contact", state: getState() });
    return window.open(data.externalLink, "_blank");
  }

  function getState() {
    return { motif: motifName, contactReason: "custom_condolence" };
  }

  return (
    <Container
      fluid
      className={"content-container" + selectColorClass()}
      id={product}
    >
      <Container>
        <Row>
          <Col lg={itemNo % 2 === 0 ? { order: "last" } : { order: "first" }}>
            <h2 className="section-heading">{productTitles[product]}</h2>
            {productDescriptions[product]
              ?.split("\n")
              ?.map((description, key) => (
                <p key={`desc_${key}`}>{description}</p>
              ))}

            {getServiceText()
              [product]?.split("\n")
              ?.map((description, key) => (
                <p key={`service_${key}`} className="service-text">
                  {description}
                </p>
              ))}

            <hr />
            {data.externalLink && (
              <>
                <Row>
                  <Col lg="4" sm="3" className="no-padding-right">
                    <p className="product-price-heading">Unser Preis:</p>
                  </Col>
                  <Col lg="8" sm="9">
                    <p className="service-text">
                      1 Karte inkl. Briefumschlag: 3,50 €
                    </p>
                    <p className="service-text">
                      3 Karten inkl. Briefumschläge: 9 € (14 % Rabatt)
                    </p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            <Row>
              <Col>
                {" "}
                <Button
                  variant="dark"
                  type="submit"
                  className="order-button"
                  onClick={() => {
                    buttonClicked();
                  }}
                >
                  {data.externalLink ? "Karte bestellen" : "Kontakt aufnehmen"}
                </Button>
              </Col>
              {data.externalLink ? (
                <Col>
                  <Button
                    variant="dark"
                    type="submit"
                    className="order-button"
                    onClick={() => {
                      window.open(
                        "https://www.paypal.com/instantcommerce/checkout/QDVQAATTNE6P8",
                        "_blank"
                      );
                    }}
                  >
                    3er-Set bestellen
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
          <Col lg>
            <ImageCarousel images={imageObjects} interval={null} size="60%" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default CondolenceCardArea;
