import React, { useEffect, useRef } from "react";
import { scribble, single } from "./../../../assets/frames/frames";

import { selectDesignGenerator } from "./../Design/designSlice";
import { useDispatch, useSelector } from "react-redux";
import { addPath } from "../printerSlice";

function Frame({
  height = 200,
  width = 200,
  frametype = "none",
  color = "black",
  paddingTop = 20,
  paddingBottom = 20,
  paddingLeft = 20,
  paddingRight = 20,
  designId = "",
}) {
  // GENERATE FINAL DESIGN
  const dispatch = useDispatch();
  const designGenerator = useSelector((state) =>
    selectDesignGenerator(state, designId)
  );

  const designGeneratorMemo = useRef(false);

  useEffect(() => {
    if (!designGenerator) {
      designGeneratorMemo.current = false;
      return;
    }

    if (designGeneratorMemo === designGenerator) return;
    designGeneratorMemo.current = true;

    // Duplication of getFrameHeight and getFrameWidth to not violate useEffectRules

    function getFrameHeight(overshootFactor) {
      return (
        height - overshootFactor * paddingTop - overshootFactor * paddingBottom
      );
    }

    function getFrameWidth(overshootFactor) {
      return (
        width - overshootFactor * paddingLeft - overshootFactor * paddingRight
      );
    }

    let framePaths = {};
    let lineStrokeFactor = 0;
    let overshootFactor = 0;
    let padLeft = 0;
    let padRight = 0;
    let padTop = 0;
    let padBottom = 0;
    let frameHeight = 0;
    let frameWidth = 0;

    switch (frametype) {
      case "scribble":
        {
          const { left, right, top, bottom } = scribble;
          framePaths = {
            left: left,
            right: right,
            top: top,
            bottom: bottom,
          };
          lineStrokeFactor = width / 150;
          overshootFactor = 0.3;
          padLeft = paddingLeft;
          padRight = paddingRight;
          padTop = paddingTop;
          padBottom = paddingBottom;
          frameHeight = getFrameHeight(overshootFactor);
          frameWidth = getFrameWidth(overshootFactor);
        }
        break;
      case "single":
        {
          const { horizontal, vertical } = single;
          framePaths = {
            left: vertical,
            right: vertical,
            top: horizontal,
            bottom: horizontal,
          };
          lineStrokeFactor = width / 400;
          overshootFactor = 0.48;
          padLeft = paddingLeft;
          padRight = paddingRight;
          padTop = paddingTop;
          padBottom = paddingBottom;
          frameHeight = getFrameHeight(overshootFactor);
          frameWidth = getFrameWidth(overshootFactor);
        }
        break;
      case "outer":
        {
          const { horizontal, vertical } = single;
          framePaths = {
            left: vertical,
            right: vertical,
            top: horizontal,
            bottom: horizontal,
          };
          lineStrokeFactor = width / 300;
          overshootFactor = 0;
          padLeft = 0;
          padRight = 0;
          padTop = 0;
          padBottom = 0;
          frameHeight = height;
          frameWidth = width;
        }
        break;
      default:
        return;
    }

    const leftFrame = {
      pathId: "leftFrame",
      path: framePaths.left.path,
      viewBox: framePaths.left.viewBox,
      posX: left_left(framePaths.left, padLeft),
      posY: left_top(overshootFactor, padTop),
      width: framePaths.left.width * lineStrokeFactor,
      height: frameHeight,
      rotation: 0,
      frame: true,
    };

    const rightFrame = {
      pathId: "rightFrame",
      path: framePaths.right.path,
      viewBox: framePaths.right.viewBox,
      posX:
        width -
        right_right(framePaths.right, padRight) -
        framePaths.right.width * lineStrokeFactor,
      posY: right_top(overshootFactor, padTop),
      width: framePaths.right.width * lineStrokeFactor,
      height: frameHeight,
      rotation: 0,
      frame: true,
    };

    const topFrame = {
      pathId: "topFrame",
      path: framePaths.top.path,
      viewBox: framePaths.top.viewBox,
      posX: top_left(overshootFactor, padLeft),
      posY: top_top(framePaths.top, padTop),
      width: frameWidth,
      height: framePaths.top.height * lineStrokeFactor,
      rotation: 0,
      frame: true,
    };

    const bottomFrame = {
      pathId: "rightFrame",
      path: framePaths.bottom.path,
      viewBox: framePaths.bottom.viewBox,
      posX: bottom_left(overshootFactor, padLeft),
      posY:
        height -
        bottom_bottom(framePaths.bottom, padBottom) -
        framePaths.bottom.height * lineStrokeFactor,
      width: frameWidth,
      height: framePaths.bottom.height * lineStrokeFactor,
      rotation: 0,
      frame: true,
    };

    dispatch(addPath(leftFrame));
    dispatch(addPath(rightFrame));
    dispatch(addPath(topFrame));
    dispatch(addPath(bottomFrame));
  }, [
    designGenerator,
    dispatch,
    frametype,
    height,
    width,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  ]);
  // ----

  const style = {
    position: "absolute",
    left: "0px",
    top: "0px",
    height: `${height}px`,
    width: `${width}px`,
    fill: color,
  };

  const left_left = (pathLeft, padLeft) => padLeft / 2 - pathLeft.width / 2;
  const left_top = (overshootFactor, padTop) => overshootFactor * padTop;

  const right_right = (pathRight, padRight) =>
    padRight / 2 - pathRight.width / 2;
  const right_top = (overshootFactor, padTop) => overshootFactor * padTop;

  const top_left = (overshootFactor, padLeft) => overshootFactor * padLeft;
  const top_top = (pathTop, padTop) => padTop / 2 - pathTop.height / 2;

  const bottom_left = (overshootFactor, padLeft) => overshootFactor * padLeft;
  const bottom_bottom = (pathBottom, padBottom) =>
    padBottom / 2 - pathBottom.height / 2;

  function getFrameHeight(overshootFactor) {
    return (
      height - overshootFactor * paddingTop - overshootFactor * paddingBottom
    );
  }

  function getFrameWidth(overshootFactor) {
    return (
      width - overshootFactor * paddingLeft - overshootFactor * paddingRight
    );
  }

  function createFrameStyle(
    pathLeft,
    pathRight,
    pathTop,
    pathBottom,
    overshootFactor
  ) {
    const frameHeight = getFrameHeight(overshootFactor);
    const frameWidth = getFrameWidth(overshootFactor);
    const styleLeft = {
      position: "absolute",
      left: `${left_left(pathLeft, paddingLeft)}px`,
      top: `${left_top(overshootFactor, paddingTop)}px`,
    };
    const styleRight = {
      position: "absolute",
      right: `${right_right(pathRight, paddingRight)}px`,
      top: `${right_top(overshootFactor, paddingTop)}px`,
    };
    const styleTop = {
      position: "absolute",
      left: `${top_left(overshootFactor, paddingLeft)}px`,
      top: `${top_top(pathTop, paddingTop)}px`,
    };
    const styleBottom = {
      position: "absolute",
      left: `${bottom_left(overshootFactor, paddingLeft)}px`,
      bottom: `${bottom_bottom(pathBottom, paddingBottom)}px`,
    };
    return {
      frameHeight,
      styleLeft,
      styleRight,
      frameWidth,
      styleTop,
      styleBottom,
    };
  }

  function createFrame(
    pathLeft,
    pathRight,
    pathTop,
    pathBottom,
    overshootFactor = 0.3,
    lineStrokeFactor = 3
  ) {
    const {
      frameHeight,
      styleLeft,
      styleRight,
      frameWidth,
      styleTop,
      styleBottom,
    } = createFrameStyle(
      pathLeft,
      pathRight,
      pathTop,
      pathBottom,
      overshootFactor
    );
    return (
      <div style={style} data-testid="_frame_">
        <svg
          data-testid="_frameLine_left"
          width={`${pathLeft.width * lineStrokeFactor}px`}
          height={`${frameHeight}px`}
          preserveAspectRatio="none"
          viewBox={pathLeft.viewBox}
          style={styleLeft}
        >
          <path d={pathLeft.path} id="frame-left" />
        </svg>
        <svg
          data-testid="_frameLine_right"
          width={`${pathRight.width * lineStrokeFactor}px`}
          height={`${frameHeight}px`}
          preserveAspectRatio="none"
          viewBox={pathRight.viewBox}
          style={styleRight}
        >
          <path d={pathRight.path} id="frame-right" />
        </svg>
        <svg
          data-testid="_frameLine_top"
          width={`${frameWidth}px`}
          height={`${pathTop.height * lineStrokeFactor}px`}
          preserveAspectRatio="none"
          viewBox={pathTop.viewBox}
          style={styleTop}
        >
          <path d={pathTop.path} id="frame-top" />
        </svg>
        <svg
          data-testid="_frameLine_bottom"
          width={`${frameWidth}px`}
          height={`${pathBottom.height * lineStrokeFactor}px`}
          preserveAspectRatio="none"
          viewBox={pathBottom.viewBox}
          style={styleBottom}
        >
          <path d={pathBottom.path} id="frame-bottom" />
        </svg>
      </div>
    );
  }

  function createOuterFrameStyle() {
    const frameHeight = height;
    const frameWidth = width;
    const styleLeft = {
      position: "absolute",
      left: `0px`,
      top: `0px`,
    };
    const styleRight = {
      position: "absolute",
      right: `0px`,
      top: `0px`,
    };
    const styleTop = {
      position: "absolute",
      left: `0px`,
      top: `0px`,
    };
    const styleBottom = {
      position: "absolute",
      left: `0px`,
      bottom: `0px`,
    };
    return {
      frameHeight,
      styleLeft,
      styleRight,
      frameWidth,
      styleTop,
      styleBottom,
    };
  }

  function createOuterFrame(
    pathLeft,
    pathRight,
    pathTop,
    pathBottom,
    lineStrokeFactor = 3
  ) {
    const {
      frameHeight,
      styleLeft,
      styleRight,
      frameWidth,
      styleTop,
      styleBottom,
    } = createOuterFrameStyle();
    return (
      <div style={style} data-testid="_frame_">
        <svg
          data-testid="_frameLine_left"
          width={`${pathLeft.width * lineStrokeFactor}px`}
          height={`${frameHeight}px`}
          preserveAspectRatio="none"
          viewBox={pathLeft.viewBox}
          style={styleLeft}
        >
          <path d={pathLeft.path} id="frame-left" />
        </svg>
        <svg
          data-testid="_frameLine_right"
          width={`${pathRight.width * lineStrokeFactor}px`}
          height={`${frameHeight}px`}
          preserveAspectRatio="none"
          viewBox={pathRight.viewBox}
          style={styleRight}
        >
          <path d={pathRight.path} id="frame-right" />
        </svg>
        <svg
          data-testid="_frameLine_top"
          width={`${frameWidth}px`}
          height={`${pathTop.height * lineStrokeFactor}px`}
          preserveAspectRatio="none"
          viewBox={pathTop.viewBox}
          style={styleTop}
        >
          <path d={pathTop.path} id="frame-top" />
        </svg>
        <svg
          data-testid="_frameLine_bottom"
          width={`${frameWidth}px`}
          height={`${pathBottom.height * lineStrokeFactor}px`}
          preserveAspectRatio="none"
          viewBox={pathBottom.viewBox}
          style={styleBottom}
        >
          <path d={pathBottom.path} id="frame-bottom" />
        </svg>
      </div>
    );
  }

  switch (frametype) {
    case "scribble": {
      const { left, right, top, bottom } = scribble;
      return createFrame(left, right, top, bottom, 0.3, width / 150);
    }
    case "single": {
      const { horizontal, vertical } = single;
      return createFrame(
        vertical,
        vertical,
        horizontal,
        horizontal,
        0.48,
        width / 400
      );
    }
    case "outer": {
      const { horizontal, vertical } = single;
      return createOuterFrame(
        vertical,
        vertical,
        horizontal,
        horizontal,
        width / 300
      );
    }
    default:
      return null;
  }
}

export default Frame;
