import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { textSelectors } from "./textSlice";
import { selectDesignGenerator } from "../Design/designSlice";

import "./textframe.css";
import { addText } from "../printerSlice";

function preparePxNumber(number) {
  return number.toString() + "px";
}

function Textframe({
  setOverflow = (overflow) => {
    return overflow;
  },
  setPrintArea = (id, printArea) => {
    return id;
  },
  onClick = (id) => {
    return id;
  },
  id = "NO_ID",
  textColor = "black",
  textAlign = "left",
  textVerticalAlign = "center",
  width = 500,
  height = 500,
  areaWidth = 100,
  areaHeight = 100,
  areaRect = { left: 0, right: 0, top: 0, bottom: 0 },
  posX = 100,
  posY = 100,
  designHeight = 1000,
  fontSize = 12,
  highlightFontSize = 12,
  fontFamily = "Source Serif Pro",
  fontWeight = "normal",
  highlightFontFamily = "Source Serif Pro",
  highlightFontWeight = "normal",
  devMode = false,
  currentSpace = -100000,
  lineHeight = 1.5,
  collisionDetection = false,
  active = false,
  canUseQuote = false,
  icon = null,
  textType = "poem",
  editable = true,
  designLoaded = false,
}) {
  const dispatch = useDispatch();
  const paragraphFrame = useRef(null);
  const divFrame = useRef(null);
  const { text, design: designId } = useSelector((state) =>
    textSelectors.selectById(state, id)
  ) ?? { text: "", design: "" };
  const textHasQuote = useRef(false);
  const [quoteFrame, setQuoteFrame] = useState(0);

  const memoizedGetTextFraming = useCallback(
    function getTextFraming(spans, global = false) {
      let outerRect = {};
      const allLines = [];

      for (let i = 0; i < spans.length; i++) {
        let { left, right, top, bottom } = spans[i].getBoundingClientRect();
        // calculate the text-position relative to parent area
        // Add scroll positions window.pageOffset to get absolute values
        left = left + window.pageXOffset;
        right = right + window.pageXOffset;
        top = top + window.pageYOffset;
        bottom = bottom + window.pageYOffset;
        if (!global) {
          left = left - areaRect.left;
          right = right - areaRect.left;
          top = top - areaRect.top;
          bottom = bottom - areaRect.top;
        }
        allLines.push({ left: left, right: right, top: top, bottom: bottom });
        if (i !== spans.length - 1 || !textHasQuote.current) {
          //--> Ignore Quote line for outer left and outer right border
          if (!outerRect.left || left < outerRect.left) outerRect.left = left;
          if (!outerRect.right || right > outerRect.right)
            outerRect.right = right;
        }
      }

      outerRect.top = allLines[0].top;
      outerRect.bottom = allLines[allLines.length - 1].bottom;

      return { outerRect, allLines };
    },
    [areaRect.left, areaRect.top]
  );

  // GENERATE FINAL DESIGN
  const designGenerator = useSelector((state) =>
    selectDesignGenerator(state, designId)
  );
  const designGeneratorMemo = useRef(false);
  useEffect(() => {
    if (!designGenerator) {
      designGeneratorMemo.current = false;
      return;
    }

    if (designGeneratorMemo === designGenerator) return;
    designGeneratorMemo.current = true;

    const spans = paragraphFrame.current.children;
    const { outerRect } = memoizedGetTextFraming(spans, true);

    const textItem = {
      posX: outerRect.left,
      posY: outerRect.top,
      width: outerRect.right - outerRect.left,
      height: outerRect.bottom - outerRect.top,
      textId: id,
      type: textType,
      horizontalAlign: textAlign,
    };
    dispatch(addText(textItem));
  }, [
    designGenerator,
    dispatch,
    memoizedGetTextFraming,
    id,
    textAlign,
    textType,
  ]);
  // ----

  useEffect(() => {
    // Measure how much Space is left in Textfield
    if (!paragraphFrame.current) return;
    const pxSpaceLeft =
      divFrame.current.getBoundingClientRect().height -
      paragraphFrame.current.getBoundingClientRect().height;

    if (pxSpaceLeft === currentSpace) return;
    setOverflow(pxSpaceLeft);
  }, [
    id,
    width,
    height,
    areaWidth,
    areaHeight,
    designHeight,
    fontSize,
    highlightFontSize,
    fontFamily,
    fontWeight,
    highlightFontFamily,
    highlightFontWeight,
    lineHeight,
    text,
    currentSpace,
    setOverflow,
  ]);

  useEffect(() => {
    // Collision detection "No Print Area" - Detection
    if (!collisionDetection && !textHasQuote.current) return;
    if (!paragraphFrame.current) return;
    const spans = paragraphFrame.current.children;
    var { outerRect, allLines } = memoizedGetTextFraming(spans);

    if (textHasQuote.current) {
      // Reposition the Quote Frame
      const widthOuterRect = outerRect.right - outerRect.left;
      const widthQuote =
        allLines[allLines.length - 1].right -
        allLines[allLines.length - 1].left;
      let positionOfQuote = widthOuterRect - widthQuote;
      if (positionOfQuote < 0 || textAlign !== "left") positionOfQuote = 0;

      //The following is needed because the "no Print Zone Frame" gets repositioned and this
      //effect is not called anymore after Repositioning (for Performance Reasons)
      const repositionLastLine = positionOfQuote - quoteFrame;
      allLines[allLines.length - 1].right =
        allLines[allLines.length - 1].right + repositionLastLine;
      allLines[allLines.length - 1].left =
        allLines[allLines.length - 1].left + repositionLastLine;
      // ---------------

      setQuoteFrame(positionOfQuote);
    }

    if (collisionDetection) {
      setPrintArea({
        outerRect: outerRect,
        lineRects: allLines,
      });
    }
  }, [
    text,
    fontFamily,
    highlightFontFamily,
    fontSize,
    highlightFontSize,
    lineHeight,
    areaRect,
    designLoaded,
    collisionDetection,
    memoizedGetTextFraming,
    quoteFrame,
    setPrintArea,
  ]);

  function selectMargins() {
    switch (textVerticalAlign) {
      case "center":
        return { margin: "0px" };
      case "bottom":
        return { marginBottom: "0px", marginTop: "auto" };
      case "top":
        return { marginBottom: "auto", marginTop: "0px" };
      default:
        return { marginBottom: "auto", marginTop: "0px" };
    }
  }

  const lines = text.split("\n");
  const width_px = preparePxNumber((width / 1000) * areaWidth);
  const height_px = preparePxNumber((height / 1000) * areaHeight);
  const fontSizeNumber = (fontSize / 1000) * designHeight;
  const fontSize_px = preparePxNumber(fontSizeNumber);
  const quoteFontSize_px = preparePxNumber(fontSizeNumber * 0.75);
  const highlightFontSize_px = preparePxNumber(
    (highlightFontSize / 1000) * designHeight
  );

  const posX_px = preparePxNumber((posX / 1000) * areaWidth);
  const posY_px = preparePxNumber((posY / 1000) * areaHeight);

  const divStyle = {
    width: width_px,
    height: height_px,
    left: posX_px,
    top: posY_px,
    borderStyle: "solid",
    borderColor: devMode ? "red" : "transparent",
    borderWidth: "1px",
    textAlign: textAlign,
    lineHeight: lineHeight,
  };

  const margins = selectMargins();

  const pStyle = {
    ...margins,
    fontSize: fontSize_px,
    minWidth: "50px",
    width: "100%",
    whiteSpace: "pre-wrap",
    letterSpacing: "0em",
  };

  const spanStyle = {
    padding: "0px",
    fontSize: fontSize_px,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    color: textColor,
  };

  const spanHighlightStyle = {
    padding: "0px",
    fontSize: highlightFontSize_px,
    fontFamily: highlightFontFamily,
    fontWeight: highlightFontWeight,
    color: textColor,
    lineHeight: highlightFontSize > fontSize ? fontSize / highlightFontSize : 1,
  };

  const spanQuoteStyle = {
    padding: "0px",
    position: "relative",
    left: `${quoteFrame}px`,
    fontSize: quoteFontSize_px,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    color: textColor,
  };

  const iconStyle = {
    padding: "0px",
    fontSize: fontSize_px,
    fontFamily: "jtIconFont",
    color: textColor,
  };

  let highlightStyleActive = false;

  function toggleHighlightStyle() {
    highlightStyleActive = highlightStyleActive ? false : true;
  }

  function createLineSpan(text, index) {
    if (index > 0) toggleHighlightStyle();
    if (text !== "") {
      return (
        <span
          style={highlightStyleActive ? spanHighlightStyle : spanStyle}
          key={"Sub_" + index}
          data-testid={"subline_span"}
        >
          {text}
        </span>
      );
    } else return null;
  }

  function createLine(line, index, array) {
    if (index === array.length - 1) {
      if (line.substr(0, 2) === "//" && canUseQuote) {
        textHasQuote.current = true;
        return (
          <span style={spanQuoteStyle} key={index} data-testid={"line_span"}>
            {line.substr(2)}
          </span>
        );
      } else {
        textHasQuote.current = false;
      }
    }
    const textParts = line.split("**");
    return (
      <span style={spanStyle} key={index} data-testid={"line_span"}>
        {textParts.map(createLineSpan)}
        {index === array.length - 1 ? "" : <br />}
      </span>
    );
  }

  const renderedLines = lines.map(createLine);

  function getTextframeClass() {
    if (!editable) return "wrapperDivUneditable";
    return active ? "wrapperDiv active" : "wrapperDiv";
  }

  return (
    <div
      data-testid={`_textFrame_${id}`}
      className={getTextframeClass()}
      style={divStyle}
      ref={divFrame}
      onClick={() => {
        if (editable) onClick(id);
      }}
    >
      <p
        style={pStyle}
        ref={paragraphFrame}
        data-testid={`_textFrameParagraph_${id}`}
      >
        {icon && (
          <span style={iconStyle} data-testid={`icon_span`}>
            {icon}
          </span>
        )}
        {renderedLines}
      </p>
    </div>
  );
}

Textframe.propTypes = {
  setOverflow: PropTypes.func,
  setPrintArea: PropTypes.func,
  onClick: PropTypes.func,

  designRect: PropTypes.object,

  id: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  highlightFontFamily: PropTypes.string,
  highlightFontWeight: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  areaWidth: PropTypes.number,
  areaHeight: PropTypes.number,
  posX: PropTypes.number,
  posY: PropTypes.number,
  designHeight: PropTypes.number,
  fontSize: PropTypes.number,
  highlightFontSize: PropTypes.number,
  currentSpace: PropTypes.number,

  devMode: PropTypes.bool,
  active: PropTypes.bool,
};

export default Textframe;
